@font-face {
  font-family: 'font';
  font-style: normal;
  src: url('../../font.ttf');
}





  
  
  
  .train {
    p { 
      font-family: 'font'; 
      &.p {
        font-family: inherit;
      }
    }
    font-family: serif;
    .thicc {
      height:1rem;
    }
    color:white;
    font-size:large;
    a {
      cursor:pointer;
      font-style: normal;
      color:red;
      &:hover {
        font-style: italic;
        background-color: yellow;
      }
    }
    h1 {
      font-size:xx-large;
      color:yellow;
    }
    h2 {
      font-size:x-large;
      font-style: oblique;
      color:yellow;
    }
    input {
      color: black;
    }
    background-color: red;
    min-height:100vh;
  }