@font-face {
  font-family: 'sofia pro';
  font-style: bold;
  src: url("./fonts/sofiapro-bold.woff2");
}

@font-face {
  font-family: 'proxima nova';
  font-style: normal;
  src: url("./fonts/Proxima-Nova-Regular.woff2");
}

@font-face {
  font-family: 'proxima nova';
  font-weight: 600;
  src: url("./fonts/Proxima-Nova-Semibold.woff2");
}

body {
  margin: 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: black;
  background-image: none;
}

@-webkit-keyframes offsetBG {
  from {
    background-position-y: 0;
  }
  to {
    background-position-y: -234px;
  }
}

@keyframes offsetBG {
  from {
    background-position-y: 0;
  }
  to {
    background-position-y: -234px;
  }
}

.body {
  background-color: #ea3d80;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -20;
}

.body.backgroundColourAdmin {
  background-color: white;
}

.body.backgroundColourWhite {
  background-color: white;
}

.body.backgroundColourOrange {
  background-color: #fbb03b;
  margin-top: -1.5rem;
  padding-top: 1.5rem;
}

.body.backgroundColourBlack {
  background-color: black;
}

.body.backgroundColourStars {
  background-color: black;
  background-image: url("./pics/stars.gif");
  -webkit-animation-name: offsetBG;
          animation-name: offsetBG;
  -webkit-animation-duration: 30s;
          animation-duration: 30s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Store {
  color: black;
  font-family: "Raleway", sans-serif;
  font-size: large;
}

.Store .box {
  background-color: white;
  border: 5px solid black;
  padding: 1rem;
}

.Store .box h1 {
  margin-top: 0px;
}

.Store .modal {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: 99999999999;
  color: black;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.Store .modal .transparency {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
  z-index: -1;
}

.Store .modal .box {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-height: calc(100vh - 20px);
  overflow-y: auto;
  overflow-x: hidden;
}

.Store .modal .box.buy {
  min-width: 500px;
  max-width: 600px;
}

@media only screen and (max-width: 555px) {
  .Store .modal .box.buy {
    min-width: auto;
    width: calc(100% - 2rem);
    max-width: calc(100% - 2rem);
  }
}

.Store .modal .box.small {
  max-width: calc(500px);
}

@media only screen and (max-width: 555px) {
  .Store .modal .box.small {
    width: calc(100% - 2rem);
  }
}

.Store .modal .box h1 {
  padding-right: 1.6rem;
}

.Store .modal .box .cross {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  width: calc(100% + 0.5rem + 6px);
  text-align: right;
  margin-bottom: -2rem;
}

.Store .modal .box .cross button {
  margin-top: -1rem;
  width: 2rem;
  height: 2rem;
  background-color: black;
}

.Store .modal .box .cross button:hover {
  background-color: #f01a50;
}

.Store .modal .basketItem {
  width: 100%;
  margin-bottom: 1rem;
}

.Store .modal .basketItem h4 {
  margin-top: 0px;
  margin-bottom: 0.5rem;
}

.Store .modal .basketItem .first {
  padding-right: 1rem;
}

.Store .modal .basketItem .first .image {
  border: none;
  width: 75px;
  height: 75px;
  background-repeat: none;
  background-size: cover;
}

@media only screen and (max-width: 444px) {
  .Store .modal .basketItem .first {
    display: none;
  }
}

.Store .modal .basketItem .second {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
}

.Store .modal .basketItem .second .flex {
  -webkit-box-align: top;
      -ms-flex-align: top;
          align-items: top;
}

.Store .modal .basketItem .second h4 {
  margin: 0px;
  padding: 0px;
}

@media only screen and (max-width: 444px) {
  .Store .modal .basketItem .second h4 {
    font-size: medium;
  }
}

.Store .modal .basketItem .second h5 {
  margin: 0px;
}

.Store .modal .basketItem .second .price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 0px;
}

.Store .modal .basketItem .second .title {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.Store .modal form {
  width: 100%;
}

.Store .error {
  color: #f01a50;
  font-weight: 700;
  font-style: italic;
  cursor: pointer;
}

.Store .error:hover {
  text-decoration: underline;
}

.Store .variations {
  font-size: small;
}

.Store .quantity {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Store .quantity .b {
  width: 2rem;
  height: 2rem;
  font-weight: 900;
  font-size: x-large;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: black;
  color: white;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Store .quantity .b:hover {
  background-color: #511aeb;
}

.Store .quantity .db {
  width: 2rem;
  height: 2rem;
  font-weight: 900;
  font-size: x-large;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: black;
  color: white;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: default;
  color: gray;
}

.Store .quantity .db:hover {
  background-color: #511aeb;
}

.Store .quantity .db:hover {
  background-color: black;
}

.Store .quantity .value {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: white;
  color: black;
  border: 3px solid black;
  height: 2rem;
  border-left: none;
  border-right: none;
}

.Store .required {
  color: #f01a50;
  font-size: small;
  font-style: italic;
  font-weight: 700;
  margin-top: 0.25rem;
}

.Store label {
  font-weight: 700;
}

.Store form .dropdown {
  margin-top: 0.25rem;
}

.Store form .box {
  padding: 0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  max-height: 1.25rem;
  border: 3px solid black;
}

.Store form h2 {
  margin-top: 0px;
  margin-bottom: 0.5rem;
}

.Store form h4 {
  margin-top: 0.5rem;
  margin-bottom: 0px;
}

.Store form h3 {
  margin: 0px;
  margin-bottom: 0.5rem;
}

.Store form input {
  display: block;
  margin-top: calc(0.25rem + 3px);
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.Store form textArea {
  display: block;
  margin-top: 0.25rem;
  border: 3px solid black;
  width: 100%;
  max-width: 600px;
  height: 6rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0.25rem;
  font-family: "Raleway", sans-serif;
  font-size: medium;
}

.Store form .flex {
  width: 100%;
}

.Store .flex1 {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-right: 0.25rem;
}

.Store .flex2 {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-left: 0.25rem;
}

.Store .alignRight {
  text-align: right;
}

.Store .alignRight button {
  margin-right: 0px;
  margin-left: 1px;
}

@media only screen and (max-width: 888px) {
  .Store .basket .flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.Store .basket .flex .left {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.Store .basket .flex .right {
  border: 5px solid black;
  background-color: white;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Store .basket .flex .right button {
  margin-right: 0px;
  margin-bottom: 1rem;
}

.Store .basket .flex .right h2 {
  margin-top: 0px;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 888px) {
  .Store .basket .flex .right h2 {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 4px;
  }
}

@media only screen and (max-width: 666px) {
  .Store .basket .flex .right h2 {
    font-size: larger;
  }
}

@media only screen and (max-width: 444px) {
  .Store .basket .flex .right h2 {
    font-size: large;
  }
}

@media only screen and (max-width: 888px) {
  .Store .basket .flex .right {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-left: 0px;
    padding: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .Store .basket .flex .right button {
    margin-bottom: 0px;
  }
}

.Store .basket b {
  margin-right: 0.5rem;
}

.Store .basket .quantity {
  font-size: medium;
}

.Store .basket .quantity .b {
  width: 1.6rem;
  height: 1.6rem;
  font-size: large;
}

.Store .basket .quantity .db {
  width: 1.6rem;
  height: 1.6rem;
  font-size: large;
}

.Store .basket .quantity .value {
  height: 1.6rem;
  width: 1.6rem;
}

.Store .basket .animate {
  -webkit-transition: height 0.3s ease;
  transition: height 0.3s ease;
  overflow: hidden;
}

.Store .basket .basketItem {
  padding: 1rem;
  border: 5px solid black;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem;
}

.Store .basket .basketItem .first .image {
  width: 100px;
  height: 100px;
  background-repeat: none;
  background-size: cover;
}

@media only screen and (max-width: 555px) {
  .Store .basket .basketItem .first .image {
    width: 75px;
    height: 75px;
  }
}

@media only screen and (max-width: 444px) {
  .Store .basket .basketItem .first .image {
    width: 50px;
    height: 50px;
  }
}

@media only screen and (max-width: 333px) {
  .Store .basket .basketItem .first .image {
    display: none;
  }
}

.Store .basket .basketItem .second {
  margin-top: -1rem;
  padding: none;
  margin-left: 1rem;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media only screen and (max-width: 444px) {
  .Store .basket .basketItem .second {
    margin-left: 0.5rem;
  }
}

@media only screen and (max-width: 333px) {
  .Store .basket .basketItem .second {
    margin-left: 0px;
  }
}

.Store .basket .basketItem .second h4 {
  margin: none;
  margin-bottom: 0.5rem;
}

.Store .basket .basketItem .second button {
  margin: 0px;
}

.Store .basket .basketItem .second .shit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

@media only screen and (max-width: 555px) {
  .Store .basket .basketItem .second .shit {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.Store .basket .basketItem .second .shit .one {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.Store .basket .basketItem .second .shit .two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media only screen and (max-width: 555px) {
  .Store .basket .basketItem .second .shit .two {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.Store .inline {
  display: inline;
}

.Store .inlineBlock {
  display: inline-block;
}

.Store .center {
  text-align: center;
}

.Store .center button {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.Store .price {
  margin-top: 0.5rem;
  text-align: right;
}

.Store .contact {
  width: 500px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.Store .contact p {
  margin-top: none;
}

.Store .contact form {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.Store .spinner {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 6px solid #f4b321;
  border-top: 6px solid #29efad;
  border-left: 6px solid #29efad;
  -webkit-animation: spinner 1s linear infinite;
          animation: spinner 1s linear infinite;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spinner {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Store table td {
  padding-left: 1rem;
}

.Store table .first {
  padding-left: 0px;
}

.Store table .first .image {
  border: none;
  width: 75px;
  height: 75px;
  background-repeat: none;
  background-size: cover;
}

.Store table .second {
  min-width: 100px;
  max-width: 250px;
}

.Store table .third {
  min-width: 200px;
  max-width: 300px;
}

.Store table .fourth {
  text-align: right;
}

.Store .B {
  display: inline;
  color: #511aeb;
  font-size: medium;
  cursor: pointer;
}

.Store .B:hover {
  text-decoration: underline;
}

.Store .checkbox {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: default;
}

.Store .checkbox label {
  margin-right: 0.5rem;
}

.Store .checkbox .btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border: 3px solid black;
  background-color: white;
  font-weight: 900;
}

.Store .checkbox .btn:hover {
  border: 3px solid #511aeb;
}

.Store .dropdown {
  white-space: nowrap;
  font-size: medium;
  position: relative;
  max-width: 300px;
  background-color: white;
  border: 3px solid black;
  padding: 0.5rem;
  height: 2rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.Store .dropdown .title {
  position: absolute;
  left: 0px;
  top: -5px;
  padding: 0.5rem;
  width: calc(100% + 2px - 3rem);
  overflow: hidden;
  cursor: default;
}

.Store .dropdown .drop {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: absolute;
  width: calc(100% + 9px - 2rem);
  left: -3px;
  top: 100%;
  background-color: white;
  border: 3px solid black;
  border-top: none;
  overflow: hidden;
  -webkit-transition: max-height 0.2s ease-in-out;
  transition: max-height 0.2s ease-in-out;
}

.Store .dropdown .drop .option {
  height: 1rem;
  padding: 0.5rem;
  cursor: pointer;
}

.Store .dropdown .drop .option:hover {
  background-color: black;
  color: white;
}

.Store .dropdown .arrow {
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: -3px;
  top: -3px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: black;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.Store .dropdown .arrow:hover {
  background-color: #511aeb;
}

.Store .bigBanner {
  background-size: cover;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: black;
  position: relative;
}

.Store .bigBanner .sub {
  font-size: x-large;
  margin-top: 1rem;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (max-width: 555px) {
  .Store .bigBanner .sub {
    font-size: larger;
  }
}

@media only screen and (max-width: 444px) {
  .Store .bigBanner .sub {
    font-size: large;
  }
}

.Store .bigBanner .content {
  padding: 0px;
  margin: 0px;
  max-width: 100%;
  text-align: center;
  z-index: 1;
}

.Store .bigBanner .Title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.Store .bigBanner .Title .st0 {
  fill: #29efad;
}

.Store .bigBanner .Title .word {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: calc(0.36363636vw + 2.90909px);
  margin-left: calc(0.9818181818vw + 7.854545454545px);
  margin-right: calc(0.9818181818vw + 7.854545454545px);
}

.Store .bigBanner .Title .letter {
  -webkit-transition: -webkit-transform 0.05s linear;
  transition: -webkit-transform 0.05s linear;
  transition: transform 0.05s linear;
  transition: transform 0.05s linear, -webkit-transform 0.05s linear;
  cursor: pointer;
}

.Store .bigBanner .Title svg {
  height: calc(6vw + 48px);
}

.Store .bigBanner .Title.showStore svg {
  height: calc(3vw + 48px);
}

.Store .bigBanner .Title.showStore .word {
  gap: calc(0.18181818vw + 2.90909px);
  margin-left: calc(0.4909090909vw + 7.854545454545px);
  margin-right: calc(0.4909090909vw + 7.854545454545px);
}

.Store .bigBanner .Title.animating svg {
  -webkit-transition: height 0.2s linear;
  transition: height 0.2s linear;
}

.Store .bigBanner .Title.animating .word {
  -webkit-transition: gap 0.2s linear;
  transition: gap 0.2s linear;
  -webkit-transition: margin 0.2s linear;
  transition: margin 0.2s linear;
}

.Store .bigBanner .background {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: -10;
}

.Store .bigbox {
  background-color: white;
  border: 5px solid black;
  margin-bottom: 1rem;
  padding: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: calc(100% - 3rem);
  margin-top: 2rem;
}

@media only screen and (max-width: 888px) {
  .Store .bigbox {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: calc(100% - 2rem);
  }
}

@media only screen and (max-width: 666px) {
  .Store .bigbox {
    width: calc(100% - 1rem);
  }
}

.Store .split {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 3rem);
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
  padding-bottom: 4rem;
}

@media only screen and (max-width: 888px) {
  .Store .split {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: calc(100% - 2rem);
    padding-top: 1rem;
  }
}

@media only screen and (max-width: 666px) {
  .Store .split {
    width: calc(100% - 1rem);
    padding-top: 0.5rem;
  }
}

.Store .split .sidebar {
  width: 400px;
  margin-right: 16px;
}

@media only screen and (max-width: 888px) {
  .Store .split .sidebar {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 0.5rem;
  }
}

.Store .split .sidebar h3 {
  margin-top: 0px;
}

.Store .split .content {
  width: calc(100% - 416px);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 1rem;
  border: 5px solid black;
  background: white;
}

@media only screen and (max-width: 888px) {
  .Store .split .content {
    width: 100%;
  }
}

.Store .split .content h3 {
  margin-top: 0px;
}

.Store .image {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.Store .image img {
  width: 100%;
  max-width: 400px;
  margin-bottom: 5px;
  border: 5px solid black;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.Store .image .flex {
  border: 5px solid black;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: white;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.5rem;
}

.Store .image .flex .left {
  margin-right: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}

.Store .image .flex .left:hover {
  color: #511aeb;
}

.Store .image .flex .right {
  margin-left: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}

.Store .image .flex .right:hover {
  color: #511aeb;
}

.Store .image .flex .dleft {
  margin-right: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  opacity: 0.5;
  cursor: default;
}

.Store .image .flex .dleft:hover {
  color: #511aeb;
}

.Store .image .flex .dleft:hover {
  color: black;
}

.Store .image .flex .dright {
  margin-left: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  opacity: 0.5;
  cursor: default;
}

.Store .image .flex .dright:hover {
  color: #511aeb;
}

.Store .image .flex .dright:hover {
  color: black;
}

.Store .image .flex .thumbs {
  width: 100%;
  position: relative;
  height: 0px;
  overflow: hidden;
}

.Store .image .flex .thumbs .page {
  display: inline-block;
  width: 100%;
}

.Store .image .flex .thumbs .scrollable {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  -webkit-transition: left 0.3s ease-in-out;
  transition: left 0.3s ease-in-out;
}

.Store .image .flex .thumbs .thumb {
  display: inline-block;
  cursor: pointer;
  background-size: cover;
  width: calc(100% / 4);
  height: 0px;
}

@media only screen and (max-width: 888px) {
  .Store .image .flex .thumbs .thumb {
    width: calc(100% / 8);
  }
}

@media only screen and (max-width: 777px) {
  .Store .image .flex .thumbs .thumb {
    width: calc(100% / 7);
  }
}

@media only screen and (max-width: 666px) {
  .Store .image .flex .thumbs .thumb {
    width: calc(100% / 6);
  }
}

@media only screen and (max-width: 555px) {
  .Store .image .flex .thumbs .thumb {
    width: calc(100% / 5);
  }
}

@media only screen and (max-width: 444px) {
  .Store .image .flex .thumbs .thumb {
    width: calc(100% / 4);
  }
}

@media only screen and (max-width: 333px) {
  .Store .image .flex .thumbs .thumb {
    width: calc(100% / 3);
  }
}

.Store .image .flex .thumbs .thumb:hover {
  opacity: 0.5;
}

.Store input {
  border: 3px solid black;
  padding: 0.25rem;
  padding-left: 0.5rem;
  font-size: medium;
  outline: none;
}

.Store input:disabled {
  border: 3px solid grey;
  background-color: lightgray;
}

.Store .disabled {
  color: grey;
}

.Store textArea {
  outline: none;
}

.Store .labelPair {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 444px) {
  .Store .labelPair {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.Store .labelPair .label {
  width: 150px;
  text-align: right;
  margin-right: 16px;
  font-weight: 700;
  color: black;
}

@media only screen and (max-width: 444px) {
  .Store .labelPair .label {
    padding-top: 0px;
    width: 100%;
    text-align: left;
    margin-right: 0px;
  }
}

.Store .labelPair .pair {
  width: calc(100% - 166px);
}

@media only screen and (max-width: 444px) {
  .Store .labelPair .pair {
    width: 100%;
  }
}

.Store button {
  outline: none;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: medium;
  background-color: #511aeb;
  border: none;
  padding: 0.5rem;
  font-weight: 700;
  color: white;
  cursor: pointer;
}

.Store button:hover {
  background-color: black;
}

.Store button.green {
  background-color: #29efad;
  color: black;
  border: 3px solid black;
}

.Store button.green:hover {
  background-color: black;
  color: white;
}

.Store button.red {
  background-color: #f01a50;
}

.Store button.red:hover {
  background-color: black;
}

.Store button.disabled {
  background-color: grey;
  color: white;
  cursor: default;
}

.Store .buy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.Store .buy .image {
  border: none;
  width: 75px;
  height: 75px;
  background-repeat: none;
  background-size: cover;
  margin-right: 0.5rem;
}

.Store .buy .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.5rem;
}

.Store .buy .left {
  overflow: hidden;
  width: 500px;
}

@media only screen and (max-width: 1165px) {
  .Store .buy .left {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    width: auto;
  }
}

@media only screen and (max-width: 777px) {
  .Store .buy .left {
    display: none;
  }
}

.Store .buy .right {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-left: 1rem;
}

@media only screen and (max-width: 777px) {
  .Store .buy .right {
    margin-left: 0px;
  }
}

.Store .buy .right h2 {
  margin-top: 0.5rem;
}

.Store .slideshow {
  position: relative;
}

.Store .slideshow .flex {
  position: absolute;
  left: 0px;
  top: 0px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Store .slideshow img {
  -webkit-transition: opacity 0.5s linear;
  transition: opacity 0.5s linear;
}

.Store .receipt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 777px) {
  .Store .receipt {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.Store .receipt .image {
  border: none;
  width: 75px;
  height: 75px;
  background-repeat: none;
  background-size: cover;
  margin-right: 0.5rem;
}

@media only screen and (max-width: 444px) {
  .Store .receipt .first {
    display: none;
  }
}

.Store .receipt .second {
  margin-right: 0.5rem;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.Store .receipt h4 {
  margin: 0px;
}

.Store .receipt .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.5rem;
}

.Store .receipt .left {
  width: 500px;
  margin-right: 1rem;
}

@media only screen and (max-width: 999px) {
  .Store .receipt .left {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    width: auto;
  }
}

@media only screen and (max-width: 777px) {
  .Store .receipt .left {
    margin-right: 0px;
    margin-bottom: 1rem;
  }
}

.Store .receipt .right {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.Store .receipt .right h2 {
  margin-top: 0.5rem;
}

.Store .receipt .variations {
  font-size: small;
}

.Store .invoice {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Store .invoice h2 {
  margin-top: 0.5rem;
}

.Store .invoice .sploot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Store .invoice .sploot .splootl {
  width: 9rem;
}

.Store .invoice .hour {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Store .invoice .hour.total {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.Store .invoice .hour .title {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.Store .invoice .hour .charge {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.Store .invoice p {
  margin-top: 0px;
}

@media only screen and (max-width: 777px) {
  .Store .invoice {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media only screen and (max-width: 444px) {
  .Store .invoice .first {
    display: none;
  }
}

.Store .invoice .second {
  margin-right: 0.5rem;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.Store .invoice h4 {
  margin: 0px;
}

.Store .invoice .left {
  width: 500px;
  margin-right: 1rem;
}

@media only screen and (max-width: 999px) {
  .Store .invoice .left {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    width: auto;
  }
}

@media only screen and (max-width: 777px) {
  .Store .invoice .left {
    margin-right: 0px;
    margin-bottom: 1rem;
  }
}

.Store .invoice .right {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.Store .invoice .variations {
  font-size: small;
}

.Store .banner {
  color: black;
  border-bottom: 5px solid black;
  background-color: #f4b321;
  padding: 0px;
}

.Store .banner .a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Store .banner .a:hover {
  text-decoration: none;
}

.Store .banner .button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0px;
  font-size: medium;
  padding: 0.5rem;
  font-weight: 700;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  color: black;
  padding-top: 0px;
  padding-bottom: 0px;
}

.Store .banner .button:hover {
  background-color: black;
  color: white;
}

.Store .banner .content {
  padding: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media only screen and (max-width: 555px) {
  .Store .banner .content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.Store .banner .content img {
  height: 2rem;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
}

.Store .banner .content .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Store .banner .h1 {
  margin: 0px;
  padding: 0px;
}

.Store .banner .logo {
  height: 2rem;
}

.Store .banner .logo rect {
  fill: none;
}

.Store .banner .logo.hover rect {
  fill: black;
}

.Store .banner .logo.hover path {
  fill: white;
}

.Store .thumbFlex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
  border: 5px solid black;
  padding: 0.5rem;
}

.Store .thumbFlex .item {
  border: 3px solid black;
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: calc(100% / 5 - 0.5rem);
  height: 0px;
  margin: 0.25rem;
  overflow: hidden;
  display: block;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
}

@media only screen and (max-width: 999px) {
  .Store .thumbFlex .item {
    width: calc(100% / 4 - 0.5rem);
  }
}

@media only screen and (max-width: 666px) {
  .Store .thumbFlex .item {
    width: calc(100% / 3 - 0.5rem);
  }
}

@media only screen and (max-width: 333px) {
  .Store .thumbFlex .item {
    width: calc(100% / 2 - 0.5rem);
  }
}

.Store .thumbFlex .item a {
  color: black;
}

.Store .thumbFlex .item .tool {
  font-size: x-large;
  display: block;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

@media only screen and (max-width: 777px) {
  .Store .thumbFlex .item .tool {
    font-size: large;
  }
}

@media only screen and (max-width: 444px) {
  .Store .thumbFlex .item .tool {
    font-size: medium;
  }
}

.Store .thumbFlex .item .tool .title {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-top: 3px solid black;
  border-bottom: 3px solid black;
}

.Store .thumbFlex .item .tool h3 {
  margin: 0;
}

.Store .thumbFlex .item .tool:hover {
  opacity: 1;
}

.Store .gameContent {
  width: calc(100% - 3rem);
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (max-width: 888px) {
  .Store .gameContent {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: calc(100% - 2rem);
  }
}

@media only screen and (max-width: 666px) {
  .Store .gameContent {
    width: calc(100% - 1rem);
  }
}

@media only screen and (max-width: 600px) {
  .Store .gameContent {
    max-width: calc(100% - 1rem);
  }
}

.Store .gameContent .heading {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .Store .gameContent .heading {
    padding-left: 0.5rem;
  }
}

.Store .gameContent .game {
  position: relative;
  overflow: hidden;
  width: 500px;
  height: 0px;
  padding-bottom: 500px;
  background-color: #68c8c8;
  border: 5px solid black;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: none;
}

@media only screen and (max-width: 600px) {
  .Store .gameContent .game {
    width: 100%;
    padding-bottom: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .Store .gameContent .game h3 {
    font-size: medium;
  }
  .Store .gameContent .game h2 {
    font-size: large;
  }
}

.Store .gameContent .game img {
  position: absolute;
}

.Store .gameContent .game div {
  position: absolute;
}

.Store .gameContent .game .opacity {
  -webkit-transition: opacity 0.5s linear;
  transition: opacity 0.5s linear;
}

.Store .gameContent .game .opacity h3 {
  padding-bottom: 2rem;
}

.Store .gameContent .game .centerFlex {
  left: 0;
  top: 0;
  padding: 1rem;
  height: 100%;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}

@media only screen and (max-width: 333px) {
  .Store .gameContent .game .centerFlex {
    padding: 0.25rem;
  }
}

@media only screen and (max-width: 333px) {
  .Store .gameContent .game .lots h3 {
    font-size: small;
    margin-bottom: 0.25rem;
  }
}

.Store .gameContent .game .floor {
  border-top: 5px solid black;
  width: 100%;
  bottom: 0px;
  left: 0px;
  height: 100px;
  background-color: #b4b4b4;
}

.Store .gameContent .game h3 {
  margin-top: 0px;
  margin-top: 0.5rem;
}

.Store .gameContent .game h2 {
  margin: 0px;
}

.Store h1 {
  font-family: "Leckerli One", cursive;
  margin-top: 0.5rem;
}

.Store h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.Store h3 {
  font-family: "Montserrat", sans-serif;
}

.Store h4 {
  font-family: "Montserrat", sans-serif;
}

.Store h5 {
  font-family: "Montserrat", sans-serif;
}

.Store .bg2 .content {
  z-index: 1;
}

.Store .expandItems {
  color: black;
  margin-bottom: 1rem;
  background-color: white;
  border: 5px solid black;
  border-top: none;
  text-align: center;
  padding: 0.5rem;
}

.Store .items {
  color: black;
  margin-bottom: 1rem;
  background-color: white;
  padding: 5px;
  border: 5px solid black;
  overflow-y: hidden;
  -webkit-transition: max-height 0.5s ease-in-out;
  transition: max-height 0.5s ease-in-out;
}

.Store .items h4 {
  margin-top: 0px;
  margin-bottom: 0.5rem;
}

.Store .items h5 {
  margin-top: 0px;
  margin-bottom: 0.5rem;
}

.Store .items .flex {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Store .items .item {
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: calc(100% / 5);
  height: 0px;
  overflow: hidden;
  display: block;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
}

@media only screen and (max-width: 999px) {
  .Store .items .item {
    width: calc(100% / 4);
  }
}

@media only screen and (max-width: 666px) {
  .Store .items .item {
    width: calc(100% / 3);
  }
}

@media only screen and (max-width: 333px) {
  .Store .items .item {
    width: calc(100% / 2);
  }
}

.Store .items .item a {
  color: black;
}

.Store .items .item .tool {
  font-size: x-large;
  display: block;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

@media only screen and (max-width: 777px) {
  .Store .items .item .tool {
    font-size: large;
  }
}

@media only screen and (max-width: 444px) {
  .Store .items .item .tool {
    font-size: medium;
  }
}

.Store .items .item .tool h3 {
  margin: 0;
}

.Store .items .item .tool:hover {
  opacity: 1;
}

.Store .content {
  width: calc(100% - 3rem);
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media only screen and (max-width: 888px) {
  .Store .content {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: calc(100% - 2rem);
  }
}

@media only screen and (max-width: 666px) {
  .Store .content {
    width: calc(100% - 1rem);
  }
}

a {
  color: #511aeb;
  text-decoration: none;
  cursor: pointer;
}

a.black {
  color: black;
}

a:hover {
  text-decoration: underline;
}

.LinkTree .options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 1rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 2rem;
}

.LinkTree .options .option {
  color: inherit;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 19%;
  max-width: 300px;
  min-width: 150px;
}

.LinkTree .options .option .pic svg {
  width: 150px;
  height: 150px;
}

@media only screen and (max-width: 1111px) {
  .LinkTree .options .option .pic svg {
    width: 100px;
    height: 100px;
  }
}

.LinkTree .options .option .pic svg .black {
  fill: black;
}

.LinkTree .options .option .pic svg .blue {
  fill: #29efad;
}

.LinkTree .options .option .pic svg:hover {
  cursor: pointer;
}

.LinkTree .options .option .pic svg:hover .black {
  fill: white;
}

.LinkTree .options .option .pic svg:hover .blue {
  fill: black;
}

@media only screen and (max-width: 1111px) {
  .LinkTree .options .option .caption {
    font-size: large;
  }
}

.LinkTree .options .option:hover {
  cursor: default;
}

.Tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  font-size: large;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-bottom: 1rem;
}

.Tabs .tab {
  margin-right: 1rem;
}

.Tabs .tab.selected {
  background-color: black;
  color: white;
}

.Tabs.AdminTabs {
  font-size: small;
  text-transform: uppercase;
  width: 800px;
}

.TabRange {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  font-size: large;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.TabRange .tabby {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem;
}

.TabRange .tabby .tab.selected {
  background-color: black;
  color: white;
}

.TabRange .tabby .gap {
  width: 1rem;
}

.TabRange .tabby .gap.selected {
  background-color: black;
  color: white;
}

input {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

textarea {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

.SwapTabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  font-size: large;
  margin-bottom: 1rem;
}

.SwapTabs input {
  width: 4rem;
}

.SwapTabs .tab .selected {
  background-color: black;
  color: white;
}

.SwapTabs .tab:hover .leftArrow {
  visibility: inherit;
}

.SwapTabs .tab:hover .rightArrow {
  visibility: inherit;
}

.SwapTabs .tab .leftArrow {
  margin-right: 0.2rem;
  font-size: small;
  position: relative;
  top: -0.1rem;
  visibility: hidden;
}

.SwapTabs .tab .leftArrow.disabled {
  cursor: default;
}

.SwapTabs .tab .rightArrow {
  margin-left: 0.2rem;
  font-size: small;
  position: relative;
  top: -0.1rem;
  visibility: hidden;
}

.SwapTabs .tab .rightArrow.disabled {
  cursor: default;
}

.SwapTabs .hide {
  visibility: hidden;
  margin-left: 1rem;
}

.SwapTabs:hover .hide {
  visibility: inherit;
}

.Pages .tab {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-right: 0.3rem;
}

.MusicAdmin .albums {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 2rem;
}

.MusicAdmin .albums .category .categoryTitle {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: black;
  width: 100%;
  padding: 0.5rem;
  color: white;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 0.5rem;
}

.MusicAdmin .albumDisplay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.MusicAdmin .albumDisplay img {
  width: 250px;
}

.MusicAdmin .albumDisplay .progress {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  margin-bottom: 1rem;
}

.MusicAdmin .albumDisplay .progress .redPercent, .MusicAdmin .albumDisplay .progress .whitePercent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 1rem;
}

.MusicAdmin .albumDisplay .progress .redPercent {
  color: black;
}

.MusicAdmin .albumDisplay .progress .progressBar {
  -webkit-transition: width 1s linear;
  transition: width 1s linear;
  z-index: 1;
  height: 1rem;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: black;
  overflow: hidden;
}

.MusicAdmin .albumDisplay .progress .progressBar .whitePercent {
  color: white;
}

.MusicAdmin .albumDisplay .missingSongName {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.MusicAdmin .albumDisplay .albumName {
  margin-top: 1rem;
}

.MusicAdmin .albumDisplay .albumSongs {
  margin-bottom: 1rem;
}

.MusicAdmin .albumDisplay .albumArtwork {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.MusicAdmin .albumDisplay .albumName, .MusicAdmin .albumDisplay .albumArtist {
  font-size: x-large;
}

.MusicAdmin .albumDisplay .albumName span, .MusicAdmin .albumDisplay .albumArtist span {
  font-size: medium;
}

.MusicAdmin .albumDisplay .songDoesNotExist {
  font-style: italic;
  color: red;
}

.MusicAdmin .downloading .log {
  background-color: black;
  color: white;
  font-family: 'Courier New', Courier, monospace;
  height: 500px;
  overflow-y: scroll;
  overflow-x: scroll;
  white-space: nowrap;
  padding: 0.5rem;
  font-size: small;
}

.MusicAdmin .downloading .log .line pre {
  margin: 0px;
}

.MusicAdmin .downloading .log .blankLine {
  height: 1rem;
}

.MusicAdmin .downloading .viewAlbum {
  margin-top: 0.5rem;
}

.MusicAdmin .downloading ::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.MusicAdmin .downloading ::-webkit-scrollbar-track {
  background: black;
}

.MusicAdmin .downloading ::-webkit-scrollbar-thumb {
  background: black;
}

.MusicAdmin .searchForNewAlbum input {
  width: 100%;
  margin-bottom: 1rem;
}

.MusicAdmin .searchForNewAlbum .albumThumbnails {
  overflow-y: scroll;
  height: 60vh;
  min-height: 200px;
}

.MusicAdmin .searchForNewAlbum .albumThumbnails .albumThumbnail {
  width: 143px;
  height: 143px;
}

.MusicAdmin .downloadNewAlbumButton {
  margin-bottom: 1rem;
}

.MusicAdmin .albumThumbnails {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 8px;
  width: 100%;
}

.MusicAdmin .albumThumbnails .albumThumbnail {
  width: 144px;
  height: 144px;
  position: relative;
}

.MusicAdmin .albumThumbnails .albumThumbnail img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.MusicAdmin .albumThumbnails .albumThumbnail:hover .deets {
  visibility: visible;
}

.MusicAdmin .albumThumbnails .albumThumbnail:hover .missingSong {
  visibility: hidden;
}

.MusicAdmin .albumThumbnails .albumThumbnail .missingSong {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  padding: 0.25rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: black;
  color: white;
}

.MusicAdmin .albumThumbnails .albumThumbnail .deets {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  padding: 0.25rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: black;
  color: white;
  font-size: small;
  pointer-events: none;
}

.MusicAdmin .albumThumbnails .albumThumbnail .deets .albumName, .MusicAdmin .albumThumbnails .albumThumbnail .deets .albumArtist {
  margin-bottom: 5px;
}

.MusicAdmin .Albums {
  margin-bottom: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1rem;
}

.MusicAdmin .Albums h2, .MusicAdmin .Albums p {
  margin: 0px;
}

.MusicAdmin .Albums .album .itemSplit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.MusicAdmin .Albums .album .itemSplit .left {
  width: 200px;
}

.MusicAdmin .Albums .album .itemSplit .left p {
  margin-bottom: 1rem;
}

.MusicAdmin .Albums .album .itemSplit .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.MusicAdmin .Albums .album .song {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
}

.MusicAdmin .Albums .album .song .dots {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-top: 9px;
  border-top: 1px dashed #f01a50;
  visibility: hidden;
}

.MusicAdmin .Albums .album .song .dots.visible {
  visibility: inherit;
}

.MusicAdmin .hideButtons {
  visibility: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.MusicAdmin .hideButtons.visible {
  visibility: inherit;
}

.Music {
  max-width: 600px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-family: Arial, Helvetica, sans-serif;
}

.Music h1 {
  margin: 0px;
}

.Music p {
  margin: 0px;
  font-weight: bold;
  font-size: large;
}

.Music .Album {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.Music .Album .gif {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.Music .Album p {
  margin-bottom: 1rem;
}

.Music .Album .albumSplit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.Music .Album .albumSplit .right {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1rem;
}

.Music .Album .albumSplit .left img {
  width: 50vw;
  max-width: 300px;
}

.Music .Album .song {
  font-size: x-large;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  padding: 0px;
  padding-right: 0.25rem;
  margin-top: -2px;
  padding-bottom: 2px;
  cursor: pointer;
}

.Music .Album .song svg {
  width: 24px;
  position: relative;
  top: 4px;
}

.Music .Album .song:hover {
  color: #fbb03b;
  background-color: black;
  padding-left: 0.25rem;
  padding-right: 0px;
}

.Music .Album .song:hover svg {
  fill: #fbb03b;
}

.Music .musicHeading {
  padding-top: 2rem;
  text-align: center;
}

.Music .musicPlayer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 4rem;
  background-color: #fbb03b;
  border-top: 2px solid black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Music .musicPlayer button {
  padding: 0px;
  background: none;
  border: none;
  cursor: pointer;
}

.Music .musicPlayer button:hover {
  background-color: black;
  color: #fbb03b;
}

.Music .musicPlayer button:hover svg {
  fill: #fbb03b;
}

.Music .musicPlayer svg {
  height: 32px;
}

.Music .musicPlayer .controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 2rem);
  max-width: 1000px;
}

.Music .musicPlayer .controls .downloadButton a {
  width: 32px;
  height: 32px;
}

.Music .musicPlayer .controls .downloadButton a:hover svg {
  background-color: black;
  fill: #fbb03b;
}

.Music .musicPlayer .controls .bar {
  margin-left: 1rem;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  cursor: pointer;
  position: relative;
}

.Music .musicPlayer .controls .bar .line {
  border-top: 2px dashed black;
  position: relative;
  top: 14px;
  margin-right: -14px;
}

.Music .musicPlayer .controls .bar .dot {
  width: 14px;
  height: 14px;
  background-color: black;
  border-radius: 7px;
  position: absolute;
  top: 8px;
}

.Music .musicPlayer .controls .barExtra {
  cursor: pointer;
  width: 14px;
  margin-right: 1rem;
}

.HIDDEN {
  height: 0px;
  width: 0px;
  overflow: hidden;
  visibility: hidden;
}

.Admin,
.Orders,
.Invoices,
.Items,
.Vouchers,
.Reddit,
.Schedule,
.Secret,
.Ambigrams,
.MusicAdmin,
.SelfAssessment {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: black;
  background-color: white;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
}

.Admin textarea,
.Orders textarea,
.Invoices textarea,
.Items textarea,
.Vouchers textarea,
.Reddit textarea,
.Schedule textarea,
.Secret textarea,
.Ambigrams textarea,
.MusicAdmin textarea,
.SelfAssessment textarea {
  background-color: white;
}

.Admin input,
.Orders input,
.Invoices input,
.Items input,
.Vouchers input,
.Reddit input,
.Schedule input,
.Secret input,
.Ambigrams input,
.MusicAdmin input,
.SelfAssessment input {
  background-color: white;
}

.Admin input.lil,
.Orders input.lil,
.Invoices input.lil,
.Items input.lil,
.Vouchers input.lil,
.Reddit input.lil,
.Schedule input.lil,
.Secret input.lil,
.Ambigrams input.lil,
.MusicAdmin input.lil,
.SelfAssessment input.lil {
  width: 4rem !important;
}

.Admin a.red,
.Orders a.red,
.Invoices a.red,
.Items a.red,
.Vouchers a.red,
.Reddit a.red,
.Schedule a.red,
.Secret a.red,
.Ambigrams a.red,
.MusicAdmin a.red,
.SelfAssessment a.red {
  color: red;
}

.Admin ::-webkit-scrollbar,
.Orders ::-webkit-scrollbar,
.Invoices ::-webkit-scrollbar,
.Items ::-webkit-scrollbar,
.Vouchers ::-webkit-scrollbar,
.Reddit ::-webkit-scrollbar,
.Schedule ::-webkit-scrollbar,
.Secret ::-webkit-scrollbar,
.Ambigrams ::-webkit-scrollbar,
.MusicAdmin ::-webkit-scrollbar,
.SelfAssessment ::-webkit-scrollbar {
  width: 2px;
}

.Admin ::-webkit-scrollbar-track,
.Orders ::-webkit-scrollbar-track,
.Invoices ::-webkit-scrollbar-track,
.Items ::-webkit-scrollbar-track,
.Vouchers ::-webkit-scrollbar-track,
.Reddit ::-webkit-scrollbar-track,
.Schedule ::-webkit-scrollbar-track,
.Secret ::-webkit-scrollbar-track,
.Ambigrams ::-webkit-scrollbar-track,
.MusicAdmin ::-webkit-scrollbar-track,
.SelfAssessment ::-webkit-scrollbar-track {
  background: white;
}

.Admin ::-webkit-scrollbar-thumb,
.Orders ::-webkit-scrollbar-thumb,
.Invoices ::-webkit-scrollbar-thumb,
.Items ::-webkit-scrollbar-thumb,
.Vouchers ::-webkit-scrollbar-thumb,
.Reddit ::-webkit-scrollbar-thumb,
.Schedule ::-webkit-scrollbar-thumb,
.Secret ::-webkit-scrollbar-thumb,
.Ambigrams ::-webkit-scrollbar-thumb,
.MusicAdmin ::-webkit-scrollbar-thumb,
.SelfAssessment ::-webkit-scrollbar-thumb {
  background: black;
}

.Admin .hidden,
.Orders .hidden,
.Invoices .hidden,
.Items .hidden,
.Vouchers .hidden,
.Reddit .hidden,
.Schedule .hidden,
.Secret .hidden,
.Ambigrams .hidden,
.MusicAdmin .hidden,
.SelfAssessment .hidden {
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.Admin .dateSelect,
.Orders .dateSelect,
.Invoices .dateSelect,
.Items .dateSelect,
.Vouchers .dateSelect,
.Reddit .dateSelect,
.Schedule .dateSelect,
.Secret .dateSelect,
.Ambigrams .dateSelect,
.MusicAdmin .dateSelect,
.SelfAssessment .dateSelect {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 8rem;
}

.Admin .dateSelect .dateInner,
.Orders .dateSelect .dateInner,
.Invoices .dateSelect .dateInner,
.Items .dateSelect .dateInner,
.Vouchers .dateSelect .dateInner,
.Reddit .dateSelect .dateInner,
.Schedule .dateSelect .dateInner,
.Secret .dateSelect .dateInner,
.Ambigrams .dateSelect .dateInner,
.MusicAdmin .dateSelect .dateInner,
.SelfAssessment .dateSelect .dateInner {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
}

.Admin .dateSelect .dateInner:hover .arrow,
.Orders .dateSelect .dateInner:hover .arrow,
.Invoices .dateSelect .dateInner:hover .arrow,
.Items .dateSelect .dateInner:hover .arrow,
.Vouchers .dateSelect .dateInner:hover .arrow,
.Reddit .dateSelect .dateInner:hover .arrow,
.Schedule .dateSelect .dateInner:hover .arrow,
.Secret .dateSelect .dateInner:hover .arrow,
.Ambigrams .dateSelect .dateInner:hover .arrow,
.MusicAdmin .dateSelect .dateInner:hover .arrow,
.SelfAssessment .dateSelect .dateInner:hover .arrow {
  visibility: visible;
}

.Admin .dateSelect .dateInner .arrow,
.Orders .dateSelect .dateInner .arrow,
.Invoices .dateSelect .dateInner .arrow,
.Items .dateSelect .dateInner .arrow,
.Vouchers .dateSelect .dateInner .arrow,
.Reddit .dateSelect .dateInner .arrow,
.Schedule .dateSelect .dateInner .arrow,
.Secret .dateSelect .dateInner .arrow,
.Ambigrams .dateSelect .dateInner .arrow,
.MusicAdmin .dateSelect .dateInner .arrow,
.SelfAssessment .dateSelect .dateInner .arrow {
  width: 100%;
  font-size: small;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  visibility: hidden;
}

.Admin .RedditCampaigns input,
.Orders .RedditCampaigns input,
.Invoices .RedditCampaigns input,
.Items .RedditCampaigns input,
.Vouchers .RedditCampaigns input,
.Reddit .RedditCampaigns input,
.Schedule .RedditCampaigns input,
.Secret .RedditCampaigns input,
.Ambigrams .RedditCampaigns input,
.MusicAdmin .RedditCampaigns input,
.SelfAssessment .RedditCampaigns input {
  margin-bottom: 1rem;
}

.Admin .RedditCampaigns .buts,
.Orders .RedditCampaigns .buts,
.Invoices .RedditCampaigns .buts,
.Items .RedditCampaigns .buts,
.Vouchers .RedditCampaigns .buts,
.Reddit .RedditCampaigns .buts,
.Schedule .RedditCampaigns .buts,
.Secret .RedditCampaigns .buts,
.Ambigrams .RedditCampaigns .buts,
.MusicAdmin .RedditCampaigns .buts,
.SelfAssessment .RedditCampaigns .buts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.Admin .Check,
.Orders .Check,
.Invoices .Check,
.Items .Check,
.Vouchers .Check,
.Reddit .Check,
.Schedule .Check,
.Secret .Check,
.Ambigrams .Check,
.MusicAdmin .Check,
.SelfAssessment .Check {
  border: 1px solid black;
  width: 0.75rem;
  height: 0.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: small;
  padding: 1px;
}

.Admin .Check:hover,
.Orders .Check:hover,
.Invoices .Check:hover,
.Items .Check:hover,
.Vouchers .Check:hover,
.Reddit .Check:hover,
.Schedule .Check:hover,
.Secret .Check:hover,
.Ambigrams .Check:hover,
.MusicAdmin .Check:hover,
.SelfAssessment .Check:hover {
  text-decoration: none !important;
}

.Admin .small,
.Orders .small,
.Invoices .small,
.Items .small,
.Vouchers .small,
.Reddit .small,
.Schedule .small,
.Secret .small,
.Ambigrams .small,
.MusicAdmin .small,
.SelfAssessment .small {
  font-size: smaller;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.Admin input,
.Orders input,
.Invoices input,
.Items input,
.Vouchers input,
.Reddit input,
.Schedule input,
.Secret input,
.Ambigrams input,
.MusicAdmin input,
.SelfAssessment input {
  border: 1px solid black;
  outline: none;
}

.Admin h1,
.Orders h1,
.Invoices h1,
.Items h1,
.Vouchers h1,
.Reddit h1,
.Schedule h1,
.Secret h1,
.Ambigrams h1,
.MusicAdmin h1,
.SelfAssessment h1 {
  margin: 0px;
  margin-bottom: 1rem;
}

.Admin a,
.Orders a,
.Invoices a,
.Items a,
.Vouchers a,
.Reddit a,
.Schedule a,
.Secret a,
.Ambigrams a,
.MusicAdmin a,
.SelfAssessment a {
  color: black;
}

.Admin .T,
.Orders .T,
.Invoices .T,
.Items .T,
.Vouchers .T,
.Reddit .T,
.Schedule .T,
.Secret .T,
.Ambigrams .T,
.MusicAdmin .T,
.SelfAssessment .T {
  cursor: pointer;
}

.Admin .T:hover,
.Orders .T:hover,
.Invoices .T:hover,
.Items .T:hover,
.Vouchers .T:hover,
.Reddit .T:hover,
.Schedule .T:hover,
.Secret .T:hover,
.Ambigrams .T:hover,
.MusicAdmin .T:hover,
.SelfAssessment .T:hover {
  text-decoration: underline;
}

.Admin .T.checked,
.Orders .T.checked,
.Invoices .T.checked,
.Items .T.checked,
.Vouchers .T.checked,
.Reddit .T.checked,
.Schedule .T.checked,
.Secret .T.checked,
.Ambigrams .T.checked,
.MusicAdmin .T.checked,
.SelfAssessment .T.checked {
  background-color: black;
  color: white;
}

.Admin .A,
.Orders .A,
.Invoices .A,
.Items .A,
.Vouchers .A,
.Reddit .A,
.Schedule .A,
.Secret .A,
.Ambigrams .A,
.MusicAdmin .A,
.SelfAssessment .A {
  cursor: pointer;
}

.Admin .A:hover,
.Orders .A:hover,
.Invoices .A:hover,
.Items .A:hover,
.Vouchers .A:hover,
.Reddit .A:hover,
.Schedule .A:hover,
.Secret .A:hover,
.Ambigrams .A:hover,
.MusicAdmin .A:hover,
.SelfAssessment .A:hover {
  text-decoration: underline;
}

.Admin .disabledA,
.Orders .disabledA,
.Invoices .disabledA,
.Items .disabledA,
.Vouchers .disabledA,
.Reddit .disabledA,
.Schedule .disabledA,
.Secret .disabledA,
.Ambigrams .disabledA,
.MusicAdmin .disabledA,
.SelfAssessment .disabledA {
  position: relative;
  font-style: italic;
  cursor: default;
}

.Admin .disabledA::after,
.Orders .disabledA::after,
.Invoices .disabledA::after,
.Items .disabledA::after,
.Vouchers .disabledA::after,
.Reddit .disabledA::after,
.Schedule .disabledA::after,
.Secret .disabledA::after,
.Ambigrams .disabledA::after,
.MusicAdmin .disabledA::after,
.SelfAssessment .disabledA::after {
  position: absolute;
  left: 0px;
  border-top: 2px solid black;
  background-color: black;
  top: 0.6rem;
  width: 100%;
  -webkit-transform: rotate(-3deg);
          transform: rotate(-3deg);
  content: " ";
}

.Admin .indent,
.Orders .indent,
.Invoices .indent,
.Items .indent,
.Vouchers .indent,
.Reddit .indent,
.Schedule .indent,
.Secret .indent,
.Ambigrams .indent,
.MusicAdmin .indent,
.SelfAssessment .indent {
  margin-bottom: 0.5rem;
  margin-left: 2rem;
}

.Admin .mb,
.Orders .mb,
.Invoices .mb,
.Items .mb,
.Vouchers .mb,
.Reddit .mb,
.Schedule .mb,
.Secret .mb,
.Ambigrams .mb,
.MusicAdmin .mb,
.SelfAssessment .mb {
  margin-bottom: 0.5rem;
}

.Admin .mt,
.Orders .mt,
.Invoices .mt,
.Items .mt,
.Vouchers .mt,
.Reddit .mt,
.Schedule .mt,
.Secret .mt,
.Ambigrams .mt,
.MusicAdmin .mt,
.SelfAssessment .mt {
  margin-top: 0.5rem;
}

.Admin .i,
.Orders .i,
.Invoices .i,
.Items .i,
.Vouchers .i,
.Reddit .i,
.Schedule .i,
.Secret .i,
.Ambigrams .i,
.MusicAdmin .i,
.SelfAssessment .i {
  font-style: italic;
}

.Admin .AreYouSure,
.Orders .AreYouSure,
.Invoices .AreYouSure,
.Items .AreYouSure,
.Vouchers .AreYouSure,
.Reddit .AreYouSure,
.Schedule .AreYouSure,
.Secret .AreYouSure,
.Ambigrams .AreYouSure,
.MusicAdmin .AreYouSure,
.SelfAssessment .AreYouSure {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.Admin .AreYouSure div,
.Orders .AreYouSure div,
.Invoices .AreYouSure div,
.Items .AreYouSure div,
.Vouchers .AreYouSure div,
.Reddit .AreYouSure div,
.Schedule .AreYouSure div,
.Secret .AreYouSure div,
.Ambigrams .AreYouSure div,
.MusicAdmin .AreYouSure div,
.SelfAssessment .AreYouSure div {
  margin-right: 0.5rem;
}

.Admin .title,
.Orders .title,
.Invoices .title,
.Items .title,
.Vouchers .title,
.Reddit .title,
.Schedule .title,
.Secret .title,
.Ambigrams .title,
.MusicAdmin .title,
.SelfAssessment .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin-bottom: 0.5rem;
}

.Admin .title .left,
.Orders .title .left,
.Invoices .title .left,
.Items .title .left,
.Vouchers .title .left,
.Reddit .title .left,
.Schedule .title .left,
.Secret .title .left,
.Ambigrams .title .left,
.MusicAdmin .title .left,
.SelfAssessment .title .left {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: x-large;
  font-weight: bold;
}

.Admin .title .largeBit,
.Orders .title .largeBit,
.Invoices .title .largeBit,
.Items .title .largeBit,
.Vouchers .title .largeBit,
.Reddit .title .largeBit,
.Schedule .title .largeBit,
.Secret .title .largeBit,
.Ambigrams .title .largeBit,
.MusicAdmin .title .largeBit,
.SelfAssessment .title .largeBit {
  font-size: x-large;
  font-weight: bold;
  margin-right: 1rem;
}

.Admin .title .right,
.Orders .title .right,
.Invoices .title .right,
.Items .title .right,
.Vouchers .title .right,
.Reddit .title .right,
.Schedule .title .right,
.Secret .title .right,
.Ambigrams .title .right,
.MusicAdmin .title .right,
.SelfAssessment .title .right {
  font-weight: bold;
}

.Admin .expanded,
.Orders .expanded,
.Invoices .expanded,
.Items .expanded,
.Vouchers .expanded,
.Reddit .expanded,
.Schedule .expanded,
.Secret .expanded,
.Ambigrams .expanded,
.MusicAdmin .expanded,
.SelfAssessment .expanded {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 100;
}

.Admin .expanded.nofade,
.Orders .expanded.nofade,
.Invoices .expanded.nofade,
.Items .expanded.nofade,
.Vouchers .expanded.nofade,
.Reddit .expanded.nofade,
.Schedule .expanded.nofade,
.Secret .expanded.nofade,
.Ambigrams .expanded.nofade,
.MusicAdmin .expanded.nofade,
.SelfAssessment .expanded.nofade {
  background: none;
}

.Admin .expanded .window,
.Orders .expanded .window,
.Invoices .expanded .window,
.Items .expanded .window,
.Vouchers .expanded .window,
.Reddit .expanded .window,
.Schedule .expanded .window,
.Secret .expanded .window,
.Ambigrams .expanded .window,
.MusicAdmin .expanded .window,
.SelfAssessment .expanded .window {
  background-color: white;
  padding: 1rem;
  padding-top: 0px;
  width: 600px;
}

.Admin .expanded .window .modalHeading,
.Orders .expanded .window .modalHeading,
.Invoices .expanded .window .modalHeading,
.Items .expanded .window .modalHeading,
.Vouchers .expanded .window .modalHeading,
.Reddit .expanded .window .modalHeading,
.Schedule .expanded .window .modalHeading,
.Secret .expanded .window .modalHeading,
.Ambigrams .expanded .window .modalHeading,
.MusicAdmin .expanded .window .modalHeading,
.SelfAssessment .expanded .window .modalHeading {
  background-color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: -1rem;
  margin-right: -1rem;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  margin-bottom: 1rem;
}

.Admin .expanded .window .modalHeading .heading,
.Orders .expanded .window .modalHeading .heading,
.Invoices .expanded .window .modalHeading .heading,
.Items .expanded .window .modalHeading .heading,
.Vouchers .expanded .window .modalHeading .heading,
.Reddit .expanded .window .modalHeading .heading,
.Schedule .expanded .window .modalHeading .heading,
.Secret .expanded .window .modalHeading .heading,
.Ambigrams .expanded .window .modalHeading .heading,
.MusicAdmin .expanded .window .modalHeading .heading,
.SelfAssessment .expanded .window .modalHeading .heading {
  padding-left: 1rem;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  color: white;
  font-size: small;
  font-weight: bold;
}

.Admin .expanded .window .modalHeading button,
.Orders .expanded .window .modalHeading button,
.Invoices .expanded .window .modalHeading button,
.Items .expanded .window .modalHeading button,
.Vouchers .expanded .window .modalHeading button,
.Reddit .expanded .window .modalHeading button,
.Schedule .expanded .window .modalHeading button,
.Secret .expanded .window .modalHeading button,
.Ambigrams .expanded .window .modalHeading button,
.MusicAdmin .expanded .window .modalHeading button,
.SelfAssessment .expanded .window .modalHeading button {
  background: none;
  border: none;
  font-family: inherit;
  color: white;
  font-weight: bold;
  font-size: x-small;
  cursor: pointer;
}

.Admin .expanded .window.red,
.Orders .expanded .window.red,
.Invoices .expanded .window.red,
.Items .expanded .window.red,
.Vouchers .expanded .window.red,
.Reddit .expanded .window.red,
.Schedule .expanded .window.red,
.Secret .expanded .window.red,
.Ambigrams .expanded .window.red,
.MusicAdmin .expanded .window.red,
.SelfAssessment .expanded .window.red {
  color: red;
}

.Admin .expanded .window.red .modalHeading,
.Orders .expanded .window.red .modalHeading,
.Invoices .expanded .window.red .modalHeading,
.Items .expanded .window.red .modalHeading,
.Vouchers .expanded .window.red .modalHeading,
.Reddit .expanded .window.red .modalHeading,
.Schedule .expanded .window.red .modalHeading,
.Secret .expanded .window.red .modalHeading,
.Ambigrams .expanded .window.red .modalHeading,
.MusicAdmin .expanded .window.red .modalHeading,
.SelfAssessment .expanded .window.red .modalHeading {
  background-color: red;
}

.Admin .expanded .window.red A,
.Orders .expanded .window.red A,
.Invoices .expanded .window.red A,
.Items .expanded .window.red A,
.Vouchers .expanded .window.red A,
.Reddit .expanded .window.red A,
.Schedule .expanded .window.red A,
.Secret .expanded .window.red A,
.Ambigrams .expanded .window.red A,
.MusicAdmin .expanded .window.red A,
.SelfAssessment .expanded .window.red A {
  color: red;
}

.Admin .expanded .window.red .items,
.Orders .expanded .window.red .items,
.Invoices .expanded .window.red .items,
.Items .expanded .window.red .items,
.Vouchers .expanded .window.red .items,
.Reddit .expanded .window.red .items,
.Schedule .expanded .window.red .items,
.Secret .expanded .window.red .items,
.Ambigrams .expanded .window.red .items,
.MusicAdmin .expanded .window.red .items,
.SelfAssessment .expanded .window.red .items {
  border: 1px solid red;
}

.Admin .expanded .buts,
.Orders .expanded .buts,
.Invoices .expanded .buts,
.Items .expanded .buts,
.Vouchers .expanded .buts,
.Reddit .expanded .buts,
.Schedule .expanded .buts,
.Secret .expanded .buts,
.Ambigrams .expanded .buts,
.MusicAdmin .expanded .buts,
.SelfAssessment .expanded .buts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.Admin .expanded .itemSplit,
.Orders .expanded .itemSplit,
.Invoices .expanded .itemSplit,
.Items .expanded .itemSplit,
.Vouchers .expanded .itemSplit,
.Reddit .expanded .itemSplit,
.Schedule .expanded .itemSplit,
.Secret .expanded .itemSplit,
.Ambigrams .expanded .itemSplit,
.MusicAdmin .expanded .itemSplit,
.SelfAssessment .expanded .itemSplit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.5rem;
}

.Admin .expanded .itemSplit input,
.Orders .expanded .itemSplit input,
.Invoices .expanded .itemSplit input,
.Items .expanded .itemSplit input,
.Vouchers .expanded .itemSplit input,
.Reddit .expanded .itemSplit input,
.Schedule .expanded .itemSplit input,
.Secret .expanded .itemSplit input,
.Ambigrams .expanded .itemSplit input,
.MusicAdmin .expanded .itemSplit input,
.SelfAssessment .expanded .itemSplit input {
  border: none;
}

.Admin .expanded .itemSplit .left,
.Orders .expanded .itemSplit .left,
.Invoices .expanded .itemSplit .left,
.Items .expanded .itemSplit .left,
.Vouchers .expanded .itemSplit .left,
.Reddit .expanded .itemSplit .left,
.Schedule .expanded .itemSplit .left,
.Secret .expanded .itemSplit .left,
.Ambigrams .expanded .itemSplit .left,
.MusicAdmin .expanded .itemSplit .left,
.SelfAssessment .expanded .itemSplit .left {
  width: 120px;
}

.Admin .expanded .itemSplit.lil .right input,
.Orders .expanded .itemSplit.lil .right input,
.Invoices .expanded .itemSplit.lil .right input,
.Items .expanded .itemSplit.lil .right input,
.Vouchers .expanded .itemSplit.lil .right input,
.Reddit .expanded .itemSplit.lil .right input,
.Schedule .expanded .itemSplit.lil .right input,
.Secret .expanded .itemSplit.lil .right input,
.Ambigrams .expanded .itemSplit.lil .right input,
.MusicAdmin .expanded .itemSplit.lil .right input,
.SelfAssessment .expanded .itemSplit.lil .right input {
  width: 4rem;
}

.Admin .expanded .itemSplit .right,
.Orders .expanded .itemSplit .right,
.Invoices .expanded .itemSplit .right,
.Items .expanded .itemSplit .right,
.Vouchers .expanded .itemSplit .right,
.Reddit .expanded .itemSplit .right,
.Schedule .expanded .itemSplit .right,
.Secret .expanded .itemSplit .right,
.Ambigrams .expanded .itemSplit .right,
.MusicAdmin .expanded .itemSplit .right,
.SelfAssessment .expanded .itemSplit .right {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.Admin .expanded .itemSplit .right input,
.Orders .expanded .itemSplit .right input,
.Invoices .expanded .itemSplit .right input,
.Items .expanded .itemSplit .right input,
.Vouchers .expanded .itemSplit .right input,
.Reddit .expanded .itemSplit .right input,
.Schedule .expanded .itemSplit .right input,
.Secret .expanded .itemSplit .right input,
.Ambigrams .expanded .itemSplit .right input,
.MusicAdmin .expanded .itemSplit .right input,
.SelfAssessment .expanded .itemSplit .right input {
  width: 100%;
}

.Admin .expanded .itemSplit .right textarea,
.Orders .expanded .itemSplit .right textarea,
.Invoices .expanded .itemSplit .right textarea,
.Items .expanded .itemSplit .right textarea,
.Vouchers .expanded .itemSplit .right textarea,
.Reddit .expanded .itemSplit .right textarea,
.Schedule .expanded .itemSplit .right textarea,
.Secret .expanded .itemSplit .right textarea,
.Ambigrams .expanded .itemSplit .right textarea,
.MusicAdmin .expanded .itemSplit .right textarea,
.SelfAssessment .expanded .itemSplit .right textarea {
  width: 100%;
  height: 3rem;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: inherit;
}

.Admin .expanded .itemSplit .right.columns,
.Orders .expanded .itemSplit .right.columns,
.Invoices .expanded .itemSplit .right.columns,
.Items .expanded .itemSplit .right.columns,
.Vouchers .expanded .itemSplit .right.columns,
.Reddit .expanded .itemSplit .right.columns,
.Schedule .expanded .itemSplit .right.columns,
.Secret .expanded .itemSplit .right.columns,
.Ambigrams .expanded .itemSplit .right.columns,
.MusicAdmin .expanded .itemSplit .right.columns,
.SelfAssessment .expanded .itemSplit .right.columns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Admin .expanded .itemSplit .right.columns .column,
.Orders .expanded .itemSplit .right.columns .column,
.Invoices .expanded .itemSplit .right.columns .column,
.Items .expanded .itemSplit .right.columns .column,
.Vouchers .expanded .itemSplit .right.columns .column,
.Reddit .expanded .itemSplit .right.columns .column,
.Schedule .expanded .itemSplit .right.columns .column,
.Secret .expanded .itemSplit .right.columns .column,
.Ambigrams .expanded .itemSplit .right.columns .column,
.MusicAdmin .expanded .itemSplit .right.columns .column,
.SelfAssessment .expanded .itemSplit .right.columns .column {
  margin-right: 1rem;
}

.Admin .expanded .itemSplit .right.columns .column .columnTitle,
.Orders .expanded .itemSplit .right.columns .column .columnTitle,
.Invoices .expanded .itemSplit .right.columns .column .columnTitle,
.Items .expanded .itemSplit .right.columns .column .columnTitle,
.Vouchers .expanded .itemSplit .right.columns .column .columnTitle,
.Reddit .expanded .itemSplit .right.columns .column .columnTitle,
.Schedule .expanded .itemSplit .right.columns .column .columnTitle,
.Secret .expanded .itemSplit .right.columns .column .columnTitle,
.Ambigrams .expanded .itemSplit .right.columns .column .columnTitle,
.MusicAdmin .expanded .itemSplit .right.columns .column .columnTitle,
.SelfAssessment .expanded .itemSplit .right.columns .column .columnTitle {
  font-weight: bold;
}

.Admin .expanded .itemSplit .right.columns .column .columnStuff,
.Orders .expanded .itemSplit .right.columns .column .columnStuff,
.Invoices .expanded .itemSplit .right.columns .column .columnStuff,
.Items .expanded .itemSplit .right.columns .column .columnStuff,
.Vouchers .expanded .itemSplit .right.columns .column .columnStuff,
.Reddit .expanded .itemSplit .right.columns .column .columnStuff,
.Schedule .expanded .itemSplit .right.columns .column .columnStuff,
.Secret .expanded .itemSplit .right.columns .column .columnStuff,
.Ambigrams .expanded .itemSplit .right.columns .column .columnStuff,
.MusicAdmin .expanded .itemSplit .right.columns .column .columnStuff,
.SelfAssessment .expanded .itemSplit .right.columns .column .columnStuff {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Admin .horizontalButtons,
.Orders .horizontalButtons,
.Invoices .horizontalButtons,
.Items .horizontalButtons,
.Vouchers .horizontalButtons,
.Reddit .horizontalButtons,
.Schedule .horizontalButtons,
.Secret .horizontalButtons,
.Ambigrams .horizontalButtons,
.MusicAdmin .horizontalButtons,
.SelfAssessment .horizontalButtons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.Admin .expandedInvoice .hours .hoursInner,
.Orders .expandedInvoice .hours .hoursInner,
.Invoices .expandedInvoice .hours .hoursInner,
.Items .expandedInvoice .hours .hoursInner,
.Vouchers .expandedInvoice .hours .hoursInner,
.Reddit .expandedInvoice .hours .hoursInner,
.Schedule .expandedInvoice .hours .hoursInner,
.Secret .expandedInvoice .hours .hoursInner,
.Ambigrams .expandedInvoice .hours .hoursInner,
.MusicAdmin .expandedInvoice .hours .hoursInner,
.SelfAssessment .expandedInvoice .hours .hoursInner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
}

.Admin .expandedInvoice .hours .hoursInner .hoursLeft,
.Orders .expandedInvoice .hours .hoursInner .hoursLeft,
.Invoices .expandedInvoice .hours .hoursInner .hoursLeft,
.Items .expandedInvoice .hours .hoursInner .hoursLeft,
.Vouchers .expandedInvoice .hours .hoursInner .hoursLeft,
.Reddit .expandedInvoice .hours .hoursInner .hoursLeft,
.Schedule .expandedInvoice .hours .hoursInner .hoursLeft,
.Secret .expandedInvoice .hours .hoursInner .hoursLeft,
.Ambigrams .expandedInvoice .hours .hoursInner .hoursLeft,
.MusicAdmin .expandedInvoice .hours .hoursInner .hoursLeft,
.SelfAssessment .expandedInvoice .hours .hoursInner .hoursLeft {
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2;
}

.Admin .expandedInvoice .hours .hoursInner .hoursRight,
.Orders .expandedInvoice .hours .hoursInner .hoursRight,
.Invoices .expandedInvoice .hours .hoursInner .hoursRight,
.Items .expandedInvoice .hours .hoursInner .hoursRight,
.Vouchers .expandedInvoice .hours .hoursInner .hoursRight,
.Reddit .expandedInvoice .hours .hoursInner .hoursRight,
.Schedule .expandedInvoice .hours .hoursInner .hoursRight,
.Secret .expandedInvoice .hours .hoursInner .hoursRight,
.Ambigrams .expandedInvoice .hours .hoursInner .hoursRight,
.MusicAdmin .expandedInvoice .hours .hoursInner .hoursRight,
.SelfAssessment .expandedInvoice .hours .hoursInner .hoursRight {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Admin .expandedInvoice .hours .hoursInner .hoursRight input,
.Orders .expandedInvoice .hours .hoursInner .hoursRight input,
.Invoices .expandedInvoice .hours .hoursInner .hoursRight input,
.Items .expandedInvoice .hours .hoursInner .hoursRight input,
.Vouchers .expandedInvoice .hours .hoursInner .hoursRight input,
.Reddit .expandedInvoice .hours .hoursInner .hoursRight input,
.Schedule .expandedInvoice .hours .hoursInner .hoursRight input,
.Secret .expandedInvoice .hours .hoursInner .hoursRight input,
.Ambigrams .expandedInvoice .hours .hoursInner .hoursRight input,
.MusicAdmin .expandedInvoice .hours .hoursInner .hoursRight input,
.SelfAssessment .expandedInvoice .hours .hoursInner .hoursRight input {
  position: relative;
  top: -1px;
}

.Admin .expandedInvoice .hours .hoursInner .x,
.Orders .expandedInvoice .hours .hoursInner .x,
.Invoices .expandedInvoice .hours .hoursInner .x,
.Items .expandedInvoice .hours .hoursInner .x,
.Vouchers .expandedInvoice .hours .hoursInner .x,
.Reddit .expandedInvoice .hours .hoursInner .x,
.Schedule .expandedInvoice .hours .hoursInner .x,
.Secret .expandedInvoice .hours .hoursInner .x,
.Ambigrams .expandedInvoice .hours .hoursInner .x,
.MusicAdmin .expandedInvoice .hours .hoursInner .x,
.SelfAssessment .expandedInvoice .hours .hoursInner .x {
  width: 1rem;
}

.Admin .expandedInvoice .hours .hoursInner.total,
.Orders .expandedInvoice .hours .hoursInner.total,
.Invoices .expandedInvoice .hours .hoursInner.total,
.Items .expandedInvoice .hours .hoursInner.total,
.Vouchers .expandedInvoice .hours .hoursInner.total,
.Reddit .expandedInvoice .hours .hoursInner.total,
.Schedule .expandedInvoice .hours .hoursInner.total,
.Secret .expandedInvoice .hours .hoursInner.total,
.Ambigrams .expandedInvoice .hours .hoursInner.total,
.MusicAdmin .expandedInvoice .hours .hoursInner.total,
.SelfAssessment .expandedInvoice .hours .hoursInner.total {
  font-size: large;
}

.Admin .selfAssessmentOptions,
.Orders .selfAssessmentOptions,
.Invoices .selfAssessmentOptions,
.Items .selfAssessmentOptions,
.Vouchers .selfAssessmentOptions,
.Reddit .selfAssessmentOptions,
.Schedule .selfAssessmentOptions,
.Secret .selfAssessmentOptions,
.Ambigrams .selfAssessmentOptions,
.MusicAdmin .selfAssessmentOptions,
.SelfAssessment .selfAssessmentOptions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.Admin .selfAssessmentOptions .taxYearCheck,
.Orders .selfAssessmentOptions .taxYearCheck,
.Invoices .selfAssessmentOptions .taxYearCheck,
.Items .selfAssessmentOptions .taxYearCheck,
.Vouchers .selfAssessmentOptions .taxYearCheck,
.Reddit .selfAssessmentOptions .taxYearCheck,
.Schedule .selfAssessmentOptions .taxYearCheck,
.Secret .selfAssessmentOptions .taxYearCheck,
.Ambigrams .selfAssessmentOptions .taxYearCheck,
.MusicAdmin .selfAssessmentOptions .taxYearCheck,
.SelfAssessment .selfAssessmentOptions .taxYearCheck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0.5rem;
}

.Admin .selfAssessmentOptions .taxYearCheck .right,
.Orders .selfAssessmentOptions .taxYearCheck .right,
.Invoices .selfAssessmentOptions .taxYearCheck .right,
.Items .selfAssessmentOptions .taxYearCheck .right,
.Vouchers .selfAssessmentOptions .taxYearCheck .right,
.Reddit .selfAssessmentOptions .taxYearCheck .right,
.Schedule .selfAssessmentOptions .taxYearCheck .right,
.Secret .selfAssessmentOptions .taxYearCheck .right,
.Ambigrams .selfAssessmentOptions .taxYearCheck .right,
.MusicAdmin .selfAssessmentOptions .taxYearCheck .right,
.SelfAssessment .selfAssessmentOptions .taxYearCheck .right {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-left: 0.5rem;
}

.Admin .selfAssessmentOptions .taxYearCheck .Check,
.Orders .selfAssessmentOptions .taxYearCheck .Check,
.Invoices .selfAssessmentOptions .taxYearCheck .Check,
.Items .selfAssessmentOptions .taxYearCheck .Check,
.Vouchers .selfAssessmentOptions .taxYearCheck .Check,
.Reddit .selfAssessmentOptions .taxYearCheck .Check,
.Schedule .selfAssessmentOptions .taxYearCheck .Check,
.Secret .selfAssessmentOptions .taxYearCheck .Check,
.Ambigrams .selfAssessmentOptions .taxYearCheck .Check,
.MusicAdmin .selfAssessmentOptions .taxYearCheck .Check,
.SelfAssessment .selfAssessmentOptions .taxYearCheck .Check {
  padding-bottom: 2px;
  padding-top: 0px;
}

.Admin .total,
.Orders .total,
.Invoices .total,
.Items .total,
.Vouchers .total,
.Reddit .total,
.Schedule .total,
.Secret .total,
.Ambigrams .total,
.MusicAdmin .total,
.SelfAssessment .total {
  font-size: x-large;
  margin-bottom: 1rem;
}

.Reddit .RedditUsers .expanded .user {
  margin-bottom: 0px;
}

.Reddit .RedditUsers .user {
  margin-bottom: 1rem;
}

.Reddit .RedditUsers .user h2 {
  margin: 0px;
  margin-bottom: 0.5rem;
}

.Reddit .RedditUsers .user .split {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Reddit .RedditUsers .user .split .left {
  width: 5rem;
}

.Reddit .clickMe {
  cursor: pointer;
}

.Reddit .RedditCalendar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.Reddit .RedditCalendar .week {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Reddit .RedditCalendar .week.topbit .hour {
  font-weight: bold;
}

.Reddit .RedditCalendar .week .dayTitle {
  width: 2rem;
  margin-right: 0.5rem;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.Reddit .RedditCalendar .week .hour {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
  height: 1.3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Reddit .BarGraph .graph {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Reddit .BarGraph .graph .bar {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.Reddit .BarGraph .graph .bar.zero {
  visibility: hidden;
}

.Reddit .BarGraph .graph .bar .inner {
  margin-left: -1;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  border: 1px solid black;
  background-color: black;
  width: 100%;
}

.Reddit .BarGraph .graph .bar .inner.black {
  background-color: white;
}

.Reddit .BarGraph .graph .bar .blank {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.Reddit .PieChart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Reddit .PieChart .pie {
  margin-right: 1rem;
}

.Reddit .PieChart .data .datum {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: bold;
  cursor: default;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-top: 0.1rem;
}

.Reddit .PieChart .data .datum.selected {
  background-color: black;
  color: white;
}

.Reddit .PieChart .data .datum .percent {
  width: 2.5rem;
}

.Reddit .PieChart .data .datum .swatch {
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.5rem;
  position: relative;
  top: 0.125rem;
}

.Vouchers .addVoucher {
  margin-bottom: 1rem;
  font-size: large;
}

.Vouchers .expanded .window h2 {
  margin: 0px;
  margin-bottom: 0.5rem;
}

.Vouchers .expanded .window .voucherSplit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.5rem;
}

.Vouchers .expanded .window .voucherSplit .left {
  width: 6rem;
}

.Vouchers .expanded .window .voucherSplit .right {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.Vouchers .expanded .window .voucherSplit input {
  border: none;
}

.Vouchers .expanded .window .buts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.Vouchers .voucher {
  margin-bottom: 1rem;
}

.Vouchers .voucher h2 {
  margin: 0px;
  margin-bottom: 0.5rem;
}

.Vouchers .voucher h4 {
  margin: 0px;
}

.Vouchers .voucher .voucherSplit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Vouchers .voucher .voucherSplit .left {
  width: 5rem;
}

.Vouchers .voucher .voucherSplit .right {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.Items .addItem {
  font-size: large;
  margin-bottom: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.Items .item {
  margin-bottom: 1rem;
}

.Items .item .clickme {
  cursor: pointer;
}

.Items .item .images {
  margin-top: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Items .item .images img {
  height: 64px;
  margin-right: 0.5rem;
}

.Items .item:hover .hideButtons {
  visibility: inherit;
}

.Items .item .hideButtons {
  visibility: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Items .item .hideButtons .A,
.Items .item .hideButtons .AreYouSure {
  margin-right: 1rem;
}

.Items .buts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.Items .expanded .variations {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.Items .expanded .variations input {
  width: 7.5rem !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.Items .expanded .variations h4 {
  margin: 0px;
}

.Items .expanded .variations .variation {
  width: 7rem;
  margin-right: 1rem;
}

.Items .expanded .variations .variation .variationTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Items .expanded .variations .variation .aLeft {
  font-size: small;
  margin-right: 0.2rem;
}

.Items .expanded .variations .variation .aRight {
  font-size: small;
  margin-left: 0.2rem;
}

.Items .expanded .variations .variation .hide {
  visibility: hidden;
}

.Items .expanded .variations .variation:hover .hide {
  visibility: inherit;
}

.Items .expanded .variations .variationOptions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Items .expanded .variations .variationOptions .optionsLeft {
  width: 5rem;
}

.Items .expanded .variations .variationOptions .optionsLeft input {
  width: 4.5rem !important;
}

.Items .expanded .variations .variationOptions .optionsRight {
  width: 2rem;
}

.Items .expanded .variations .variationOptions .optionsRight input {
  width: 1.5rem !important;
}

.Items .expanded .images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Items .expanded .images img {
  height: 64px;
  top: 0px;
  left: 0px;
}

.Items .expanded .images .image {
  margin-right: 0.5rem;
  height: calc(64px + 1.5rem);
}

.Items .expanded .images .image:hover .imageOptions {
  visibility: inherit;
}

.Items .expanded .images .image .imageOptions {
  visibility: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: small;
}

.Items .expanded .images .image .imageOptions .x {
  position: relative;
  top: -0.15rem;
}

.Items .expanded .images .image .imageOptions .nothing {
  width: 1rem;
  height: 1rem;
}

.Items .expanded .images .uploadButton {
  width: 64px;
  height: 64px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: x-large;
  border: 1px solid black;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.Items .expanded .images .uploader .A:hover {
  text-decoration: none;
}

.Orders .order {
  cursor: pointer;
  margin-bottom: 1rem;
}

.Orders .order.red {
  color: red;
}

.Orders .order.red A {
  color: red;
}

.Orders .order.red .items {
  border: 1px solid red;
}

.Orders .order:hover .title .left {
  text-decoration: underline;
}

.Orders .items {
  padding: 0.5rem;
  border: 1px solid black;
  margin-bottom: 0.5rem;
}

.Orders .items .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Orders .items .item .left {
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2;
}

.Orders .items .item .right {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.Orders .info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.5rem;
}

.Orders .info .left {
  width: 100px;
  font-weight: bold;
}

.Orders .info .right {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.disabled {
  opacity: 0.5;
}

.Schedule ul {
  margin: 0px;
  padding-left: 1.5rem;
}

.Schedule ul li {
  margin: 0px;
  padding: 0px;
}

.Schedule .editingToggle {
  margin-bottom: 1rem;
}

.Schedule .editingToggle .T {
  font-size: large;
}

.Schedule .schedule {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Schedule .schedule .today {
  font-size: x-large;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border: 1px solid black;
  padding: 0.5rem;
}

.Schedule .editingSchedule .day {
  margin-bottom: 0.5rem;
}

.Schedule .editingSchedule .day.red {
  color: red;
}

.Schedule .editingSchedule .day:hover .hideButtons {
  visibility: inherit;
}

.Schedule .editingSchedule .day .hideButtons {
  visibility: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.Schedule .editingSchedule .day .hideButtons.visible {
  visibility: inherit;
}

.Schedule .editingSchedule .day .scheduleItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.Schedule .editingSchedule .day .scheduleItem .x {
  visibility: hidden;
}

.Schedule .editingSchedule .day .scheduleItem .scheduleTitle {
  padding: 0px;
}

.Schedule .editingSchedule .day .scheduleItem:hover .x {
  visibility: inherit;
}

.SelfAssessment .selfAssessmentGraphs .graph {
  margin-top: 1rem;
  position: relative;
}

.SelfAssessment .selfAssessmentGraphs .graph .label {
  position: absolute;
  height: 1rem;
  left: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: small;
  pointer-events: none;
}

.SelfAssessment .selfAssessmentGraphs .graph .mouseLabel {
  position: absolute;
  pointer-events: none;
}

.SelfAssessment .selfAssessmentGraphs .graph .mouseLabel .innerLabel {
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
  width: 15rem;
  text-align: right;
  font-size: small;
}

.SelfAssessment .selfAssessmentGraphs .graph svg {
  stroke: black;
  stroke-width: 1px;
  fill: none;
}

.SelfAssessment .selfAssessmentGraphs .str {
  font-size: small;
}

.SelfAssessment .selfAssessmentGraphs .profit span {
  font-size: x-large;
}

.SelfAssessment .selfAssessmentGraphs .equidistant {
  margin-bottom: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 0px;
  position: relative;
  top: -1rem;
  pointer-events: none;
}

.SelfAssessment .bulkIncome .records {
  max-height: 500px;
  overflow-y: scroll;
}

.SelfAssessment .selfAssessmentCompiler .timeline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

.SelfAssessment .selfAssessmentCompiler .timeline .year {
  font-size: x-large;
}

.SelfAssessment .selfAssessmentCompiler .timeline .fart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

.SelfAssessment .selfAssessmentCompiler .timeline .fg {
  color: black;
}

.SelfAssessment .selfAssessmentCompiler .timeline .red {
  color: red;
}

.SelfAssessment .addButtons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
  font-size: large;
}

.SelfAssessment .record {
  cursor: pointer;
  margin-bottom: 1rem;
}

.SelfAssessment .record .title {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.SelfAssessment .record .title .left {
  font-size: medium;
}

.SelfAssessment .record .title .left .smallDate {
  font-style: italic;
}

.SelfAssessment .record .title .middle {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
}

.SelfAssessment .record .title .amount {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: right;
  font-size: x-large;
}

.SelfAssessment .expandedCompilation .fuckme {
  font-style: italic;
}

.SelfAssessment .expandedCompilation .bigballs {
  font-size: x-large;
}

.Ambigram .inner {
  max-width: 800px;
  padding: 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
}

.Ambigram .inner .noSpaces li {
  margin-bottom: 1rem;
}

.Ambigram .inner .noSpaces .mb {
  margin-bottom: 1rem;
}

.Ambigram .inner .noSpaces p {
  margin: 0px;
}

.Ambigram .inner h1 {
  text-align: center;
}

.Ambigram .inner .ambibox0 {
  text-align: center;
  position: relative;
}

.Ambigram .inner .ambibox0.spinning {
  z-index: 10;
}

.Ambigram .inner .ambibox0 .tooltip {
  padding: 0.25rem;
  background-color: white;
  border: 1px solid black;
  position: absolute;
  right: 0%;
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  z-index: 100;
}

.Ambigram .inner .ambibox0 .tooltip.visible {
  opacity: 1;
}

.Ambigram .inner .ambibox0 .ambibox1 {
  -webkit-transition: -webkit-transform 0.1s linear;
  transition: -webkit-transform 0.1s linear;
  transition: transform 0.1s linear;
  transition: transform 0.1s linear, -webkit-transform 0.1s linear;
}

.Ambigram .inner .ambibox0 .ambibox1 .ambigram {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.26, 0.18, 0, 1);
  transition: -webkit-transform 1s cubic-bezier(0.26, 0.18, 0, 1);
  transition: transform 1s cubic-bezier(0.26, 0.18, 0, 1);
  transition: transform 1s cubic-bezier(0.26, 0.18, 0, 1), -webkit-transform 1s cubic-bezier(0.26, 0.18, 0, 1);
  max-width: 100%;
  cursor: pointer;
}

.Dropdown {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Dropdown .down {
  width: 10rem;
  background-color: white;
  border: 1px solid black;
  padding: 0.25rem;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.5rem;
}

.Dropdown .down .selected {
  background-color: black;
  color: white;
  padding: 0.25rem;
  margin: -0.25rem;
}

.Secret .secretInput {
  width: 100%;
  text-align: center;
  background-color: none;
  border: none;
  cursor: default;
}

.Secret .secretItems {
  margin-bottom: 1rem;
}

.Secret .secretItems h3 {
  margin-bottom: 0.5rem;
}

.Secret .secretItems p {
  margin: 0px;
}

.Secret .secretItems img {
  max-width: 500px;
}

.Invoices .clientList {
  margin-top: 0.5rem;
}

.Invoices .clientList .clientListItem {
  font-style: italic;
}

.Invoices .addButtons {
  margin-bottom: 1rem;
  font-size: large;
}

.Invoices .client {
  cursor: pointer;
  margin-bottom: 1rem;
}

.Invoices .client:hover .largeBit {
  text-decoration: underline;
}

.Invoices .invoice {
  cursor: pointer;
  margin-bottom: 1rem;
}

.Invoices .invoice.red {
  color: red;
}

.Invoices .invoice.red A {
  color: red;
}

.Invoices .invoice.red .items {
  border: 1px solid red;
}

.Invoices .items {
  padding: 0.5rem;
  border: 1px solid black;
  margin-bottom: 0.5rem;
}

.Invoices .items .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Invoices .items .item .left {
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2;
}

.Invoices .items .item .right {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.Invoices .items .item.itemTotal {
  font-size: large;
  font-weight: bold;
}

.Invoices .info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.5rem;
}

.Invoices .info .left {
  width: 100px;
  font-weight: bold;
}

.Invoices .info .right {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.Invoices .newInvoice .window h2 {
  margin: 0px;
  margin-bottom: 0.5rem;
}

.Invoices .newInvoice .window .invoiceSplit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.5rem;
}

.Invoices .newInvoice .window .invoiceSplit .left {
  width: 6rem;
}

.Invoices .newInvoice .window .invoiceSplit .right {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.Invoices .newInvoice .window .invoiceSplit input {
  border: none;
}

.Invoices .newInvoice .window .buts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.faded {
  cursor: default !important;
  opacity: 0.25;
}

.Ambigrams.app {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(100%);
  padding: 0px;
}

.Ambigrams.app .Mainbit {
  padding: 1rem;
  height: 100vh;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.Ambigrams.app .Mainbit .UploadTab {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.Ambigrams.app .Mainbit .UploadTab .VideoThumb {
  display: inline;
}

.Ambigrams.app .Mainbit .UploadTab .VideoThumb img {
  width: 100px;
}

.Ambigrams.app .Mainbit .UploadTab .topBit {
  width: 100%;
  min-height: 178px;
}

.Ambigrams.app .Mainbit .UploadTab .topBit .videoThumbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
}

.Ambigrams.app .Mainbit .UploadTab .topBit .videoThumbs .emptyThumb {
  width: 100px;
  height: 178px;
  border: 1px solid black;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: xxx-large;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Ambigrams.app .Mainbit .UploadTab .topBit .videoThumbs .emptyThumb img {
  pointer-events: none;
  width: 100px;
}

.Ambigrams.app .Mainbit .UploadTab .topBit .videoThumbs .emptyThumb div {
  position: relative;
  top: -2px;
  cursor: default;
}

.Ambigrams.app .Mainbit .UploadTab .topBit .videoThumbs .dotdotdot {
  font-size: xx-large;
  height: 166px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  max-width: 120px;
}

.Ambigrams.app .Mainbit .UploadTab .bottomBit {
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow-y: scroll;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Ambigrams.app .Mainbit .UploadTab .bottomBit .videoThumbs {
  margin: auto;
  margin-left: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 1rem;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
}

.Ambigrams.app .Mainbit .UploadTab .bottomBit .videoThumbs .VideoThumb {
  position: relative;
}

.Ambigrams.app .Mainbit .UploadTab .bottomBit .videoThumbs .VideoThumb img {
  width: 150px;
  cursor: move;
}

.Ambigrams.app .Mainbit .UploadTab .bottomBit .videoThumbs .VideoThumb .info {
  font-size: large;
  position: absolute;
  bottom: 1.5rem;
}

.Ambigrams.app .Mainbit .UploadTab .bottomBit .videoThumbs .VideoThumb .info span {
  background-color: white;
}

.Ambigrams.app .Mainbit .projectsList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 1rem;
  margin: auto;
  margin-left: 0px;
}

.Ambigrams.app .Mainbit .projectsList .project {
  padding: 1rem;
  border: 1px solid black;
  margin-bottom: 0px;
}

.Ambigrams.app .Mainbit .projectsList .project:hover {
  background-color: black;
  color: white;
}

.Ambigrams.app .Mainbit .projectsList .project.first {
  margin-left: -1rem;
}

.Ambigrams.app .expanded {
  width: calc(100% - 16rem);
  left: 16rem;
}

.Ambigrams.app .sidebar {
  width: 16rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: xx-large;
  padding: 2rem;
  background-color: black;
  color: white;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 2rem;
}

.Ambigrams.app .sidebar .ambigramTabs span {
  font-size: medium;
  position: relative;
  font-weight: bold;
  left: 0.5rem;
}

.Ambigrams.app .sidebar .addButton {
  z-index: 1;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  background-color: black;
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid black;
  cursor: pointer;
}

.Ambigrams.app .sidebar .addButton:hover {
  background-color: white;
  color: black;
}

.Ambigrams.app .sidebar .tab {
  position: relative;
  cursor: pointer;
}

.Ambigrams.app .sidebar .tab:hover {
  left: 0.5rem;
}

.Ambigrams.app .sidebar .tab:hover.faded {
  left: 0rem;
}

.Ambigrams.app .sidebar .tab:hover.faded.selected {
  left: 0.5rem;
}

.Ambigrams.app .sidebar .tab.faded {
  opacity: 0.5;
}

.Ambigrams.app .sidebar .tab.selected {
  left: 0.5rem;
}

.Ambigrams.app .sidebar .tab.selected::before {
  position: absolute;
  left: -1.5rem;
  top: 1px;
  content: '>';
}

.Ambigrams.app .sidebar .tab.tool .Popup {
  z-index: 101;
  position: fixed;
  width: 0px;
  height: 0px;
  left: 16.5rem;
  cursor: default;
}

@-webkit-keyframes popup {
  from {
    top: 0rem;
    min-width: 0px;
    min-height: 0px;
  }
  to {
    top: -3rem;
    left: 0rem;
    min-width: 6rem;
    min-height: 6rem;
  }
}

@keyframes popup {
  from {
    top: 0rem;
    min-width: 0px;
    min-height: 0px;
  }
  to {
    top: -3rem;
    left: 0rem;
    min-width: 6rem;
    min-height: 6rem;
  }
}

.Ambigrams.app .sidebar .tab.tool .Popup .popup {
  position: relative;
  background-color: white;
  border: 1px solid black;
  top: -3rem;
  -webkit-animation-name: popup;
          animation-name: popup;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  min-width: 6rem;
  min-height: 6rem;
  color: black;
  font-size: large;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Ambigrams.app .sidebar .tab.tool .Popup .popup .popupBG .swatch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: white;
  width: 6rem;
  height: 6rem;
}

.Ambigrams.app .sidebar .tab.tool .Popup .popup .popupBG .swatch.yellow {
  color: black;
}

.Ambigrams.app .sidebar .tab.tool .Popup .popup .popupQuery {
  padding: 1rem;
  white-space: nowrap;
}

.Ambigrams.app .sidebar .tab.tool .Popup .popup .popupQuery div {
  font-size: x-large;
}

.Ambigrams.app .sidebar .tab.tool .Popup .popup .popupQuery input {
  width: 6rem;
  margin-top: 0.5rem;
}

.Ambigrams.app .sidebar .tab.tool .Popup .popup .popupMusic {
  white-space: nowrap;
  padding: 1rem;
  font-size: larger;
}

.Ambigrams.app .sidebar .tab.tool .Popup .popup .popupComps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 10.5rem;
}

.Ambigrams.app .sidebar .tab.tool .Popup .popup .popupComps .soyboy {
  text-align: center;
}

.Ambigrams.app .sidebar .tab.tool .Popup .popup .popupComps .compList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Ambigrams.app .sidebar .tab.tool .Popup .popup .popupComps .compList div {
  font-size: x-large;
}

.Ambigrams.app .sidebar .tab.tool .Popup .popup .popupComps .compList div.lil {
  font-size: large;
}

.Ambigrams.app .sidebar .tab.tool .Popup .popup .popupComps .compbtns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.Ambigrams.app .sidebar .tab.tool .Popup .popup .popupComps .compbtns.clicked .compbtn:hover {
  background-color: white;
  color: black;
}

.Ambigrams.app .sidebar .tab.tool .Popup .popup .popupComps .compbtns .compbtn {
  width: 2rem;
  height: 2rem;
  border: 1px solid black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}

.Ambigrams.app .sidebar .tab.tool .Popup .popup .popupComps .compbtns .compbtn:hover {
  background-color: black;
  color: white;
}

.Ambigrams.app .i {
  font-size: x-large;
}

.Ambigrams .tabSplit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.Ambigrams .ConfirmUpload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  font-size: x-large;
}

.Ambigrams .ConfirmUpload .deets {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.Ambigrams .ConfirmUpload .deets h2 {
  margin: 0px;
}

.Ambigrams .ConfirmUpload .deets img {
  max-width: 200px;
}

.Ambigrams .ConfirmUpload .deets .rightbit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Ambigrams .Comments .comment {
  text-align: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.Ambigrams .Comments .comment img {
  max-width: 100%;
  max-height: 150px;
  cursor: move;
}

.Ambigrams .project .title {
  font-size: x-large;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  gap: 1rem;
}

.Ambigrams .project .title .splitTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.Ambigrams .project .miniThumbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
  padding-bottom: 0.5rem;
}

.Ambigrams .project .miniThumbs div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Ambigrams .project .miniThumbs img {
  margin: auto;
  width: 100px;
}

.Ambigrams .fullScreenLoading {
  z-index: 1000000;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.75);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: xx-large;
  font-style: italic;
  left: 0px;
  top: 0px;
}

.Ambigrams .Videos {
  min-height: 197px;
  max-height: calc(100vh - 12rem);
  margin-bottom: 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 1rem;
}

.Ambigrams .Videos.noVideos {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Ambigrams .Videos.hover {
  border: 1px solid red;
}

.Ambigrams .Videos .loadingVideos {
  background-color: rgba(255, 255, 255, 0.75);
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Ambigrams .Videos .SetThumbnail .setThumbnail .thumbPreview {
  min-height: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 2rem;
}

.Ambigrams .Videos .SetThumbnail .setThumbnail .thumbPreview img {
  max-height: 400px;
}

.Ambigrams .Videos .SetThumbnail .setThumbnail .thumbPreview img.seeking {
  opacity: 0.25;
}

.Ambigrams .Videos .SetThumbnail .Seeker {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 600px;
  position: relative;
  height: 24px;
  cursor: pointer;
}

.Ambigrams .Videos .SetThumbnail .Seeker::after {
  position: absolute;
  top: 11px;
  border-bottom: 2px solid black;
  width: 600px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  content: " ";
}

.Ambigrams .Videos .SetThumbnail .Seeker .bobbin {
  position: absolute;
  width: 8px;
  height: 24px;
  background-color: black;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}

.Ambigrams .Videos .UploadVideo .uploadVideo .UploadForm .texts {
  padding: 0.5rem;
  border: 1px solid black;
  cursor: pointer;
}

.Ambigrams .Videos .UploadVideo .uploadVideo .UploadForm .clipped {
  background-color: black;
  color: white;
}

.Ambigrams .Videos .UploadVideo .uploadVideo .YouTubeOrTikTok {
  height: 9rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.Ambigrams .Videos .UploadVideo .uploadVideo .YouTubeOrTikTok .platformBtn {
  width: 8rem;
  height: 4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid black;
  text-align: center;
}

.Ambigrams .Videos .UploadVideo .uploadVideo .YouTubeOrTikTok .platformBtn:hover {
  background-color: black;
  color: white;
}

.Ambigrams .Videos .UploadVideo .uploadVideo .YouTubeOrTikTok .queryBtn {
  width: 10rem;
  height: 4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}

.Ambigrams .Videos .videoThumb {
  cursor: pointer;
  position: relative;
}

.Ambigrams .Videos .videoThumb .innerThumb {
  position: absolute;
  width: 100%;
}

.Ambigrams .Videos .videoThumb .innerThumb .thumbButtons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: absolute;
  bottom: 0.5rem;
  gap: 0.3rem;
}

.Ambigrams .Videos .videoThumb .innerThumb .thumbButtons .A, .Ambigrams .Videos .videoThumb .innerThumb .thumbButtons .AreYouSure {
  background-color: white;
}

.Ambigrams .Videos .videoThumb .innerThumb .thumbUploaded {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: absolute;
  top: 0.5rem;
  gap: 0.3rem;
}

.Ambigrams .Videos .videoThumb .innerThumb .thumbUploaded .A, .Ambigrams .Videos .videoThumb .innerThumb .thumbUploaded .a {
  background-color: white;
}

.Ambigrams .Videos .videoThumb .innerThumb .thumbUploaded .a {
  cursor: default;
}

.Ambigrams .Videos .videoThumb img {
  width: 110px;
}

.Ambigrams .FakeWindow .ForgeComment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 150px;
}

.Ambigrams .FakeWindow .ForgeComment canvas {
  border: 1px solid black;
}

.Ambigrams .FakeWindow .ForgeComment img {
  max-width: 598px;
  -webkit-transition: max-width 0.3s linear;
  transition: max-width 0.3s linear;
  border: 1px solid black;
}

.Ambigrams .FakeWindow .ForgeComment img.shrinky {
  max-width: 200px;
}

.Ambigrams .FakeWindow .fakePreview {
  width: 1200px;
  height: 500px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Ambigrams .FakeWindow .FAKE {
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  color: black;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 270px;
  gap: 12px;
}

.Ambigrams .FakeWindow .FAKE input, .Ambigrams .FakeWindow .FAKE textarea {
  font-family: inherit;
  color: inherit;
  margin: 0px;
  padding: 0px;
  font-size: inherit;
  width: 100%;
  line-height: inherit;
}

.Ambigrams .FakeWindow .FAKE textarea {
  resize: none;
  overflow: hidden;
  min-height: 1rem;
}

.Ambigrams .FakeWindow .FAKE .sneaky {
  height: 0px;
  overflow: hidden;
}

.Ambigrams .FakeWindow .FAKE .textarea, .Ambigrams .FakeWindow .FAKE textarea {
  word-break: break-word;
}

.Ambigrams .FakeWindow .FAKE .pfp {
  width: 40px;
}

.Ambigrams .FakeWindow .FAKE .pfp img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
}

.Ambigrams .FakeWindow .FAKE .pfp .nopfp {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: lightgray;
}

.Ambigrams .FakeWindow .FAKE .fakeBody .fakeUsername {
  color: #161823;
  font-family: 'sofia pro';
  font-size: 18px;
  margin-bottom: 2px;
}

.Ambigrams .FakeWindow .FAKE .fakeBody .fakeComment {
  font-family: 'proxima nova';
  font-size: 16px;
  line-height: 22px;
}

.Ambigrams .FakeWindow .FAKE .fakeBody .fakeFooter {
  font-family: 'proxima nova';
  font-size: 14px;
  line-height: 17px;
  color: rgba(22, 24, 35, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
}

.Ambigrams .FakeWindow .FAKE .fakeBody .fakeFooter .fakeHeart {
  margin-left: 4px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.Ambigrams .FakeWindow .FAKE .fakeBody .fakeFooter .fakeHeart svg {
  fill: #161823;
  width: 20px;
  height: 20px;
  overflow: hidden;
  cursor: pointer;
}

.Ambigrams .FakeWindow .FAKE .fakeBody .fakeFooter .fakeHeart svg path {
  fill-rule: evenodd;
  clip-rule: evenodd;
  width: 20px;
  height: 20px;
}

.Ambigrams .FakeWindow .FAKE .fakeBody .fakeFooter .fakeHeart div {
  color: #161823;
  margin-left: 4px;
  margin-top: 2px;
}

.Ambigrams .FakeWindow .FAKE .fakeBody .fakeFooter .fakeTime {
  margin-top: 2px;
}

.Ambigrams .FakeWindow .FAKE .fakeBody .fakeFooter .fakeReply {
  margin-top: 1px;
  color: #161823;
  font-weight: 600;
  cursor: pointer;
}

.Ambigrams .FakeWindow .prof {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Ambigrams .ambigrams {
  max-height: calc(100vh - 12rem);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 2rem;
}

.Ambigrams .ambigrams .number {
  font-size: x-large;
  margin-bottom: 1rem;
}

.Ambigrams .ambigrams .ambigram .word0 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Ambigrams .ambigrams .ambigram .word0 div {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  white-space: nowrap;
  font-style: italic;
}

.Ambigrams .ambigrams .ambigram .itemSplitSplit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.5rem;
}

.Ambigrams .ambigrams .ambigram .itemSplitSplit .rightright {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.Ambigrams .ambigrams .ambigram .commentRight {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  gap: 1rem;
}

.Ambigrams .ambigrams .ambigram .commentRight .pasteButton {
  -webkit-box-flex: 3;
      -ms-flex: 3;
          flex: 3;
}

.Ambigrams .ambigrams .ambigram .commentRight .fakeButton {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.Ambigrams .ambigrams .ambigram .removeAmbigram {
  margin-top: 1rem;
}

.Ambigrams .addAmbigramBut {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.Ambigrams .addButtons {
  margin-bottom: 1rem;
  font-size: large;
}

.Ambigrams .projectsList .project {
  cursor: pointer;
  margin-bottom: 1rem;
}

.Ambigrams .expandedProject .loadingWindow {
  opacity: 0.25;
  pointer-events: none;
}

.Ambigrams .expandedProject .ambigram .preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.Ambigrams .expandedProject .ambigram .preview img {
  max-width: 200px;
  border: 1px solid black;
  padding: 2px;
}

.Ambigrams .expandedProject .shrinkable.shrink {
  visibility: hidden;
  height: 0px;
}

.Ambigrams .expandedProject .shrinkable .unshrinkable {
  visibility: visible !important;
}

.Ambigrams .pasteButton, .Ambigrams .fakeButton {
  border: 1px solid black;
  padding: 0.25rem;
  display: solid;
  text-align: center;
  cursor: pointer;
}

.Ambigrams .pasteButton:hover, .Ambigrams .fakeButton:hover {
  background-color: black;
  color: white;
}

.Ambigrams .AmbigramPaster .img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Ambigrams .AmbigramPaster .img img {
  max-width: 590px;
  border: 1px solid black;
  padding: 4px;
  margin-bottom: 1rem;
  -webkit-transition: max-width 0.3s linear;
  transition: max-width 0.3s linear;
}

.Ambigrams .AmbigramPaster .img img.shrinky {
  max-width: 200px;
}

.Ambigrams .spinpic {
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  cursor: pointer;
}

.Ambigrams .CommentPaster .img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Ambigrams .CommentPaster .img img, .Ambigrams .CommentPaster .img canvas {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  max-width: 590px;
  border: 1px solid black;
  padding: 4px;
  margin-bottom: 1rem;
  -webkit-transition: max-width 0.3s linear;
  transition: max-width 0.3s linear;
}

.Ambigrams .CommentPaster .img img.shrinky, .Ambigrams .CommentPaster .img canvas.shrinky {
  max-width: 200px;
}

.Ambigrams .CommentPaster .screenshot {
  border: 1px solid black;
  padding: 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-bottom: 2px;
  margin-bottom: 1rem;
  position: relative;
}

.Ambigrams .CommentPaster .screenshot img {
  max-width: 590px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Ambigrams .CommentPaster .screenshot .dragger {
  position: absolute;
  left: 4px;
  top: 4px;
  overflow: hidden;
  cursor: pointer;
}

.Ambigrams .CommentPaster .screenshot .dragger .rect {
  pointer-events: none;
  opacity: 0.8;
}

.Ambigrams .CommentPaster .screenshot .dragger .rect div {
  position: absolute;
  pointer-events: none;
  background-color: white;
  width: 10000px;
  height: 10000px;
}

.Ambigrams .CommentPaster .screenshot .dragger .border {
  position: absolute;
  border: 1px solid black;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.Ambigrams .CommentPaster .P5Paint .canvasContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Ambigrams .CommentPaster .P5Paint .canvasContainer canvas {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  max-width: 590px;
  border: 1px solid black;
  padding: 4px;
  cursor: none;
  margin-bottom: 1rem;
}

.Ambigrams .CommentPaster .P5Paint .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.Ambigrams .CommentPaster .P5Paint .brushes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 0.5rem;
}

.Ambigrams .CommentPaster .P5Paint .brushes .brush {
  width: 50px;
  height: 40px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}

.Ambigrams .CommentPaster .P5Paint .brushes .brush div {
  border: 1px solid black;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: white;
}

.Ambigrams .CommentPaster .P5Paint .brushes .brush div.selected {
  background-color: black;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes growin {
  from {
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes growin {
  from {
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes growin1 {
  from {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes growin1 {
  from {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.FileServer {
  font-size: larger;
  margin-left: 2rem;
  margin-top: 2rem;
}

.FileServer h1, .FileServer h2 {
  margin: 0px;
}

.FileServer .visitors {
  margin-top: 1rem;
}

.FileServer .visitor {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 500px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.GraphicArt {
  color: yellow;
  font-size: larger;
  font-family: 'Times New Roman', Times, serif;
}

.GraphicArt h1, .GraphicArt h2, .GraphicArt h3, .GraphicArt h4, .GraphicArt h5 {
  text-decoration: underline;
}

.GraphicArt a {
  color: yellow;
}

.GraphicArt a:visited {
  color: yellow;
}

.GraphicArt ::-webkit-scrollbar {
  width: 16px;
}

.GraphicArt ::-webkit-scrollbar-thumb {
  background: #60efff;
  background: -webkit-gradient(linear, left bottom, left top, from(#245ba7), to(#60efff));
  background: linear-gradient(0deg, #245ba7 0%, #60efff 100%);
  border-radius: 8px;
  border: 4px solid #0b0d11;
}

.GraphicArt .flexwrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 1rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.GraphicArt .PageCounter {
  z-index: 101;
  position: fixed;
  right: 2rem;
  top: 2rem;
  -webkit-animation: fadein 2s linear;
          animation: fadein 2s linear;
}

.GraphicArt .PageCounter .views {
  margin-left: 5px;
}

.GraphicArt .Fader {
  -webkit-transition: opacity 0.8s linear;
  transition: opacity 0.8s linear;
  opacity: 1;
  -webkit-animation: fadein 0.8s linear;
          animation: fadein 0.8s linear;
}

.GraphicArt .Fader.invisible {
  opacity: 0;
  pointer-events: none;
}

.GraphicArt .lilWally {
  height: 0px;
  position: relative;
  pointer-events: none;
  overflow: hidden;
  margin-top: -37px;
  padding-top: 37px;
  top: 1rem;
  -webkit-transition: margin-top 0.25s linear, padding-top 0.25s linear;
  transition: margin-top 0.25s linear, padding-top 0.25s linear;
}

.GraphicArt .lilWally.hover {
  margin-top: -60px;
  padding-top: 60px;
}

.GraphicArt .lilWally.hide {
  margin-top: 0px;
  padding-top: 0px;
}

.GraphicArt .lilWally.hide img {
  pointer-events: none;
}

.GraphicArt .lilWally img {
  pointer-events: all;
  position: absolute;
  top: 0px;
  cursor: pointer;
  right: 0.5rem;
}

@-webkit-keyframes flash {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes flash {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.GraphicArt .FLASH {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  background-color: white;
  -webkit-animation: flash 1s ease-out;
          animation: flash 1s ease-out;
  opacity: 0;
  pointer-events: none;
}

.GraphicArt .TimeMachine {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.GraphicArt .TimeMachine .invisible {
  opacity: 0 !important;
  pointer-events: none;
}

.GraphicArt .TimeMachine .cheese {
  cursor: pointer;
  width: 50px;
  -webkit-transition: opacity 0.25s linear;
  transition: opacity 0.25s linear;
}

.GraphicArt .TimeMachine .cheese.invisible {
  opacity: 0;
  pointer-events: none;
}

.GraphicArt .TimeMachine .timeMachine0 {
  opacity: 1;
  -webkit-transition: opacity 1s linear;
  transition: opacity 1s linear;
  position: absolute;
}

.GraphicArt .TimeMachine .timeMachine0 .timeMachine1 {
  position: relative;
}

.GraphicArt .TimeMachine .timeMachine0 .timeMachine1 .tm {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
}

.GraphicArt .TimeMachine .timeMachine0 .timeMachine1 .tm.on {
  z-index: 2;
  -webkit-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}

.GraphicArt .TimeMachine .timeMachine0 .timeMachine1 .scr {
  overflow: hidden;
  opacity: 0.8;
  z-index: 3;
  position: absolute;
  left: 168px;
  top: 303px;
  width: 82px;
  height: 83px;
  color: white;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  text-shadow: 0px 0px 2px white, 0px 0px 4px cyan, 0px 0px 8px blue;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.GraphicArt .TimeMachine .timeMachine0 .timeMachine1 .scr.block {
  pointer-events: none;
}

.GraphicArt .TimeMachine .timeMachine0 .timeMachine1 .scr .pass {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 4px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 8px;
}

.GraphicArt .TimeMachine .timeMachine0 .timeMachine1 .scr .pass .p {
  width: 14px;
  height: 14px;
  border-bottom: 1px solid white;
  text-align: center;
  padding-bottom: 2px;
}

.GraphicArt .TimeMachine .timeMachine0 .timeMachine1 .scr .enterPassword {
  font-size: 14px;
}

.GraphicArt .TimeMachine .timeMachine0 .timeMachine1 .scr .numPad {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 2px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}

.GraphicArt .TimeMachine .timeMachine0 .timeMachine1 .scr .numPad .btn {
  width: 14px;
  height: 12px;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-shadow: 0px 0px 3px #62abff;
          box-shadow: 0px 0px 3px #62abff;
  color: black;
  padding-top: 2px;
}

.GraphicArt .portfolioItem h2, .GraphicArt .portfolioItem p, .GraphicArt .portfolioItem h4 {
  margin: 0px;
  margin-bottom: 1rem;
}

.GraphicArt .portfolioItem p {
  font-size: large;
}

.GraphicArt .portfolioItem .Video {
  margin-bottom: 1rem;
  cursor: pointer;
  position: relative;
}

.GraphicArt .portfolioItem .Video .playButton {
  position: absolute;
  left: calc(50% - 27px);
  top: calc(50% - 28px);
  -webkit-filter: drop-shadow(0px 0px 16px black) drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
          filter: drop-shadow(0px 0px 16px black) drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
}

.GraphicArt .portfolioItem .Video.noMarginBottom {
  margin-bottom: 0px;
}

.GraphicArt .portfolioItem .Video img {
  max-width: 100%;
}

.GraphicArt .portfolioItem .centre {
  margin-bottom: 1rem;
}

.GraphicArt .portfolioItem .noMarginBottom {
  margin-bottom: 0px !important;
}

.GraphicArt .thumbnails {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 1rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.GraphicArt .thumbnails div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.GraphicArt .thumbnails div img {
  width: 100%;
  cursor: pointer;
}

.GraphicArt .centre {
  text-align: center;
}

.GraphicArt .centre.thumbnail img {
  cursor: pointer;
}

.GraphicArt .centre img {
  max-width: 100%;
}

.GraphicArt .Portfolio {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 0.5rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.GraphicArt .Portfolio h4 {
  margin: 0px;
}

.GraphicArt .Portfolio .ontop {
  z-index: 300;
  text-shadow: 0px 0px 5px black, 0px 0px 5px black, 0px 0px 5px black;
}

.GraphicArt .Portfolio .tooltip {
  z-index: 301;
  background-color: black;
  border: 1px solid yellow;
  position: absolute;
  pointer-events: none;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  font-style: italic;
  font-size: small;
}

.GraphicArt .Portfolio .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

@media screen and (max-width: 480px) {
  .GraphicArt .Portfolio .buttons {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 0px;
  }
}

.GraphicArt .Portfolio .byebye {
  position: fixed;
  left: 0px;
  top: 0px;
}

.GraphicArt .Portfolio .portImg {
  position: absolute;
  -webkit-transition: opacity 0.8s linear, left 0.5s linear, top 0.5s linear;
  transition: opacity 0.8s linear, left 0.5s linear, top 0.5s linear;
  opacity: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 0.25rem;
  cursor: pointer;
}

.GraphicArt .Portfolio .portImg .viewed {
  font-size: medium;
  text-align: center;
}

.GraphicArt .Portfolio .portImg .viewed .title {
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.GraphicArt .Portfolio .portImg .viewed .title.invisible {
  opacity: 0;
}

.GraphicArt .Portfolio .portImg .viewed .v {
  font-style: italic;
  font-size: small;
  color: #60efff;
}

.GraphicArt .Portfolio .portImg .abs {
  -webkit-transition: opacity 0.8s linear;
  transition: opacity 0.8s linear;
}

.GraphicArt .Portfolio .portImg .abs.unloaded {
  opacity: 0;
}

.GraphicArt .Cauldron {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 3rem;
}

.GraphicArt .Cauldron .caption {
  text-shadow: 0px 0px 5px black, 0px 0px 5px black, 0px 0px 5px black;
  text-align: center;
  height: 0px;
  margin-top: 1rem;
}

.GraphicArt .Cauldron .animate {
  -webkit-transition: top 1s linear;
  transition: top 1s linear;
}

.GraphicArt .Cauldron .invisible {
  opacity: 0;
}

.GraphicArt .Cauldron .cauldron {
  position: relative;
  -webkit-transition: opacity 0.25s linear, -webkit-transform 0.5s linear;
  transition: opacity 0.25s linear, -webkit-transform 0.5s linear;
  transition: transform 0.5s linear, opacity 0.25s linear;
  transition: transform 0.5s linear, opacity 0.25s linear, -webkit-transform 0.5s linear;
}

.GraphicArt .Cauldron .cauldron.invisible {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}

.GraphicArt .Cauldron .cauldron img {
  position: absolute;
  top: 0px;
  left: 0px;
}

.GraphicArt .Door {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 56px;
  gap: 0.5rem;
  position: relative;
  margin-bottom: -1rem;
  overflow: hidden;
}

.GraphicArt .Door .pleaseKnock {
  margin-top: 12px;
}

.GraphicArt .Door .invisible {
  opacity: 0;
  pointer-events: none;
}

.GraphicArt .Door .mouse {
  position: absolute;
  top: 8px;
  right: 16px;
  width: 0px;
  overflow: hidden;
  -webkit-transition: width 0.5s linear, top 0.5s linear;
  transition: width 0.5s linear, top 0.5s linear;
}

.GraphicArt .Door .mouse.nosey {
  width: 66px;
  top: 0px;
}

.GraphicArt .Door .door {
  margin-left: 2rem;
  cursor: pointer;
  position: relative;
  width: 46px;
  height: 56px;
}

.GraphicArt .Door .door .d {
  position: absolute;
  left: 0px;
  top: 0px;
}

.GraphicArt .Dance .invisible {
  opacity: 0;
  pointer-events: none;
}

.GraphicArt .Dance .Game {
  -webkit-transition: opacity 0.25s linear;
  transition: opacity 0.25s linear;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.GraphicArt .Dance .Game button {
  background-color: black;
  border: 1px solid yellow;
  font-family: inherit;
  color: yellow;
  font-size: inherit;
  cursor: pointer;
}

.GraphicArt .Dance .Game button:hover {
  background-color: yellow;
  color: black;
}

.GraphicArt .Dance .Game .monkey {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.GraphicArt .Dance .Game .monkey img {
  position: absolute;
  left: 0px;
  top: 0px;
  -webkit-animation: growin1 0.25s linear;
          animation: growin1 0.25s linear;
}

@-webkit-keyframes gaga {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  33% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  66% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    opacity: 0;
  }
}

@keyframes gaga {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  33% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  66% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    opacity: 0;
  }
}

.GraphicArt .Dance .Game .monkey .message {
  font-size: xxx-large;
  z-index: 10;
  opacity: 0;
  -webkit-animation: gaga 0.5s linear;
          animation: gaga 0.5s linear;
  text-shadow: 0px 0px 15px black, 0px 0px 10px black, 0px 0px 5px black;
}

@media screen and (max-width: 300px) {
  .GraphicArt .Dance .Game .monkey .message {
    font-size: xx-large;
  }
}

.GraphicArt .Dance .Game .monkey .instructions {
  z-index: 10;
  text-align: center;
}

.GraphicArt .Dance .Game .monkey .instructions p {
  margin: 0px;
  font-size: larger;
  margin-bottom: 0.5rem;
  text-shadow: 0px 0px 5px black, 0px 0px 5px black, 0px 0px 5px black;
}

.GraphicArt .Dance .Game .arrowButtons {
  position: relative;
}

.GraphicArt .Dance .Game .arrowButtons svg {
  width: 100%;
}

.GraphicArt .Dance .Game .arrowButtons .movingArrow {
  position: absolute;
}

.GraphicArt .Dance .Game .arrowButtons .movingArrow svg {
  fill: skyblue;
}

.GraphicArt .Dance .Game .arrowButtons .movingArrow.done svg {
  fill: red;
}

.GraphicArt .Dance .Game .arrowButtons .leftArrow {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.GraphicArt .Dance .Game .arrowButtons .rightArrow {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.GraphicArt .Dance .Game .arrowButtons .inputButtons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.GraphicArt .Dance .Game .arrowButtons .inputButtons div {
  position: absolute;
}

.GraphicArt .Dance .Game .arrowButtons .inputButtons svg {
  stroke: yellow;
  stroke-width: 3px;
  fill: none;
}

.GraphicArt .Dance .Game .arrowButtons .inputButtons .highlight {
  -webkit-filter: drop-shadow(0px 0px 5px white);
          filter: drop-shadow(0px 0px 5px white);
}

.GraphicArt .Dance .Game .arrowButtons .inputButtons .highlight svg {
  stroke: white;
}

.GraphicArt .Clock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
}

.GraphicArt .Clock.hitlered .clock {
  cursor: pointer;
}

.GraphicArt .Clock.invisible {
  opacity: 0;
  pointer-events: none;
}

.GraphicArt .Clock .clock {
  position: relative;
}

.GraphicArt .Clock .clock .mid {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.GraphicArt .Clock .clock img {
  position: absolute;
}

.GraphicArt .Clock .clock .hand {
  position: absolute;
}

.GraphicArt .FullScreen {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 100;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(0, 0, 0, 0.66);
  -webkit-animation: fadein 0.25s linear;
          animation: fadein 0.25s linear;
  -webkit-transition: opacity 0.25s linear;
  transition: opacity 0.25s linear;
}

.GraphicArt .FullScreen .starry {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: black;
  background-image: url("./pics/stars.gif");
  -webkit-animation-name: offsetBG;
          animation-name: offsetBG;
  -webkit-animation-duration: 30s;
          animation-duration: 30s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.GraphicArt .FullScreen.invisible {
  opacity: 0;
}

.GraphicArt .FullScreen .Dialog {
  margin: auto;
  background-color: #0b0d11;
  padding: 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: calc(100% - 2rem);
  max-width: 600px;
  border: 2px solid skyblue;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
  font-family: 'Courier New', Courier, monospace;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (max-width: 555px) {
  .GraphicArt .FullScreen .Dialog {
    font-size: medium;
  }
}

@media screen and (max-width: 475px) {
  .GraphicArt .FullScreen .Dialog {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.GraphicArt .FullScreen .Dialog .wallyProfilePicture {
  background-color: skyblue;
  border-radius: 4px;
  overflow: hidden;
}

.GraphicArt .FullScreen .Dialog .wallyProfilePicture .wallyContainer {
  position: relative;
  top: 0px;
  -webkit-transition: top 0.5s linear;
  transition: top 0.5s linear;
}

.GraphicArt .FullScreen .Dialog .wallyProfilePicture .wallyContainer.shootable {
  cursor: pointer;
}

.GraphicArt .FullScreen .Dialog .wallyProfilePicture .wallyContainer.invisible {
  top: 213px;
  opacity: 0;
}

.GraphicArt .FullScreen .Dialog .wallyProfilePicture .wallyContainer .flash {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: white;
  -webkit-animation: flash 1s ease-out;
          animation: flash 1s ease-out;
  opacity: 0;
  pointer-events: none;
}

.GraphicArt .FullScreen .Dialog .wallyProfilePicture .wallyContainer img {
  position: absolute;
  left: 0px;
  top: 0px;
}

.GraphicArt .FullScreen .Dialog .wallyProfilePicture .wallyContainer img.moustache {
  -webkit-transition: -webkit-transform 0.1s linear;
  transition: -webkit-transform 0.1s linear;
  transition: transform 0.1s linear;
  transition: transform 0.1s linear, -webkit-transform 0.1s linear;
  cursor: pointer;
}

.GraphicArt .FullScreen .Dialog .wallyProfilePicture .wallyContainer img.moustache.animating {
  -webkit-transition: top 0.5s linear, -webkit-transform 0.1s linear;
  transition: top 0.5s linear, -webkit-transform 0.1s linear;
  transition: transform 0.1s linear, top 0.5s linear;
  transition: transform 0.1s linear, top 0.5s linear, -webkit-transform 0.1s linear;
}

.GraphicArt .FullScreen .Dialog .wallyProfilePicture .wallyContainer img.hitler {
  opacity: 1;
}

.GraphicArt .FullScreen .Dialog .wallyProfilePicture .wallyContainer img.hitler.invisible {
  opacity: 0;
  pointer-events: none;
}

.GraphicArt .FullScreen .Dialog .dialogBody {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.5rem;
}

.GraphicArt .FullScreen .Dialog .dialogBody .text {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.GraphicArt .FullScreen .Dialog .dialogBody .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
}

.GraphicArt .FullScreen .Dialog .dialogBody .buttons button {
  background: none;
  border: 1px solid yellow;
  color: yellow;
  font-family: inherit;
  cursor: pointer;
  font-weight: bold;
  font-size: inherit;
}

.GraphicArt .FullScreen .Dialog .dialogBody .buttons button:hover {
  background-color: yellow;
  color: black;
}

.GraphicArt .FullScreen .videoScreen, .GraphicArt .FullScreen .imageScreen {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.GraphicArt .FullScreen .videoScreen .caption, .GraphicArt .FullScreen .imageScreen .caption {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.GraphicArt .FullScreen .videoScreen .caption .caption, .GraphicArt .FullScreen .imageScreen .caption .caption {
  border-radius: 4px;
  gap: 1rem;
  font-style: italic;
  text-shadow: 0px 0px 5px black, 0px 0px 5px black, 0px 0px 5px black;
}

.GraphicArt .FullScreen .videoScreen .caption .caption button, .GraphicArt .FullScreen .imageScreen .caption .caption button {
  height: 2rem;
  width: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.GraphicArt .FullScreen .videoScreen .caption .caption button svg, .GraphicArt .FullScreen .imageScreen .caption .caption button svg {
  width: 1rem;
}

.GraphicArt .FullScreen .videoScreen .img img, .GraphicArt .FullScreen .imageScreen .img img {
  width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-animation: growin1 0.2s linear;
          animation: growin1 0.2s linear;
}

.GraphicArt .FullScreen .videoScreen button, .GraphicArt .FullScreen .imageScreen button {
  padding: 0px;
  margin: 0px;
  background: none;
  border: none;
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
}

.GraphicArt .FullScreen .videoScreen button:hover svg, .GraphicArt .FullScreen .imageScreen button:hover svg {
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
}

.GraphicArt .FullScreen .videoScreen button:disabled, .GraphicArt .FullScreen .imageScreen button:disabled {
  cursor: default;
  opacity: 0.5;
}

.GraphicArt .FullScreen .videoScreen button:disabled:hover svg, .GraphicArt .FullScreen .imageScreen button:disabled:hover svg {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.GraphicArt .FullScreen .videoScreen button svg, .GraphicArt .FullScreen .imageScreen button svg {
  fill: #60efff;
  width: 1.5rem;
  -webkit-transition: -webkit-transform 0.1s ease;
  transition: -webkit-transform 0.1s ease;
  transition: transform 0.1s ease;
  transition: transform 0.1s ease, -webkit-transform 0.1s ease;
  pointer-events: none;
}

.GraphicArt .FullScreen .videoScreen .X, .GraphicArt .FullScreen .imageScreen .X {
  position: fixed;
  right: 1rem;
  top: 1rem;
  z-index: 999999999999999;
}

.GraphicArt .FullScreen .videoScreen .video, .GraphicArt .FullScreen .imageScreen .video {
  border: 2px solid #60efff;
  border-radius: 4px;
  background-color: #0b0d11;
}

.GraphicArt .FullScreen .modal {
  -webkit-animation: growin 0.25s linear;
          animation: growin 0.25s linear;
  -webkit-transition: -webkit-transform 0.5s linear;
  transition: -webkit-transform 0.5s linear;
  transition: transform 0.5s linear;
  transition: transform 0.5s linear, -webkit-transform 0.5s linear;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: calc(100% - 1rem);
  background-color: skyblue;
  margin: auto;
  border: 2px solid skyblue;
  border-radius: 4px;
}

.GraphicArt .FullScreen .modal.contact .modalBody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.GraphicArt .FullScreen .modal.contact .modalBody p {
  margin-top: 0px;
  margin-bottom: 0.5rem;
}

.GraphicArt .FullScreen .modal .modalHeading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #54d0ed;
  background-image: url("https://kaisboatfund.co.uk/api/pictures/heading.gif");
  background-repeat: no-repeat;
  color: black;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 2rem;
  padding-left: 0.5rem;
  padding-right: 5px;
  color: white;
  font-style: italic;
}

.GraphicArt .FullScreen .modal .modalHeading .X {
  cursor: pointer;
}

.GraphicArt .FullScreen .modal .modalHeading .X img {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.GraphicArt .FullScreen .modal .modalBody {
  padding: 1rem;
  min-height: 100px;
  max-height: calc(100vh - 8rem);
  overflow-y: auto;
  background-color: #0b0d11;
}

.GraphicArt .FullScreen .modal .modalBody p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.GraphicArt .FullScreen .modal.small {
  -webkit-transform: scale(0);
          transform: scale(0);
}

.GraphicArt .unloaded {
  opacity: 0 !important;
}

.GraphicArt .Heading {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: opacity 0.8s ease;
  transition: opacity 0.8s ease;
}

.GraphicArt .Heading.invisible {
  opacity: 0;
  pointer-events: none;
}

.GraphicArt .Heading.pointer {
  cursor: pointer;
}

.GraphicArt .Heading .AnimatedHeading {
  margin: auto;
  width: 100%;
  position: relative;
}

.GraphicArt .Heading .AnimatedHeading .abs {
  position: absolute;
  -webkit-transition: opacity 0.8s linear;
  transition: opacity 0.8s linear;
  opacity: 1;
}

.GraphicArt .Heading .AnimatedHeading .link {
  text-shadow: 0px 0px 5px black, 0px 0px 5px black, 0px 0px 5px black;
  word-wrap: unset;
}

.GraphicArt .Heading .AnimatedHeading .link a {
  color: yellow;
}

.GraphicArt .Heading .AnimatedHeading .link a:visited {
  color: yellow;
}

.GraphicArt .Heading .AnimatedHeading .flip {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.Calligraphy {
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Calligraphy .Calligrabg {
  width: 100%;
  height: 100vh;
  opacity: 0;
  -webkit-filter: blur(30px);
          filter: blur(30px);
  position: absolute;
  -webkit-transition: opacity 5s cubic-bezier(0.2, 0.5, 0.5, 1), -webkit-filter 5s cubic-bezier(0.2, 0.5, 0.5, 1);
  transition: opacity 5s cubic-bezier(0.2, 0.5, 0.5, 1), -webkit-filter 5s cubic-bezier(0.2, 0.5, 0.5, 1);
  transition: opacity 5s cubic-bezier(0.2, 0.5, 0.5, 1), filter 5s cubic-bezier(0.2, 0.5, 0.5, 1);
  transition: opacity 5s cubic-bezier(0.2, 0.5, 0.5, 1), filter 5s cubic-bezier(0.2, 0.5, 0.5, 1), -webkit-filter 5s cubic-bezier(0.2, 0.5, 0.5, 1);
}

.Calligraphy .Calligrabg.showBg {
  -webkit-filter: blur(0px);
          filter: blur(0px);
  opacity: 1;
  background-image: url("https://kaisboatfund.co.uk/api/pictures/calligrabg.jpg");
}

.Calligraphy .drop0 {
  border-radius: 5px;
  overflow: hidden;
  z-index: 98;
  margin: auto;
  -webkit-filter: drop-shadow(0px 0px 50px black);
          filter: drop-shadow(0px 0px 50px black);
}

.Calligraphy .drop1 {
  border-radius: 5px;
  overflow: hidden;
  z-index: 99;
  -webkit-filter: drop-shadow(0px 0px 25px black);
          filter: drop-shadow(0px 0px 25px black);
}

.Calligraphy .Video {
  border-radius: 5px;
  overflow: hidden;
  z-index: 100;
  background-color: black;
  -webkit-animation-name: blurin;
          animation-name: blurin;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-timing-function: cubic-bezier(0.22, 0.48, 0.37, 1);
          animation-timing-function: cubic-bezier(0.22, 0.48, 0.37, 1);
}

@-webkit-keyframes blurin {
  from {
    opacity: 0;
    -webkit-filter: blur(20px) sepia(1);
            filter: blur(20px) sepia(1);
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  to {
    opacity: 1;
    -webkit-filter: blur(0px) sepia(0);
            filter: blur(0px) sepia(0);
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes blurin {
  from {
    opacity: 0;
    -webkit-filter: blur(20px) sepia(1);
            filter: blur(20px) sepia(1);
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  to {
    opacity: 1;
    -webkit-filter: blur(0px) sepia(0);
            filter: blur(0px) sepia(0);
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.HIDDEN {
  display: none;
}
