$c0: rgb(244, 179, 33);
$c1: rgb(41, 239, 173);
$c2: rgb(234, 61, 128);

// $afg: blue;
// $ared: red;
// $abg: yellow;
// $abgFaded: rgba(255, 255, 0, 0.75);

$afg: black;
// $afg: rebeccapurple;
$ared: rgb(255, 0, 0);
$abg: rgb(255, 255, 255);
$abgFaded: rgba(255, 255, 255, 0.75);

$bg: $c0;
$border: rgb(0, 0, 0);
$fg: rgb(0, 0, 0);
$fgFaded: rgb(131, 131, 131);

$link: rgb(81, 26, 235);
$green: $c1; //rgb(0, 255, 106);
$red: rgb(240, 26, 80);




$portfolioBlack: rgb(11, 13, 17);
$portfolioBlue: rgb(96,239,255);


@mixin responsive($wid) {
  @media only screen and (max-width: $wid) {
    @content;
  }
}

@font-face {
  font-family: 'sofia pro';
  font-style: bold;
  src: url('./fonts/sofiapro-bold.woff2');
}
@font-face {
  font-family: 'proxima nova';
  font-style: normal;
  src: url('./fonts/Proxima-Nova-Regular.woff2');
}
@font-face {
  font-family: 'proxima nova';
  font-weight: 600;
  src: url('./fonts/Proxima-Nova-Semibold.woff2');
}

body {
  margin: 0px;
  box-sizing: border-box;
  // background-color: $c2;
  color: $fg;
  // overflow-y: scroll;
  background-image: none;
}

@keyframes offsetBG {
  from {
    background-position-y: 0;
  }
  to { 
    background-position-y: -234px;
  }
}

.body {
  background-color: $c2;
  &.backgroundColourAdmin {
    background-color: $abg;
  }
  &.backgroundColourWhite{
    background-color: white;
  }
  &.backgroundColourOrange{
    background-color: #fbb03b;
    margin-top: -1.5rem;
    padding-top:1.5rem;
  }
  &.backgroundColourBlack{
    background-color: black;
  }
  &.backgroundColourStars{
    background-color: black;
    background-image: url('./pics/stars.gif');

    animation-name: offsetBG;
    animation-duration: 30s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  width:100%;
  height:100vh;
  position:fixed;
  z-index: -20;
}

.flex {
  display: flex;
}

.Store {
  color: $fg;
  font-family: "Raleway", sans-serif;
  font-size: large;

  .box {
    background-color: white;
    border: 5px solid $border;
    padding: 1rem;
    h1 {
      margin-top: 0px;
    }
  }

  .modal {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 99999999999;
    color: $fg;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    .transparency {
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100vh;
      background-color: black;
      opacity: 0.5;
      z-index: -1;
    }
    .box {
      &.buy {
        min-width: 500px;
        max-width: 600px;

        @include responsive(555px) {
          min-width: auto;
          width: calc(100% - 2rem);
          max-width: calc(100% - 2rem);
        }
      }

      &.small {
        max-width: calc(500px);
        @include responsive(555px) {
          width: calc(100% - 2rem);
        }
      }

      position: relative;
      box-sizing: border-box;
      max-height: calc(100vh - 20px);
      overflow-y: auto;
      overflow-x: hidden;

      h1 {
        padding-right: 1.6rem;
      }

      .cross {
        position: sticky;
        // left:0px;
        top: 0px;
        width: calc(100% + 0.5rem + 6px);
        text-align: right;
        margin-bottom: -2rem;
        button {
          margin-top: -1rem;
          width: 2rem;
          height: 2rem;
          background-color: $border;
          &:hover {
            background-color: $red;
          }
        }
      }
    }
    .basketItem {
      width: 100%;
      margin-bottom: 1rem;
      h4 {
        margin-top: 0px;
        margin-bottom: 0.5rem;
      }
      .first {
        .image {
          border: none;
          width: 75px;
          height: 75px;
          background-repeat: none;
          background-size: cover;
        }
        padding-right: 1rem;
        @include responsive(444px) {
          display: none;
        }
      }
      .second {
        flex: 1;
        width: 100%;
        .flex {
          align-items: top;
        }
        h4 {
          margin: 0px;
          padding: 0px;
          @include responsive(444px) {
            font-size: medium;
          }
        }
        h5 {
          margin: 0px;
        }
        .price {
          display: flex;
          align-items: center;
          margin-top: 0px;
        }
        .title {
          flex: 1;
        }
      }
    }

    form {
      width: 100%;
    }
  }
  .error {
    color: $red;
    font-weight: 700;
    font-style: italic;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .variations {
    font-size: small;
  }
  .quantity {
    display: flex;
    @mixin B() {
      width: 2rem;
      height: 2rem;
      font-weight: 900;
      font-size: x-large;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $border;
      color: white;
      cursor: pointer;
      user-select: none;
      &:hover {
        background-color: $link;
      }
    }
    .b {
      @include B();
    }
    .db {
      @include B();
      cursor: default;
      color: gray;
      &:hover {
        background-color: $border;
      }
    }
    .value {
      box-sizing: border-box;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      color: $fg;
      border: 3px solid $border;
      height: 2rem;
      border-left: none;
      border-right: none;
    }
  }

  .required {
    color: $red;
    font-size: small;
    font-style: italic;
    font-weight: 700;
    margin-top: 0.25rem;
  }

  label {
    font-weight: 700;
  }

  form {
    .dropdown {
      margin-top: 0.25rem;
    }
    .box {
      padding: 0.25rem;
      margin-top: 0.25rem;
      margin-bottom: 1rem;
      max-height: 1.25rem;
      border: 3px solid $border;
    }
    h2 {
      margin-top: 0px;
      margin-bottom: 0.5rem;
    }
    h4 {
      margin-top: 0.5rem;
      margin-bottom: 0px;
    }
    h3 {
      margin: 0px;
      margin-bottom: 0.5rem;
    }
    input {
      display: block;
      margin-top: calc(0.25rem + 3px);
      width: 100%;
      box-sizing: border-box;
    }
    textArea {
      display: block;
      margin-top: 0.25rem;
      border: 3px solid black;
      width: 100%;
      max-width: 600px;
      height: 6rem;
      box-sizing: border-box;
      padding: 0.25rem;
      font-family: "Raleway", sans-serif;
      font-size: medium;
    }
    .flex {
      width: 100%;
    }
  }

  .flex1 {
    flex: 1;
    margin-right: 0.25rem;
  }
  .flex2 {
    flex: 1;
    margin-left: 0.25rem;
  }

  .alignRight {
    text-align: right;
    button {
      margin-right: 0px;
      margin-left: 1px;
    }
  }

  .basket {
    .flex {
      @include responsive(888px) {
        flex-direction: column;
      }
      .left {
        flex: 1;
      }
      .right {
        border: 5px solid $border;
        background-color: white;
        padding: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-left: 1rem;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        button {
          margin-right: 0px;
          margin-bottom: 1rem;
        }
        h2 {
          margin-top: 0px;
          margin-bottom: 1rem;
          @include responsive(888px) {
            margin-top: 0px;
            margin-bottom: 0px;
            margin-right: 4px;
          }
          @include responsive(666px) {
            font-size: larger;
          }
          @include responsive(444px) {
            font-size: large;
          }
        }
        @include responsive(888px) {
          flex-direction: row;
          justify-content: space-between;
          margin-left: 0px;
          padding: 1rem;
          padding-top: 2rem;
          padding-bottom: 2rem;
          button {
            margin-bottom: 0px;
          }
        }
      }
    }
    b {
      margin-right: 0.5rem;
    }
    .quantity {
      font-size: medium;
      .b {
        width: 1.6rem;
        height: 1.6rem;
        font-size: large;
      }
      .db {
        width: 1.6rem;
        height: 1.6rem;
        font-size: large;
      }
      .value {
        height: 1.6rem;
        width: 1.6rem;
      }
    }
    .animate {
      transition: height 0.3s ease;
      overflow: hidden;
    }
    .basketItem {
      padding: 1rem;
      border: 5px solid $border;
      background-color: white;
      display: flex;
      margin-bottom: 1rem;
      .first {
        .image {
          width: 100px;
          height: 100px;
          background-repeat: none;
          background-size: cover;
          @include responsive(555px) {
            width: 75px;
            height: 75px;
          }
          @include responsive(444px) {
            width: 50px;
            height: 50px;
          }
          @include responsive(333px) {
            display: none;
          }
        }
      }
      .second {
        margin-top: -1rem;
        padding: none;
        margin-left: 1rem;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include responsive(444px) {
          margin-left: 0.5rem;
        }
        @include responsive(333px) {
          margin-left: 0px;
        }
        h4 {
          margin: none;
          margin-bottom: 0.5rem;
        }
        button {
          margin: 0px;
        }
        .shit {
          display: flex;
          height: 100%;
          @include responsive(555px) {
            flex-direction: column;
          }
          .one {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .two {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @include responsive(555px) {
              flex-direction: row;
              align-items: center;
            }
          }
        }
      }
    }
  }

  .inline {
    display: inline;
  }

  .inlineBlock {
    display: inline-block;
  }

  .center {
    text-align: center;
    button {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
  }

  .price {
    margin-top: 0.5rem;
    text-align: right;
  }

  .contact {
    p {
      margin-top: none;
    }
    width: 500px;
    margin-left: 1rem;
    margin-right: 1rem;
    form {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .spinner {
    box-sizing: border-box;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 6px solid $c0;
    border-top: 6px solid $c1;
    border-left: 6px solid $c1;
    animation: spinner 1s linear infinite;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  table {
    td {
      padding-left: 1rem;
    }
    .first {
      padding-left: 0px;
      .image {
        border: none;
        width: 75px;
        height: 75px;
        background-repeat: none;
        background-size: cover;
      }
    }
    .second {
      min-width: 100px;
      max-width: 250px;
    }
    .third {
      min-width: 200px;
      max-width: 300px;
    }
    .fourth {
      text-align: right;
    }
  }

  .B {
    display: inline;
    color: $link;
    font-size: medium;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .checkbox {
    user-select: none;
    display: flex;
    align-items: center;
    cursor: default;
    label {
      margin-right: 0.5rem;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.5rem;
      height: 1.5rem;
      border: 3px solid $border;
      background-color: white;
      font-weight: 900;
      &:hover {
        border: 3px solid $link;
      }
    }
  }

  .dropdown {
    white-space: nowrap;
    font-size: medium;
    position: relative;
    max-width: 300px;
    background-color: white;
    border: 3px solid $border;
    padding: 0.5rem;
    height: 2rem;

    box-sizing: border-box;

    .title {
      position: absolute;
      left: 0px;
      top: -5px;
      padding: 0.5rem;
      width: calc(100% + 2px - 3rem);
      overflow: hidden;
      cursor: default;
    }

    .drop {
      box-sizing: border-box;
      position: absolute;
      width: calc(100% + 9px - 2rem);
      left: -3px;
      top: 100%;
      background-color: white;
      border: 3px solid $border;
      border-top: none;
      overflow: hidden;
      transition: max-height 0.2s ease-in-out;

      .option {
        height: 1rem;
        padding: 0.5rem;
        cursor: pointer;
        &:hover {
          background-color: $border;
          color: white;
        }
      }
    }

    .arrow {
      width: 2rem;
      height: 2rem;
      position: absolute;
      right: -3px;
      top: -3px;
      box-sizing: border-box;
      background-color: $border;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      cursor: pointer;

      &:hover {
        background-color: $link;
      }
    }
  }

  .bigBanner {
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $fg;
    position: relative;
    // overflow: hidden;

    .sub {
      font-size: x-large;
      margin-top: 1rem;
      font-weight: bolder;
      font-family: "Montserrat", sans-serif;
      @include responsive(555px) {
        font-size: larger;
      }
      @include responsive(444px) {
        font-size: large;
      }
    }

    .content {
      padding: 0px;
      margin: 0px;
      max-width: 100%;
      text-align: center;
      z-index: 1;
    }

    .Title {
      .st0 {
        fill: #29efad;
      }
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      // gap: calc(1.96363636363vw + 15.7090909px);
      .word {
        display: flex;
        gap: calc(0.36363636vw + 2.90909px);
        margin-left: calc(0.9818181818vw + 7.854545454545px);
        margin-right: calc(0.9818181818vw + 7.854545454545px);
      }
      .letter {
        transition: transform 0.05s linear;
        cursor: pointer;
      }
      svg {
        height: calc(6vw + 48px);
      }
      &.showStore {
        svg {
          height: calc(3vw + 48px);
        }
        .word {
          gap: calc(0.18181818vw + 2.90909px);
          margin-left: calc(0.4909090909vw + 7.854545454545px);
          margin-right: calc(0.4909090909vw + 7.854545454545px);
        }
      }
      &.animating {
        svg {
          transition: height 0.2s linear;
        }
        .word {
          transition: gap 0.2s linear;
          transition: margin 0.2s linear;
        }
      }
    }

    .background {
      position: fixed;
      left: 0px;
      top: 0px;
      z-index: -10;
    }
  }

  .bigbox {
    background-color: white;
    border: 5px solid black;
    margin-bottom: 1rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;

    width: calc(100% - 3rem);
    margin-top: 2rem;

    @include responsive(888px) {
      flex-direction: column;
      width: calc(100% - 2rem);
    }

    @include responsive(666px) {
      width: calc(100% - 1rem);
    }
  }

  .split {
    $lim: 888px;

    display: flex;
    width: calc(100% - 3rem);
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2rem;
    padding-bottom: 4rem;

    @include responsive($lim) {
      flex-direction: column;
      width: calc(100% - 2rem);
      padding-top: 1rem;
    }

    @include responsive(666px) {
      width: calc(100% - 1rem);
      padding-top: 0.5rem;
    }

    .sidebar {
      width: 400px;
      margin-right: 16px;

      @include responsive($lim) {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 0.5rem;
      }
      h3 {
        margin-top: 0px;
      }
    }

    .content {
      width: calc(100% - 416px);
      box-sizing: border-box;
      padding: 1rem;
      border: 5px solid $border;
      background: white;

      @include responsive($lim) {
        width: 100%;
      }

      h3 {
        margin-top: 0px;
      }
    }
  }

  .image {
    box-sizing: border-box;
    // padding:5px;
    // border:5px solid $border;
    // background: white;
    max-width: 100%;
    display: flex;
    flex-direction: column;

    img {
      width: 100%;
      max-width: 400px;
      margin-bottom: 5px;
      border: 5px solid $border;
      box-sizing: border-box;
    }
    .flex {
      border: 5px solid $border;
      box-sizing: border-box;
      background-color: white;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      @mixin arrow() {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          color: $link;
        }
      }
      .left {
        margin-right: 5px;
        @include arrow();
      }
      .right {
        margin-left: 5px;
        @include arrow();
      }
      @mixin darrow() {
        @include arrow();
        opacity: 0.5;
        cursor: default;
        &:hover {
          color: $fg;
        }
      }
      .dleft {
        margin-right: 5px;
        @include darrow();
      }
      .dright {
        margin-left: 5px;
        @include darrow();
      }
      .thumbs {
        width: 100%;
        position: relative;
        height: 0px;
        // padding-bottom:calc(calc(100% - 1rem) / 4);
        overflow: hidden;
        @include responsive(888px) {
          // padding-bottom:calc(calc(100% - 1rem) / 8);
        }
        @include responsive(777px) {
          // padding-bottom:calc(calc(100% - 1rem) / 7);
        }
        @include responsive(666px) {
          // padding-bottom:calc(calc(100% - 1rem) / 6);
        }
        @include responsive(555px) {
          // padding-bottom:calc(calc(100% - 1rem) / 5);
        }
        @include responsive(444px) {
          // padding-bottom:calc(calc(100% - 1rem) / 4);
        }
        @include responsive(333px) {
          // padding-bottom:calc(calc(100% - 1rem) / 3);
        }
        .page {
          display: inline-block;
          width: 100%;
        }
        .scrollable {
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
          transition: left 0.3s ease-in-out;
        }
        .thumb {
          display: inline-block;
          cursor: pointer;
          background-size: cover;
          width: calc(100% / 4);
          height: 0px;
          // padding-bottom:calc(100% / 4);
          @include responsive(888px) {
            width: calc(100% / 8);
            // padding-bottom:calc(100% / 8);
          }
          @include responsive(777px) {
            width: calc(100% / 7);
            // padding-bottom:calc(100% / 7);
          }
          @include responsive(666px) {
            width: calc(100% / 6);
            // padding-bottom:calc(100% / 6);
          }
          @include responsive(555px) {
            width: calc(100% / 5);
            // padding-bottom:calc(100% / 5);
          }
          @include responsive(444px) {
            width: calc(100% / 4);
            // padding-bottom:calc(100% / 4);
          }
          @include responsive(333px) {
            width: calc(100% / 3);
            // padding-bottom:calc(100% / 3);
          }
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }

  input {
    border: 3px solid $border;
    padding: 0.25rem;
    padding-left: 0.5rem;
    font-size: medium;
    outline: none;

    &:disabled {
      border: 3px solid grey;
      background-color: lightgray;
    }
  }

  .disabled {
    color: grey;
  }

  textArea {
    outline: none;
  }

  .labelPair {
    display: flex;
    $lim: 444px;
    margin-bottom: 1rem;

    @include responsive($lim) {
      flex-direction: column;
    }

    .label {
      width: 150px;
      text-align: right;
      margin-right: 16px;
      font-weight: 700;
      color: $fg;
      @include responsive($lim) {
        padding-top: 0px;
        width: 100%;
        text-align: left;
        margin-right: 0px;
      }
    }

    .pair {
      width: calc(100% - 166px);
      @include responsive($lim) {
        width: 100%;
      }
    }
  }

  button {
    outline: none;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: medium;
    background-color: $link;
    border: none;
    padding: 0.5rem;
    font-weight: 700;
    color: white;
    cursor: pointer;

    &:hover {
      background-color: $fg;
    }

    &.green {
      background-color: $green;
      color: $fg;
      border: 3px solid $border;
      &:hover {
        background-color: $fg;
        color: white;
      }
    }

    &.red {
      background-color: $red;
      &:hover {
        background-color: $fg;
      }
    }

    &.disabled {
      background-color: grey;
      color: white;
      cursor: default;
    }
  }

  .buy {
    display: flex;
    align-items: flex-start;
    .image {
      border: none;
      width: 75px;
      height: 75px;
      background-repeat: none;
      background-size: cover;
      margin-right: 0.5rem;
    }
    .item {
      display: flex;
      margin-bottom: 0.5rem;
    }
    .left {
      overflow: hidden;
      width: 500px;
      @include responsive(1165px) {
        flex: 1;
        width: auto;
      }
      @include responsive(777px) {
        display: none;
      }
    }
    .right {
      flex: 1;
      margin-left: 1rem;
      @include responsive(777px) {
        margin-left: 0px;
      }
      h2 {
        margin-top: 0.5rem;
      }
    }
  }

  .slideshow {
    position: relative;
    .flex {
      position: absolute;
      left: 0px;
      top: 0px;
      align-items: center;
      justify-content: center;
    }
    img {
      transition: opacity 0.5s linear;
    }
  }

  .receipt {
    display: flex;
    @include responsive(777px) {
      flex-direction: column;
    }
    .image {
      border: none;
      width: 75px;
      height: 75px;
      background-repeat: none;
      background-size: cover;
      margin-right: 0.5rem;
    }
    .first {
      @include responsive(444px) {
        display: none;
      }
    }
    .second {
      margin-right: 0.5rem;
      flex: 1;
    }
    h4 {
      margin: 0px;
    }
    .item {
      display: flex;
      margin-bottom: 0.5rem;
    }
    .left {
      width: 500px;
      margin-right: 1rem;
      @include responsive(999px) {
        flex: 1;
        width: auto;
      }
      @include responsive(777px) {
        margin-right: 0px;
        margin-bottom: 1rem;
      }
    }
    .right {
      flex: 1;
      h2 {
        margin-top: 0.5rem;
      }
    }
    .variations {
      font-size: small;
    }
  }

  .invoice {
    h2 {
      margin-top: 0.5rem;
    }
    .sploot {
      display: flex;
      .splootl{
        width:9rem;
      }
    }
    .hour {
      display: flex;
      &.total {
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
      }
      .title {
        flex: 1;
      }
      .charge {
        flex: 1;
      }
    }
    p {
      margin-top: 0px;
    }
    display: flex;
    @include responsive(777px) {
      flex-direction: column;
    }
    .first {
      @include responsive(444px) {
        display: none;
      }
    }
    .second {
      margin-right: 0.5rem;
      flex: 1;
    }
    h4 {
      margin: 0px;
    }
    .left {
      width: 500px;
      margin-right: 1rem;
      @include responsive(999px) {
        flex: 1;
        width: auto;
      }
      @include responsive(777px) {
        margin-right: 0px;
        margin-bottom: 1rem;
      }
    }
    .right {
      flex: 1;
    }
    .variations {
      font-size: small;
    }
  }

  .banner {
    color: $fg;
    border-bottom: 5px solid $border;
    background-color: $c0;
    padding: 0px;

    .a {
      display: flex;
      justify-content: center;
      &:hover {
        text-decoration: none;
      }
    }

    .button {
      display: flex;
      align-items: center;
      margin: 0px;
      font-size: medium;
      padding: 0.5rem;
      font-weight: 700;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0);
      color: $fg;
      padding-top: 0px;
      padding-bottom: 0px;
      &:hover {
        background-color: $fg;
        color: white;
      }
    }

    .content {
      padding: 0px;
      // margin: 0px;
      // max-width:100%;
      // text-align: center;
      display: flex;
      justify-content: center;

      @include responsive(555px) {
        flex-direction: column;
      }

      img {
        height: 2rem;
        margin: 0px;
        padding: 0px;
        cursor: pointer;
        // display: block;
        // border:1px solid red;
      }

      .buttons {
        display: flex;
        justify-content: center;
      }
    }

    .h1 {
      margin: 0px;
      padding: 0px;
    }

    .logo {
      height: 2rem;
      rect {
        fill: none;
      }
      &.hover {
        rect{
          fill: black;
        }
        path {
          fill: white;
        }
      }
    }
  }

  .thumbFlex {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;

    background-color: white;
    border: 5px solid $border;
    padding: 0.5rem;

    .item {
      border: 3px solid $border;
      text-align: center;
      box-sizing: border-box;
      width: calc(100% / 5 - 0.5rem);
      height: 0px;
      margin: 0.25rem;
      // padding-bottom:calc(100% / 5 - 0.5rem);
      overflow: hidden;
      display: block;
      background-color: white;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      position: relative;
      transition: opacity 0.2s ease-in-out;
      cursor: pointer;

      @include responsive(999px) {
        width: calc(100% / 4 - 0.5rem);
        // padding-bottom:calc(100% / 4 - 0.5rem);
      }

      @include responsive(666px) {
        width: calc(100% / 3 - 0.5rem);
        // padding-bottom:calc(100% / 3 - 0.5rem);
      }

      @include responsive(333px) {
        width: calc(100% / 2 - 0.5rem);
        // padding-bottom:calc(100% / 2 - 0.5rem);
      }

      a {
        color: $fg;
      }

      .tool {
        font-size: x-large;
        @include responsive(777px) {
          font-size: large;
        }
        @include responsive(444px) {
          font-size: medium;
        }
        display: block;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        .title {
          width: 100%;
          box-sizing: border-box;
          border-top: 3px solid $border;
          border-bottom: 3px solid $border;
        }
        h3 {
          margin: 0;
        }
      }

      .tool:hover {
        opacity: 1;
      }
    }
  }

  .gameContent {
    width: calc(100% - 3rem);
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include responsive(888px) {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      width: calc(100% - 2rem);
    }

    @include responsive(666px) {
      width: calc(100% - 1rem);
    }

    @include responsive(600px) {
      max-width: calc(100% - 1rem);
    }

    margin-left: auto;
    margin-right: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading {
      width: 100%;
      @include responsive(600px) {
        padding-left: 0.5rem;
      }
    }

    .game {
      position: relative;
      overflow: hidden;
      width: 500px;
      height: 0px;
      padding-bottom: 500px;
      @include responsive(600px) {
        width: 100%;
        padding-bottom: 100%;
      }
      @include responsive(500px) {
        h3 {
          font-size: medium;
        }
        h2 {
          font-size: large;
        }
      }
      background-color: rgb(104, 200, 200);
      border: 5px solid black;
      box-sizing: border-box;
      cursor: none;
      img {
        position: absolute;
      }
      div {
        position: absolute;
      }
      .opacity {
        transition: opacity 0.5s linear;
        h3 {
          padding-bottom: 2rem;
        }
      }
      .centerFlex {
        left: 0;
        top: 0;
        padding: 1rem;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        @include responsive(333px) {
          padding: 0.25rem;
        }
      }
      .lots {
        @include responsive(333px) {
          h3 {
            font-size: small;
            margin-bottom: 0.25rem;
          }
        }
      }
      .floor {
        border-top: 5px solid black;
        width: 100%;
        bottom: 0px;
        left: 0px;
        height: 100px;
        background-color: rgb(180, 180, 180);
      }
      h3 {
        margin-top: 0px;
        margin-top: 0.5rem;
      }
      h2 {
        margin: 0px;
      }
    }
  }

  h1 {
    font-family: "Leckerli One", cursive;
    margin-top: 0.5rem;
  }

  h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  h3 {
    font-family: "Montserrat", sans-serif;
  }

  h4 {
    font-family: "Montserrat", sans-serif;
  }

  h5 {
    font-family: "Montserrat", sans-serif;
  }

  .bg2 {
    // background-color: $bg;
    // color: $fg;

    .content {
      z-index: 1;
    }
  }

  .expandItems {
    color: $fg;
    margin-bottom: 1rem;
    background-color: white;
    border: 5px solid $border;
    border-top: none;
    text-align: center;
    padding: 0.5rem;
  }

  .items {
    color: $fg;
    margin-bottom: 1rem;
    background-color: white;
    padding: 5px;
    border: 5px solid $border;
    overflow-y: hidden;

    transition: max-height 0.5s ease-in-out;

    h4 {
      margin-top: 0px;
      margin-bottom: 0.5rem;
    }

    h5 {
      margin-top: 0px;
      margin-bottom: 0.5rem;
    }

    .flex {
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
    }

    .item {
      text-align: center;
      box-sizing: border-box;
      width: calc(100% / 5);
      height: 0px;
      // padding-bottom:calc(100% / 5);
      overflow: hidden;
      display: block;
      background-color: white;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      position: relative;
      transition: opacity 0.2s ease-in-out;
      cursor: pointer;

      @include responsive(999px) {
        width: calc(100% / 4);
        // padding-bottom:calc(100% / 4);
      }

      @include responsive(666px) {
        width: calc(100% / 3);
        // padding-bottom:calc(100% / 3);
      }

      @include responsive(333px) {
        width: calc(100% / 2);
        // padding-bottom:calc(100% / 2);
      }

      a {
        color: $fg;
      }

      .tool {
        font-size: x-large;
        @include responsive(777px) {
          font-size: large;
        }
        @include responsive(444px) {
          font-size: medium;
        }
        display: block;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.75);
        left: 0;
        top: 0;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        h3 {
          margin: 0;
        }
      }

      .tool:hover {
        opacity: 1;
      }
    }
  }

  .content {
    width: calc(100% - 3rem);
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include responsive(888px) {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      width: calc(100% - 2rem);
    }

    @include responsive(666px) {
      width: calc(100% - 1rem);
    }
  }
}

a {
  color: $link;
  text-decoration: none;
  cursor: pointer;
  &.black {
    color: $fg;
  }
}

a:hover {
  text-decoration: underline;
}

.LinkTree {
  .options {
    display: flex;
    flex-wrap: wrap;
    gap:1rem;
    justify-content: center;
    margin-bottom:2rem;
    .option {
      color: inherit;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      width:19%;
      max-width: 300px;
      min-width: 150px;
      .pic {
        svg {
          width:150px;
          height:150px;
          @include responsive(1111px) {
            width:100px;
            height:100px;
          }
          .black { fill:black }
          .blue { fill:$c1 }
          &:hover {
            cursor: pointer;
            .black { fill:white }
            .blue { fill:black }
          }
        }
      }
      .caption {
        @include responsive(1111px) {
          font-size: large;
        }
      }
      &:hover {
        cursor: default;
      }
    }
  }
}

.Tabs {
  display: flex;
  flex-direction: row;
  font-size: large;
  user-select: none;
  .tab {
    margin-right: 1rem;
    &.selected {
      background-color: $afg;
      color: $abg;
    }
  }
  margin-bottom: 1rem;
  &.AdminTabs {
    font-size: small;
    text-transform: uppercase;
    width:800px;
  }
}
.TabRange {
  display: flex;
  flex-direction: row;
  font-size: large;
  user-select: none;
  .tabby {
    display: flex;
    .tab {
      &.selected {
        background-color: $afg;
        color: $abg;
      }
    }
    .gap {
      width: 1rem;
      &.selected {
        background-color: $afg;
        color: $abg;
      }
    }
    margin-bottom: 1rem;
  }
}

input {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}
textarea {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

.SwapTabs {
  display: flex;
  flex-direction: row;
  font-size: large;
  input {
    width: 4rem;
  }
  .tab {
    .selected {
      background-color: $afg;
      color: $abg;
    }
    &:hover {
      .leftArrow {
        visibility: inherit;
      }
      .rightArrow {
        visibility: inherit;
      }
    }
    .leftArrow {
      margin-right: 0.2rem;
      font-size: small;
      position: relative;
      top: -0.1rem;
      visibility: hidden;
      &.disabled {
        cursor: default;
      }
    }
    .rightArrow {
      margin-left: 0.2rem;
      font-size: small;
      position: relative;
      top: -0.1rem;
      visibility: hidden;
      &.disabled {
        cursor: default;
      }
    }
  }
  .hide {
    visibility: hidden;
    margin-left: 1rem;
  }
  &:hover {
    .hide {
      visibility: inherit;
    }
  }
  margin-bottom: 1rem;
}

.Pages {
  .tab {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    margin-right: 0.3rem;
  }
}
.MusicAdmin {
  .albums {
    display: flex;
    gap:1rem;
    flex-direction: column;
    margin-bottom:2rem;
    .category {
      .categoryTitle {
        user-select: none;
        background-color: $afg;
        width:100%;
        padding: 0.5rem;
        color:$abg;
        box-sizing: border-box;
        margin-bottom:0.5rem;
      }
    }
  }
  .albumDisplay {
    display: flex;
    gap:1rem;
    img {
      width:250px;
    }
    .progress {
      position:relative;
      display: flex;
      height:1rem;
      margin-bottom:1rem;
      .redPercent, .whitePercent {
        display: flex;
        align-items: center;
        justify-content: center;
        position:absolute;
        left:0px;
        top:0px;
        height:1rem;
      }
      .redPercent {
        color: $afg;
      }
      .progressBar {
        transition: width 1s linear;
        z-index: 1;
        height:1rem;
        position:absolute;
        left:0px;
        top:0px;
        background-color: $afg;
        overflow: hidden;
        .whitePercent {
          color:$abg;
        }
      }
    }
    .missingSongName {
      margin-top:1rem;
      margin-bottom:1rem;
    }
    .albumName {
      margin-top:1rem;
    }
    .albumSongs {
      margin-bottom:1rem;
    }
    .albumArtwork {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .albumName, .albumArtist {
      font-size: x-large;
      span {
        font-size: medium;
      }
    }
    .songDoesNotExist{
      font-style: italic;
      color: $ared;
    }
  }
  .downloading {
    .log {
      background-color: black;
      color: white;
      font-family: 'Courier New', Courier, monospace;
      height:500px;
      overflow-y: scroll;
      overflow-x: scroll;
      white-space:nowrap;
      padding:0.5rem;
      font-size: small;
      .line {
        pre {
          margin:0px;
        }
      }
      .blankLine {
        height:1rem;
      }
    }
    .viewAlbum {
      margin-top:0.5rem;
    }
    ::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }
    ::-webkit-scrollbar-track {
      background: black;
    }
    ::-webkit-scrollbar-thumb {
      background: $afg;
    }
  }
  .searchForNewAlbum {
    input {
      width:100%;
      margin-bottom: 1rem;
    }
    .albumThumbnails {
      overflow-y: scroll;
      height: 60vh;
      min-height: 200px;
      .albumThumbnail {
        width:143px;
        height:143px;
      }
    }
  }
  .downloadNewAlbumButton {
    margin-bottom:1rem;
  }
  .albumThumbnails {
    display:flex;
    flex-wrap: wrap;
    gap:8px;
    width:100%;
    .albumThumbnail {
      width:144px;
      height:144px;
      position:relative;
      img {
        width:100%;
        position:absolute;
        top:0;
        left:0;
      }
      &:hover {
        .deets {
          visibility: visible;
        }
        .missingSong {
          visibility: hidden;
        }
      }
      .missingSong {
        position:absolute;
        z-index: 1;
        bottom:0;
        left:0;
        padding:0.25rem;
        box-sizing: border-box;
        background-color: $afg;
        color: $abg;
      }
      .deets {
        visibility: hidden;
        position:absolute;
        z-index: 1;
        bottom:0;
        left:0;
        padding:0.25rem;
        box-sizing: border-box;
        background-color: $afg;
        color: $abg;
        font-size: small;
        pointer-events: none;
        .albumName, .albumArtist {
          margin-bottom:5px;
        }
      }
    }

  }
  .Albums {
    margin-bottom:2rem;
    display:flex;
    flex-direction: column;
    gap:1rem;
    h2, p{
      margin:0px;
    }
    .album {
      .itemSplit {
        display: flex;
        gap:1rem;
        .left {
          width:200px;
          p {
            margin-bottom:1rem;
          }
        }
        .right {
          display:flex;
          flex-direction: column;
          justify-content: space-between;
          flex:1;
        }
      }
      .song {
        display: flex;
        gap:0.5rem;
        .dots {
          flex:1;
          margin-top:9px;
          border-top: 1px dashed  $red;
          visibility: hidden;
          &.visible {
            visibility: inherit;
          }
        }
      }
    }
  }
  .hideButtons {
    visibility: hidden;
    &.visible {
      visibility: inherit;
    }
    display: flex;
    gap:1rem;
  }
}

.Music {
  max-width:600px;
  width:100%;
  margin-left: auto;
  margin-right: auto;
  font-family: Arial, Helvetica, sans-serif;
  h1 {
    margin:0px;
  }
  p {
    margin:0px;
    font-weight: bold;
    font-size: large;
  }
  .Album {
    margin-top:0.5rem;
    margin-bottom: 0.5rem;
    .gif {
      text-align: center;
      margin-top:2rem;
      margin-bottom:2rem;
    }
    p {
      margin-bottom:1rem;
    }
    .albumSplit {
      display: flex;
      gap:1rem;
      .right {
        flex:1;
        display:flex;
        flex-direction: column;
        gap:1rem;
      }
      .left {
        img {
          width:50vw;
          max-width: 300px;
        }
      }
    }
    .song {
      font-size: x-large;
      width:100%;
      background: none;
      border:none;
      text-align: left;
      padding: 0px;
      padding-right:0.25rem;
      margin-top:-2px;
      padding-bottom:2px;
      cursor: pointer;
      svg {
        width:24px;
        position:relative;
        top: 4px;
      }
      &:hover {
        color: #fbb03b;
        background-color: black;
        padding-left:0.25rem;
        padding-right:0px;
        svg {
          fill:#fbb03b;
        }
      }
    }
  }
  .musicHeading {
    padding-top:2rem;
    text-align: center;
  }
  .musicPlayer {
    position:fixed;
    bottom:0px;
    left:0px;
    width:100%;
    height:4rem;
    background-color: #fbb03b;
    border-top:2px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    button{
      padding:0px;
      background: none;
      border:none;
      cursor: pointer;
      &:hover {
        background-color: black;
        color: #fbb03b;
        svg {
          fill:#fbb03b;
        }
      }
    }
    svg{
      height: 32px;
    }
    .controls {
      display: flex;
      width:calc(100% - 2rem);
      max-width: 1000px;
      .downloadButton {
        a {
          width:32px;
          height:32px;
          &:hover {
            svg {
              background-color: black;
              fill:#fbb03b;
            }
          }
        }
      }
      .bar {
        margin-left:1rem;
        flex:1;
        cursor: pointer;
        .line {
          border-top:2px dashed black;
          position:relative;
          top:14px;
          margin-right:-14px;
        }
        position:relative;
        .dot {
          width:14px;
          height:14px;
          background-color: black;
          border-radius: 7px;
          position:absolute;
          top:8px;
        }
      }
      .barExtra {
        cursor: pointer;
        width:14px;
        margin-right:1rem;
      }
    }
  }
}

.HIDDEN {
  height:0px;
  width:0px;
  overflow: hidden;
  visibility: hidden; 
}

.Admin,
.Orders,
.Invoices,
.Items,
.Vouchers,
.Reddit,
.Schedule,
.Secret,
.Ambigrams,
.MusicAdmin,
.SelfAssessment {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: $afg;
  background-color: $abg;

  textarea {
    background-color: $abg;
  }
  input {
    background-color: $abg;
  }
  input.lil {
    width: 4rem !important;
  }

  a.red {
    color:$ared;
  }

  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track {
    background: $abg;
  }
  ::-webkit-scrollbar-thumb {
    background: $afg;
  }

  width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
  .hidden {
    width: 0px;
    height: 0px;
    overflow: hidden;
  }
  .dateSelect {
    display: flex;
    width: 8rem;
    .dateInner {
      flex: 1;
      &:hover {
        .arrow {
          visibility: visible;
        }
      }
      .arrow {
        width: 100%;
        font-size: small;
        user-select: none;
        visibility: hidden;
      }
      text-align: center;
    }
  }
  .RedditCampaigns {
    input {
      margin-bottom: 1rem;
    }
    .buts {
      display: flex;
      gap: 1rem;
    }
  }
  .Check {
    border: 1px solid $afg;
    width: 0.75rem;
    height: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: small;
    padding:1px;
    &:hover {
      text-decoration: none !important;
    }
  }
  .small {
    font-size: smaller;
    transform: scale(1.5);
  }
  input {
    border: 1px solid $afg;
    outline: none;
  }
  h1 {
    margin: 0px;
    margin-bottom: 1rem;
  }
  a {
    color: $afg;
  }
  .T {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    &.checked {
      background-color: $afg;
      color: $abg;
    }
  }
  .A {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .disabledA {
    position:relative;
    font-style: italic;
    &::after {
      position:absolute;
      left:0px;
      border-top: 2px solid $afg;
      background-color: $afg;
      top:0.6rem;
      width:100%;
      transform: rotate(-3deg);
      content: " ";
    }
    cursor: default;
  }
  .indent {
    margin-bottom: 0.5rem;
    margin-left: 2rem;
  }
  .mb {
    margin-bottom: 0.5rem;
  }
  .mt {
    margin-top: 0.5rem;
  }
  .i {
    font-style: italic;
  }
  .AreYouSure {
    display: flex;
    flex-direction: row;
    div {
      margin-right: 0.5rem;
    }
  }
  .title {
    display: flex;
    align-items: flex-end;
    .left {
      flex: 1;
      font-size: x-large;
      font-weight: bold;
    }
    .largeBit {
      font-size: x-large;
      font-weight: bold;
      margin-right: 1rem;
    }
    .right {
      font-weight: bold;
    }
    margin-bottom: 0.5rem;
  }

  .expanded {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $abgFaded;
    z-index: 100;
    &.nofade {
      background: none;
    }
    .window {
      .modalHeading {
        background-color: $afg;
        display: flex;
        align-items: center;
        margin-left: -1rem;
        margin-right: -1rem;
        padding-top: 0.125rem;
        padding-bottom: 0.125rem;
        .heading {
          padding-left: 1rem;
          flex: 1;
          color: $abg;
          font-size: small;
          font-weight: bold;
        }
        button {
          background: none;
          border: none;
          font-family: inherit;
          color: $abg;
          font-weight: bold;
          font-size: x-small;
          cursor: pointer;
        }
        margin-bottom: 1rem;
      }
      // border:1px solid $afg;
      background-color: $abg;
      padding: 1rem;
      padding-top: 0px;
      width: 600px;

      &.red {
        .modalHeading {
          background-color: $ared;
        }
        color: $ared;
        A {
          color: $ared;
        }
        .items {
          border: 1px solid $ared;
        }
      }
    }
    .buts {
      display: flex;
      gap: 1rem;
    }
    .itemSplit {
      input {
        border: none;
      }
      display: flex;
      .left {
        width: 120px;
      }
      &.lil {
        .right {
          input {
            width: 4rem;
          }
        }
      }
      .right {
        flex: 1;
        input {
          width: 100%;
        }
        textarea {
          width: 100%;
          height: 3rem;
          border: none;
          outline: none;
          font-family: inherit;
          font-size: inherit;
        }
        &.columns {
          display: flex;
          .column {
            margin-right: 1rem;
            .columnTitle {
              font-weight: bold;
            }
            .columnStuff {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
      margin-bottom: 0.5rem;
    }
  }

  .horizontalButtons {
    display: flex;
    gap: 1rem;
  }

  .expandedInvoice {
    .hours {
      .hoursInner {
        display: flex;
        gap: 0.5rem;
        .hoursLeft {
          flex: 2;
        }
        .hoursRight {
          flex: 1;
          display: flex;
          input {
            position: relative;
            top: -1px;
          }
        }
        .x {
          width: 1rem;
        }
        &.total {
          font-size: large;
        }
      }
    }
  }
  .selfAssessmentOptions {
    display:flex;
    gap:1rem;
    .taxYearCheck {
      display:flex;
      align-items: center;
      margin-bottom:0.5rem;
      .right {
        flex:1;
        margin-left:0.5rem;
      }
      .Check {
        padding-bottom: 2px;
        padding-top:0px;
      }
    }
  }
  .total {
    font-size: x-large;
    margin-bottom: 1rem;
  }
}

.Reddit {
  .RedditUsers {
    .expanded {
      .user {
        margin-bottom: 0px;
      }
    }
    .user {
      margin-bottom: 1rem;
      h2 {
        margin: 0px;
        margin-bottom: 0.5rem;
      }
      .split {
        display: flex;
        .left {
          width: 5rem;
        }
      }
    }
  }
  .clickMe {
    cursor: pointer;
  }
  .RedditCalendar {
    display: flex;
    flex-direction: column;
    .week {
      &.topbit {
        .hour {
          font-weight: bold;
        }
      }
      display: flex;
      .dayTitle {
        width: 2rem;
        margin-right: 0.5rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .hour {
        flex: 1;
        text-align: center;
        height: 1.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .BarGraph {
    .graph {
      display: flex;
      .bar {
        flex: 1;
        &.zero {
          visibility: hidden;
        }
        display: flex;
        flex-direction: column;
        .inner {
          margin-left: -1;
          box-sizing: border-box;
          align-items: baseline;
          border: 1px solid $afg;
          background-color: $afg;
          width: 100%;
          &.black {
            background-color: $abg;
          }
        }
        .blank {
          flex: 1;
        }
      }
    }
  }
  .PieChart {
    display: flex;
    .pie {
      margin-right: 1rem;
    }
    .data {
      .datum {
        display: flex;
        font-weight: bold;
        cursor: default;
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        padding-top: 0.1rem;
        &.selected {
          background-color: $afg;
          color: $abg;
        }
        .percent {
          // font-size: small;
          width: 2.5rem;
        }
        .swatch {
          width: 0.75rem;
          height: 0.75rem;
          margin-right: 0.5rem;
          position: relative;
          top: 0.125rem;
        }
      }
    }
  }
}

.Vouchers {
  .addVoucher {
    margin-bottom: 1rem;
    font-size: large;
  }
  .expanded {
    .window {
      h2 {
        margin: 0px;
        margin-bottom: 0.5rem;
      }
      .voucherSplit {
        display: flex;
        .left {
          width: 6rem;
        }
        .right {
          flex: 1;
        }
        margin-bottom: 0.5rem;
        input {
          border: none;
        }
      }
      .buts {
        display: flex;
        gap: 1rem;
      }
    }
  }
  .voucher {
    margin-bottom: 1rem;
    h2 {
      margin: 0px;
      margin-bottom: 0.5rem;
    }
    h4 {
      margin: 0px;
    }
    .voucherSplit {
      display: flex;
      .left {
        width: 5rem;
      }
      .right {
        flex: 1;
      }
    }
  }
}

.Items {
  .addItem {
    font-size: large;
    margin-bottom: 1rem;
    display: flex;
    gap: 1rem;
  }
  .item {
    margin-bottom: 1rem;
    .clickme {
      cursor: pointer;
    }
    .images {
      margin-top: 1rem;
      display: flex;
      img {
        height: 64px;
        margin-right: 0.5rem;
      }
    }
    &:hover {
      .hideButtons {
        visibility: inherit;
      }
    }
    .hideButtons {
      visibility: hidden;
      display: flex;
      .A,
      .AreYouSure {
        margin-right: 1rem;
      }
    }
  }

  .buts {
    display: flex;
    gap: 1rem;
  }

  .expanded {
    .variations {
      input {
        width: 7.5rem !important;
        box-sizing: border-box;
      }
      h4 {
        margin: 0px;
      }
      display: flex;
      flex-wrap: wrap;

      .variation {
        width: 7rem;
        margin-right: 1rem;
        .variationTitle {
          display: flex;
        }
        .aLeft {
          font-size: small;
          margin-right: 0.2rem;
        }
        .aRight {
          font-size: small;
          margin-left: 0.2rem;
        }
        .hide {
          visibility: hidden;
        }
        &:hover {
          .hide {
            visibility: inherit;
          }
        }
      }
      .variationOptions {
        display: flex;
        .optionsLeft {
          width: 5rem;
          input {
            width: 4.5rem !important;
          }
        }
        .optionsRight {
          width: 2rem;
          input {
            width: 1.5rem !important;
          }
        }
      }
    }
    .images {
      display: flex;
      img {
        height: 64px;
        // position:absolute;
        top: 0px;
        left: 0px;
      }
      .image {
        margin-right: 0.5rem;
        height: calc(64px + 1.5rem);
        &:hover {
          .imageOptions {
            visibility: inherit;
          }
        }
        .imageOptions {
          visibility: hidden;
          display: flex;
          justify-content: space-between;
          font-size: small;
          .x {
            position: relative;
            top: -0.15rem;
          }
          .nothing {
            width: 1rem;
            height: 1rem;
          }
        }
      }
      .uploadButton {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: x-large;
        border: 1px solid $afg;
        box-sizing: border-box;
      }
      .uploader {
        .A {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.Orders {
  .order {
    cursor: pointer;
    margin-bottom: 1rem;
    &.red {
      color: $ared;
      A {
        color: $ared;
      }
      .items {
        border: 1px solid $ared;
      }
    }
    &:hover {
      .title {
        .left {
          text-decoration: underline;
        }
      }
    }
  }

  .items {
    padding: 0.5rem;
    border: 1px solid $afg;
    margin-bottom: 0.5rem;
    .item {
      display: flex;
      .left {
        flex: 2;
      }
      .right {
        flex: 1;
      }
    }
  }

  .info {
    display: flex;
    .left {
      width: 100px;
      font-weight: bold;
      // font-size: large;
    }
    .right {
      flex: 1;
    }
    margin-bottom: 0.5rem;
  }
}

.disabled {
  opacity: 0.5;
}

.Schedule {
  ul {
    margin: 0px;
    padding-left: 1.5rem;
    li {
      margin: 0px;
      padding: 0px;
    }
  }
  .editingToggle {
    .T {
      font-size: large;
    }
    margin-bottom: 1rem;
  }
  .schedule {
    display: flex;
    gap: 1rem;
    align-items: center;
    .today {
      font-size: x-large;
      flex: 1;
      border: 1px solid $afg;
      padding: 0.5rem;
    }
  }
  .editingSchedule {
    .day {
      &.red {
        color: $ared;
      }
      margin-bottom: 0.5rem;
      &:hover {
        .hideButtons {
          visibility: inherit;
        }
      }
      .hideButtons {
        visibility: hidden;
        &.visible {
          visibility: inherit;
        }
        display: flex;
        gap: 1rem;
      }
      .scheduleItem {
        display: flex;
        gap: 1rem;
        .x {
          visibility: hidden;
        }
        .scheduleTitle {
          padding: 0px;
        }
        &:hover {
          .x {
            visibility: inherit;
          }
        }
      }
    }
  }
}

.SelfAssessment {
  .selfAssessmentGraphs {
    .graph {
      margin-top: 1rem;
      position: relative;
      .label {
        position: absolute;
        height: 1rem;
        left: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: small;
        pointer-events: none;
      }
      .mouseLabel {
        position: absolute;
        pointer-events: none;
        .innerLabel {
          position: absolute;
          right: 0.25rem;
          top: 0.25rem;
          width: 15rem;
          text-align: right;
          font-size: small;
        }
      }
      svg {
        stroke: $afg;
        stroke-width: 1px;
        fill: none;
      }
    }
    .str {
      font-size: small;
    }
    .profit {
      span {
        font-size: x-large;
      }
    }
    .equidistant {
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      height: 0px;
      position: relative;
      top: -1rem;
      pointer-events: none;
    }
  }

  .bulkIncome {
    .records {
      max-height: 500px;
      overflow-y: scroll;
    }
  }

  .selfAssessmentCompiler {
    .timeline {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      .year {
        font-size: x-large;
      }
      .fart {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
      }
      .fg {
        color: $afg;
      }
      .red {
        color: $ared;
      }
    }
  }

  .addButtons {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.5rem;
    font-size: large;
  }
  .record {
    cursor: pointer;
    margin-bottom: 1rem;
    .title {
      align-items: center;
      .left {
        font-size: medium;
        .smallDate {
          font-style: italic;
        }
      }
      .middle {
        flex: 1;
        text-align: center;
      }
      .amount {
        flex: 1;
        text-align: right;
        font-size: x-large;
      }
    }
  }

  .expandedCompilation {
    .fuckme {
      font-style: italic;
    }
    .bigballs {
      font-size: x-large;
    }
  }
}

.Ambigram {
  .inner {
    max-width: 800px;
    padding:1rem;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    font-family: Arial, Helvetica, sans-serif;
    font-size: large;
    .noSpaces {
      li {
        margin-bottom:1rem;
      }
      .mb {
        margin-bottom:1rem;
      }
      p {
        margin:0px;
      }
    }
    h1 {
      text-align: center;
    }
    .ambibox0 {
      text-align: center;
      &.spinning {
        z-index: 10;
      }
      position:relative;
      .tooltip {
        padding:0.25rem;
        background-color: white;
        border: 1px solid black;
        position:absolute;
        right:0%;
        opacity:0;
        transition:opacity 0.2s linear;
        z-index: 100;
        &.visible {
          opacity:1;
        }
      }
      .ambibox1 {
        transition: transform 0.1s linear;
        .ambigram {
          transition: transform 1s cubic-bezier(.26,.18,0,1);
          max-width: 100%;
          cursor: pointer;
        }
      }
    }
  }
}

.Dropdown {
  position: relative;
  display: flex;
  .down {
    width:10rem;
    background-color: $abg;
    border: 1px solid $afg;
    padding: 0.25rem;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 100000;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .selected {
      background-color: $afg;
      color:$abg;
      padding:0.25rem;
      margin:-0.25rem;
    }
  }
}

.Secret {
  .secretInput {
    width: 100%;
    text-align: center;
    background-color: none;
    border:none;
    cursor: default;
  }
  .secretItems {
    margin-bottom: 1rem;
    h3 {
      margin-bottom: 0.5rem;
    }
    p {
      margin:0px;
    }

    img {
      max-width: 500px;
    }
  }
}

.Invoices {
  .clientList {
    margin-top: 0.5rem;
    .clientListItem {
      font-style: italic;
    }
  }

  .addButtons {
    margin-bottom: 1rem;
    font-size: large;
  }

  .client {
    cursor: pointer;
    margin-bottom: 1rem;
    &:hover {
      .largeBit {
        text-decoration: underline;
      }
    }
  }
  .invoice {
    cursor: pointer;
    margin-bottom: 1rem;
    &.red {
      color: $ared;
      A {
        color: $ared;
      }
      .items {
        border: 1px solid $ared;
      }
    }
  }

  .items {
    padding: 0.5rem;
    border: 1px solid $afg;
    margin-bottom: 0.5rem;
    .item {
      display: flex;
      .left {
        flex: 2;
      }
      .right {
        flex: 1;
      }
      &.itemTotal {
        font-size: large;
        font-weight: bold;
      }
    }
  }

  .info {
    display: flex;
    .left {
      width: 100px;
      font-weight: bold;
      // font-size: large;
    }
    .right {
      flex: 1;
    }
    margin-bottom: 0.5rem;
  }

  .newInvoice {
    .window {
      h2 {
        margin: 0px;
        margin-bottom: 0.5rem;
      }
      .invoiceSplit {
        display: flex;
        .left {
          width: 6rem;
        }
        .right {
          flex: 1;
        }
        margin-bottom: 0.5rem;
        input {
          border: none;
        }
      }
      .buts {
        display: flex;
        gap: 1rem;
      }
    }
  }
}
.faded {
  cursor: default !important;
  // pointer-events: none;
  opacity: 0.25;
}

.Ambigrams {
  &.app {
    display: flex;
    width:calc(100%);
    padding:0px;
    .Mainbit {
      padding:1rem;
      height:100vh;
      box-sizing: border-box;
      display: flex;
      overflow: scroll;
      flex:1;
      .UploadTab {
        user-select: none;
        display: flex;
        flex-direction: column;
        width:100%;
        gap:1rem;
        .VideoThumb {
          display: inline;
          img {
            width:100px;
          }
        }
        .topBit {
          width:100%;
          min-height: 178px;
          .videoThumbs {
            display:flex;
            gap:0.5rem;
            .emptyThumb {
              width:100px;
              height:178px;
              border:1px solid $afg;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: xxx-large;
              user-select: none;
              img {
                pointer-events: none;
                width:100px;
              }
              div {
                position:relative;
                top:-2px;
                cursor: default;
              }
            }
            .dotdotdot {
              font-size: xx-large;
              height:166px;
              display: flex;
              align-items: center;
              justify-content: center;
              max-width: 120px;
            }
          }
        }
        .bottomBit {
          width:100%;
          flex:1;
          overflow-y: scroll;
          display: flex;
            .videoThumbs {
              margin: auto;
              margin-left:0px;
              display: flex;
              flex-wrap: wrap;
              gap:1rem;
              justify-content:left;
              .VideoThumb {
                position:relative;
                img {
                  width:150px;
                  cursor: move;
                }
                .info {
                  // width:150px;
                  font-size: large;
                  position:absolute;
                  bottom: 1.5rem;
                  span {
                    background-color: $abg;
                  }
                }
              }
            }
        }
      }
      .projectsList {
        display: flex;
        flex-wrap: wrap;
        gap:1rem;
        margin: auto;
        margin-left: 0px;
        .project {
          padding:1rem;
          border:1px solid $afg;
          &:hover {
            background-color: $afg;
            color: $abg;
          }
          margin-bottom:0px;
          &.first {
            margin-left:-1rem;
          }
        }
      }
    }
    .expanded {
      width: calc(100% - 16rem);
      left:16rem;
    }
    .sidebar {
      width:16rem;
      box-sizing: border-box;
      display: flex;
      // align-items: center;
      font-size: xx-large;
      padding:2rem;
      background-color: $afg;
      color: $abg;
      height:100vh;
      flex-direction: column;
      justify-content: center;
      gap:2rem;
      .ambigramTabs {
        span {
          font-size: medium;
          position:relative;
          // top:0.5rem;
          font-weight: bold;
          left:0.5rem;
        }
      }
      .addButton {
        z-index: 1;
        position:fixed;
        right:2rem;
        bottom:2rem;
        background-color: $afg;
        width:3rem;
        height:3rem;
        border-radius: 3rem;
        display:flex;
        align-items: center;
        justify-content: center;
        border:1px solid $afg;
        &:hover {
          background-color: $abg;
          color: $afg;
        }
        cursor: pointer;
      }
      .tab {
        position:relative;
        &:hover {
          left:0.5rem;
          &.faded {
            left:0rem;
            &.selected {
              left:0.5rem;
            }
          }
        }
        &.faded {
          opacity: 0.5;
        }
        &.selected {
          left:0.5rem;
          &::before {
            position:absolute;
            left:-1.5rem;
            top:1px;
            content: '>';
          }
        }
        &.tool {
          .Popup {
            z-index:101;
            position:fixed;
            width:0px;
            height:0px;
            left:16.5rem;
            cursor: default;
            @keyframes popup {
              from {
                top:0rem;
                min-width: 0px;
                min-height: 0px;
              }
              to {                
                top:-3rem;
                left:0rem;
                min-width: 6rem;
                min-height: 6rem;
              }
            }
            .popup {
              position:relative;
              background-color: $abg;
              border:1px solid $afg;
              top:-3rem;
              animation-name: popup;
              animation-duration: 0.2s;
              min-width: 6rem;
              min-height: 6rem;
              color:$afg;
              font-size: large;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              .popupBG {
                .swatch {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: white;
                  width:6rem;
                  height:6rem;
                  &.yellow {
                    color:$afg;
                  }
                }
              }
              .popupQuery{
                padding:1rem;
                white-space: nowrap;
                div {
                  font-size: x-large;
                }
                input {
                  width:6rem;
                  margin-top:0.5rem;
                }
              }
              .popupMusic {
                white-space: nowrap;
                padding:1rem;
                font-size: larger;
              }
              .popupComps {
                // padding:0.5rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width:10.5rem;
                .soyboy {
                  text-align: center;
                  // width:10.5rem;
                }
                .compList {
                  display:flex;
                  gap:1rem;
                  align-items: center;
                  div {
                    font-size: x-large;
                    &.lil {
                      font-size: large;
                    }
                  }
                }
                .compbtns {
                  display: flex;
                  gap:0.5rem;
                  margin-top:0.5rem;
                  &.clicked {
                    .compbtn {
                      &:hover {
                        background-color: $abg;
                        color:$afg;
                      }
                    }
                  }
                  .compbtn {
                    width:2rem;
                    height:2rem;
                    border:1px solid $afg;
                    display:flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    &:hover {
                      background-color: $afg;
                      color:$abg;
                    }
                  }
                }
              }
            }
          }
        }
        cursor: pointer;
      }
    }

    .i {
      font-size: x-large;
    }
  }
  .tabSplit {
    display: flex;
    justify-content: space-between;
  }
  .ConfirmUpload {
    display:flex;
    align-items: center;
    justify-content: center;
    width:100%;
    font-size: x-large;
    .deets {
      // border:1px solid blue;
      h2 {
        margin:0px;
      }
      display:flex;
      gap:1rem;
      img {
        max-width: 200px;
      }
      .rightbit {
        display:flex;
        flex-direction: column;
        gap:1rem;
        justify-content: center;
      }
    }
  }
  .Comments {
    .comment {
      text-align: center;
      padding-top:0.25rem;
      padding-bottom:0.25rem;
      img {
        max-width: 100%;
        max-height: 150px;
        cursor: move;
      }
    }
  }
  .project {
    .title {
      font-size: x-large;
      display: flex;
      flex-direction: row;
      gap:1rem;
      .splitTitle {
        display:flex;
        flex-direction: column;
      }
      // align-items: center;
      // justify-content: space-around;
    }
    .miniThumbs {
      display:flex;
      // align-items: center;
      // justify-content: space-around;
      gap:0.5rem;
      padding-bottom: 0.5rem;
      div {
        display:flex;
      }
      img {
        margin:auto;
        width: 100px;
      }
    }
  }
  .fullScreenLoading {
    z-index: 1000000;
    position:fixed;
    width:100%;
    height:100vh;
    background-color: $abgFaded;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: xx-large;
    font-style: italic;
    left:0px;
    top:0px;
  }
  .Videos {
    &.noVideos {
      align-items: center;
      justify-content: center;
    }
    min-height: 197px;
    max-height: calc(100vh - 12rem);
    margin-bottom:1rem;
    box-sizing: border-box;
    &.hover {
      border:1px solid red;
    }
    display: flex;
    flex-wrap: wrap;
    gap:1rem;
    .loadingVideos {
      background-color: rgba(255,255,255,0.75);
      position:absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .SetThumbnail {
      .setThumbnail {
        .thumbPreview {
          min-height: 400px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-height: 400px;
            &.seeking {
              opacity: 0.25;
            }
          }
          margin-bottom: 2rem;
        }
      }
      .Seeker {
        user-select: none;
        width:600px;
        // margin-left:100px;
        position:relative;
        height:24px;
        &::after {
          position:absolute;
          top:11px;
          border-bottom: 2px solid $afg;
          width:600px;
          user-select: none;
          pointer-events: none;
          content: " ";
        }
        cursor: pointer;
        .bobbin {
          position: absolute;
          width:8px;
          height:24px;
          // top:-12px;
          background-color: $afg;
          user-select: none;
          pointer-events: none;
        }
      }
    }
    .UploadVideo {
      .uploadVideo {
        .UploadForm{
          .texts {
            padding:0.5rem;
            border:1px solid $afg;
            cursor: pointer;
          }
          .clipped {
            background-color: $afg;
            color: $abg;
          }
        }
        .YouTubeOrTikTok {
          height:9rem;
          display: flex;
          align-items: center;
          justify-content: space-around;
          .platformBtn {
            width:8rem;
            height:4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border:1px solid $afg;
            text-align: center;
            &:hover {
              background-color: $afg;
              color: $abg;
            }
          }
          .queryBtn {
            width:10rem;
            height:4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
          }
        }
      }
    }
    .videoThumb {
      cursor: pointer;
      position: relative;
      .innerThumb {
        position:absolute;
        width:100%;
        .thumbButtons {
          // width:100%;
          display: flex;
          flex-direction: column;
          position:absolute;
          bottom:0.5rem;
          gap:0.3rem;
          .A,.AreYouSure {
            background-color: $abg;
          }
        }
        .thumbUploaded {
          display: flex;
          flex-direction: column;
          position:absolute;
          top:0.5rem;
          gap:0.3rem;
          .A,.a {
            background-color: $abg;
          }
          .a {
            cursor: default;
          }
        }
      }

      img {
        width: 110px;
      }
    }
  }

  .FakeWindow {
    .ForgeComment {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height:150px;
      canvas {
        border:1px solid $afg;
      }
      img {
        max-width: 598px;
        transition: max-width 0.3s linear;
        border:1px solid $afg;
        &.shrinky {
          max-width: 200px;
        }
      }
    }
    .fakePreview {
      width:1200px;
      height:500px;
      // border:1px solid black;
      display:flex;
      align-items: center;
      justify-content: center;
    }
    .FAKE{
      input, textarea {
        font-family: inherit;
        color:inherit;
        margin:0px;
        padding:0px;
        font-size: inherit;
        width:100%;
        line-height: inherit;
      }
      textarea {
        resize: none;
        overflow: hidden;
        min-height: 1rem;
      }
      .sneaky {
        height:0px;
        overflow: hidden;
      }
      .textarea, textarea {
        word-break: break-word;
      }
      margin-left:auto;
      margin-right:auto;
      padding:1rem;
      // border:1px solid $afg;
      color:black;
      background-color: white;
      display:flex;
      flex-direction: row;
      width:270px;
      gap:12px;

      .pfp {
        width:40px;
        img {
          width:40px;
          height:40px;
          border-radius: 20px;
          cursor: pointer;
        }
        .nopfp {
          width:40px;
          height:40px;
          border-radius: 20px;
          box-sizing: border-box;
          background-color: lightgray;
        }
      }
      .fakeBody {
        .fakeUsername {
          color: rgb(22,24,35);
          font-family: 'sofia pro';
          font-size:18px;
          margin-bottom:2px;
        }
        .fakeComment {
          font-family: 'proxima nova';
          font-size: 16px;
          line-height:22px;
        }
        .fakeFooter {
          font-family: 'proxima nova';
          font-size: 14px;
          line-height:17px;
          color: rgba(22,24,35,0.5);
          display:flex;
          gap:24px;
          .fakeHeart {
            margin-left:4px;
            display:inline-flex;
            flex-direction: row;
            svg {
              fill: rgba(22, 24, 35, 1);
              width:20px;
              height:20px;
              overflow: hidden;
              cursor:pointer;
              path {
                fill-rule: evenodd;
                clip-rule: evenodd;
                width:20px;
                height:20px;
              }
            }
            div {
              color: rgb(22,24,35);
              margin-left:4px;
              margin-top:2px;
            }
          }
          .fakeTime {
            margin-top:2px;
          }
          .fakeReply {
            margin-top:1px;
            color: rgb(22,24,35);
            font-weight: 600;
            cursor:pointer;
          }
        }
      }
    }
    .prof {
      display:flex;
      align-items: center;
    }
  }

  .ambigrams {
    max-height: calc(100vh - 12rem);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right:1rem;
    display: flex;
    flex-direction: column;
    gap:2rem;
    .number {
      font-size: x-large;
      margin-bottom:1rem;
    }
    .ambigram {
      .word0{
        display:flex;
        div{
          flex:1;
          white-space: nowrap;
          font-style: italic;
        }
      }
      .itemSplitSplit {
        display:flex;
        gap:1.5rem;
        .rightright {
          flex:1;
        }
      }
      .commentRight {
        display:flex;
        flex-direction: row;
        gap:1rem;
        .pasteButton {
          flex:3;
        }
        .fakeButton {
          flex:1;
          
        }
      }
      .removeAmbigram {
        margin-top:1rem;
      }
    }
  }

  .addAmbigramBut {
    margin-top:1rem;
    margin-bottom:1rem;
  }

  .addButtons {
    margin-bottom: 1rem;
    font-size: large;
  }

  .projectsList {
    .project {
      cursor: pointer;
      margin-bottom: 1rem;
    }
  }


  .expandedProject {
    .loadingWindow {
      opacity: 0.25;
      pointer-events: none;
    }
    .ambigram {
      .preview {
        display: flex;
        flex-direction: column;
        gap:0.5rem;
        img {
          max-width: 200px;
          border:1px solid $afg;
          padding:2px;
        }
        margin-bottom:1rem;
      }
    }
    .shrinkable {
      &.shrink {
        visibility: hidden;
        // overflow: scroll;
        height:0px;
      }
      .unshrinkable {
        visibility: visible !important;
      }
    }
  }

  .pasteButton, .fakeButton {
    border: 1px solid $afg;
    padding:0.25rem;
    display: solid;
    text-align: center;
    cursor: pointer;
    &:hover {
      background-color: $afg;
      color: $abg;
    }
  }

  .AmbigramPaster {
    .img {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 590px;
        border:1px solid $afg;
        padding:4px;
        margin-bottom:1rem;
        transition: max-width 0.3s linear;
        &.shrinky {
          max-width: 200px;
        }
      }
    }
  }

  .spinpic {
    transition: transform 0.4s ease;
    cursor:pointer;
  }

  .CommentPaster {
    .img {
      display: flex;
      align-items: center;
      justify-content: center;
      img,canvas {
        user-select: none;
        max-width: 590px;
        border:1px solid $afg;
        padding:4px;
        margin-bottom:1rem;
        transition: max-width 0.3s linear;
        &.shrinky {
          max-width: 200px;
        }
      }
    }
    .screenshot {
      border:1px solid $afg;
      padding:4px;
      box-sizing: border-box;
      padding-bottom: 2px;
      margin-bottom:1rem;
      position: relative;
      img {
        max-width: 590px;
        user-select: none;
      }
      .dragger {
        position: absolute;
        left:4px;
        top:4px;
        overflow: hidden;
        .rect {
          pointer-events: none;
          opacity: 0.8;
          div {
            position:absolute;
            pointer-events: none;
            background-color: white;
            width:10000px;
            height:10000px;
          }
        }
        .border {
          position:absolute;
          border:1px solid $afg;
          box-sizing: border-box;
        }
        cursor: pointer;
      }
    }
    .P5Paint {
      .canvasContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        canvas {
          user-select: none;
          max-width: 590px;
          border:1px solid $afg;
          padding:4px;
          cursor: none;
          margin-bottom:1rem;
        }
      }
      .buttons {
        display: flex;
        gap:1rem;
      }
      .brushes {
        display:flex;
        gap:1rem;
        justify-content: center;
        margin-bottom: 0.5rem;
        .brush {
          width:50px;
          height:40px;
          overflow: hidden;
          display:flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          div {
            border: 1px solid $afg;
            box-sizing: border-box;
            background-color: $abg;
            &.selected {
              background-color: $afg;
            }
          }
        }
      }
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {                
    opacity: 1;
  }
}

@keyframes growin {
  from {
    transform: scale(0.75);
  }
  to {                
    transform: scale(1);
  }
}

@keyframes growin1 {
  from {
    transform: scale(0.5);
  }
  to {                
    transform: scale(1);
  }
}

.FileServer {
  font-size: larger;
  margin-left:2rem;
  margin-top:2rem;
  h1, h2 {
    margin:0px;
  }
  .visitors {
    margin-top:1rem
  }
  .visitor {
    display: flex;
    width:500px;
    justify-content: space-between;
  }
}

.GraphicArt {
  color:yellow;
  font-size: larger;
  font-family: 'Times New Roman', Times, serif;
  h1,h2,h3,h4,h5 {
    // color: cyan;
    text-decoration: underline;
  }
  a {
    color: yellow;
    // font-weight: bold;
    &:visited {
      color: yellow;
    }
  }

  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    // background: $abg;
  }
  ::-webkit-scrollbar-thumb {
    background: $portfolioBlue;
    background: linear-gradient(0deg, rgb(36,91,167) 0%, $portfolioBlue 100%);
    border-radius: 8px;
    // border:1px solid $portfolioBlue;
    border: 4px solid $portfolioBlack;
  }

  .flexwrap {
    display: flex;
    flex-wrap: wrap;
    gap:1rem;
    align-items: center;
    justify-content: center;
  }

  .PageCounter {
    z-index: 101;
    position:fixed;
    right:2rem;
    top:2rem;
    animation: fadein 2s linear;
    .views {
      margin-left:5px;
    }
  }
  .Fader {
    transition: opacity 0.8s linear;
    opacity: 1;
    animation: fadein 0.8s linear;
    &.invisible {
      opacity: 0;
      pointer-events: none;
    }
  }
  .lilWally {
    height: 0px;
    // border:1px solid yellow;
    position:relative;
    pointer-events: none;
    overflow: hidden;
    margin-top:-37px;
    padding-top:37px;
    top:1rem;
    transition: margin-top 0.25s linear, padding-top 0.25s linear;
    &.hover {
      margin-top:-60px;
      padding-top:60px;
    }
    &.hide {
      margin-top:0px;
      padding-top:0px;
      img {
        pointer-events: none;
      }
    }
    img {
      pointer-events: all;
      position:absolute;
      top:0px;
      cursor: pointer;
      right:0.5rem;
    }
  }
  
  @keyframes flash {
    from {
      opacity: 1;
    }
    to {                
      opacity: 0;
    }
  }
  .FLASH {
    position: fixed;
    width: 100%;
    height:100vh;
    top:0px;
    left:0px;
    background-color: white;
    animation: flash 1s ease-out;
    opacity: 0;
    pointer-events: none;
  }
  .TimeMachine {
    position:relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .invisible {
      opacity: 0 !important;
      pointer-events: none;
    }
    .cheese {
      cursor: pointer;
      width:50px;
      transition: opacity 0.25s linear;
      &.invisible {
        opacity: 0;
        pointer-events: none;
      }
    }
    .timeMachine0 {
      opacity: 1;
      transition: opacity 1s linear;
      position:absolute;
      .timeMachine1{
        position:relative;
        .tm {
          position:absolute;
          left:0px;
          top:0px;
          z-index:1;
          &.on {
            z-index:2;
            transition: opacity 0.4s ease-in-out;
          }
        }
        .scr {
          overflow: hidden;
          opacity:0.8;
          z-index: 3;
          position:absolute;
          left:168px;
          top:303px;
          width:82px;
          height:83px;
          color: white;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'Courier New', Courier, monospace;
          font-size: 14px;
          text-shadow: 0px 0px 2px white, 0px 0px 4px cyan, 0px 0px 8px blue;
          flex-direction: column;
          cursor: default;
          user-select: none;
          &.block {
            pointer-events: none;
          }
          .pass {
            display: flex;
            gap:4px;
            justify-content: center;
            .p {
              width:14px;
              height:14px;
              border-bottom: 1px solid white;
              text-align: center;
              padding-bottom: 2px;
            }
            margin-bottom:8px;
          }
          .enterPassword {
            // overflow: hidden;
            font-size: 14px;
          }
          .numPad {
            display:flex;
            flex-wrap: wrap;
            gap:2px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .btn {
              width:14px;
              height:12px;
              background-color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              box-shadow: 0px 0px 3px rgb(98, 171, 255);
              color: black;
              padding-top:2px;
            }
          }
        }
      }
    }
  }
  .portfolioItem {
    h2, p, h4 {
      margin:0px;
      margin-bottom:1rem;
    }
    p {
      font-size: large;
    }
    .Video {
      margin-bottom:1rem;
      cursor: pointer;
      position:relative;
      .playButton {
        position:absolute;
        left:calc(50% - 27px);
        top:calc(50% - 28px);
        filter:drop-shadow(0px 0px 16px black) drop-shadow(0px 0px 4px rgba(0,0,0,0.5));
      }
      &.noMarginBottom {
        margin-bottom: 0px;
      }
      img {
        max-width: 100%;
      }
    }
    .centre {
      margin-bottom: 1rem;
    }
    .noMarginBottom {
      margin-bottom: 0px !important;
    }
  }
  .thumbnails {
    display: flex;
    flex-wrap: wrap;
    gap:8px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    justify-content: center;
    div{
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width:100%;
        cursor: pointer;
      }
    }
  }
  .centre {
    &.thumbnail {
      img {
        cursor: pointer;
      }
    }
    text-align: center;
    img {
      max-width: 100%;
    }
  }
  .Portfolio {
    width:100%;
    height:100vh;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:0.5rem;
    h4 {
      margin:0px;
    }
    flex-direction: column;
    .ontop {
      z-index: 300;
      text-shadow: 0px 0px 5px black, 0px 0px 5px black, 0px 0px 5px black;
    }
    .tooltip {
      z-index: 301;
      background-color: black;
      border:1px solid yellow;
      position: absolute;
      pointer-events: none;
      padding-left:0.25rem;
      padding-right:0.25rem;
      font-style: italic;
      font-size: small;
    }
    .buttons {
      display: flex;
      gap:1rem;
    }
    @media screen and (max-width: 480px) {
      .buttons {
        flex-direction: column;
        align-items: center;
        gap:0px;
      }
    }
    .byebye {
      position:fixed;
      left:0px;
      top:0px;
    }
    .portImg {
      position:absolute;
      transition: opacity 0.8s linear, left 0.5s linear, top 0.5s linear;
      opacity: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap:0.25rem;
      .viewed {
        font-size: medium;
        text-align: center;
        .title {
          transition: opacity 0.2s linear;
          &.invisible {
            opacity: 0;
          }
        }
        .v {
          font-style: italic;
          font-size: small;
          color:$portfolioBlue;
        }
      }
      .abs {
        transition: opacity 0.8s linear;
        &.unloaded {
          opacity: 0;
        }
      }
      cursor: pointer;
    }
  }
  .Cauldron {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top:3rem;
    .caption {
      text-shadow: 0px 0px 5px black, 0px 0px 5px black, 0px 0px 5px black;
      text-align: center;
      height:0px;
      margin-top:1rem;
    }
    .animate {
      transition: top 1s linear;
    }
    .invisible {
      opacity: 0;
    }
    .cauldron {
      position:relative;
      transition: transform 0.5s linear, opacity 0.25s linear;
      &.invisible {
        transform: scale(0.5);
      }
      img {
        position:absolute;
        top:0px;
        left:0px;
      }
    }
  }
  .Door {
    display: flex;
    justify-content: center;
    height:56px;
    gap:0.5rem;
    position:relative;
    // top:calc(-56px + 1rem);
    margin-bottom: -1rem;
    overflow: hidden;
    .pleaseKnock {
      margin-top:12px;
    }
    .invisible {
      opacity: 0;
      pointer-events: none;
    }
    .mouse {
      position: absolute;
      top:8px;
      right:16px;
      width:0px;
      overflow: hidden;
      transition: width 0.5s linear, top 0.5s linear;
      &.nosey {
        width:66px;
        top:0px;
      }
    }
    .door {
      margin-left:2rem;
      cursor: pointer;
      position:relative;
      width:46px;
      height:56px;
      .d {
        position:absolute;
        left:0px;
        top:0px;
      }
    }
  }
  .Dance {
    .invisible {
      opacity: 0;
      pointer-events: none;
    }
    .Game {
      transition: opacity 0.25s linear;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      button {
        background-color: black;
        border:1px solid yellow;
        font-family: inherit;
        color: yellow;
        font-size: inherit;
        cursor: pointer;
        &:hover {
          background-color: yellow;
          color: black;
        }
      }
      .monkey {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          position:absolute;
          left:0px;
          top:0px;
          animation: growin1 0.25s linear;
        }
        @keyframes gaga {
          0% {
            opacity: 0;
            transform: scale(0.5);
          }
          33% {
            opacity: 1;
            transform: scale(1);
          }
          66% {
            opacity: 1;
            transform: scale(1);
          }
          100% {
            opacity: 0;
          }
        }
        .message{
          font-size: xxx-large;
          @media screen and (max-width: 300px) {
            font-size: xx-large;
          }
          z-index: 10;
          opacity:0;
          animation: gaga 0.5s linear;
          text-shadow: 0px 0px 15px black, 0px 0px 10px black, 0px 0px 5px black;
        }
        .instructions {
          z-index: 10;
          text-align: center;
          p {
            margin:0px;
            font-size: larger;
            margin-bottom:0.5rem;
            text-shadow: 0px 0px 5px black, 0px 0px 5px black, 0px 0px 5px black;
          }
        }
      }
      .arrowButtons {
        position:relative;
        svg {
          width:100%;
        }
        .movingArrow {
          position: absolute;
          svg {
            fill: skyblue;
          }
          &.done {
            svg {
              fill: red;
            }
          }
        }
        .leftArrow {
          transform: rotate(-90deg);
        }
        .rightArrow {
          transform: rotate(90deg);
        }
        .inputButtons {
          div{
            position:absolute;
          }
          display: flex;
          svg {
            stroke: yellow;
            stroke-width: 3px;
            fill:none;
          }
          .highlight {
            filter: drop-shadow(0px 0px 5px white);
            svg {
              stroke: white;
            }
          }
        }
      }
    }
  }
  .Clock {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    &.hitlered {
      .clock {
        cursor: pointer;
      }
    }
    &.invisible {
      opacity: 0;
      pointer-events: none;
    }
    .clock {
      position:relative;
      .mid {
        pointer-events: none;
        user-select: none;
      }
      img {
        position: absolute;
      }
      .hand {
        position:absolute;
      }
    }
  }
  .FullScreen {
    position:fixed;
    left:0px;
    top:0px;
    z-index: 100;
    width:100%;
    height:100vh;
    display: flex;
    background-color: rgba(0,0,0,0.66);
    animation: fadein 0.25s linear;
    transition: opacity 0.25s linear;
    .starry {
      display: flex;
      width:100%;
      height:100vh;
      background-color: black;
      background-image: url('./pics/stars.gif');
  
      animation-name: offsetBG;
      animation-duration: 30s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
    &.invisible {
      opacity: 0;
    }
    .Dialog {
      margin:auto;
      background-color: $portfolioBlack;
      padding:1rem;
      box-sizing: border-box;
      width:calc(100% - 2rem);
      max-width: 600px;
      border:2px solid skyblue;
      border-radius: 4px;
      display: flex;
      gap:1rem;
      font-family:'Courier New', Courier, monospace;
      align-items: center;
      @media screen and (max-width: 555px) {
        font-size: medium;
      }
      @media screen and (max-width: 475px) {
        flex-direction: column;
      }
      .wallyProfilePicture {
        background-color: skyblue;
        border-radius: 4px;
        overflow: hidden;
        .wallyContainer {
          &.shootable {
            cursor: pointer;
          }
          position:relative;
          top:0px;
          transition: top 0.5s linear;
          &.invisible {
            top:213px;
            opacity: 0;
          }
          .flash {
            position:absolute;
            top:0px;
            left:0px;
            background-color: white;
            animation: flash 1s ease-out;
            opacity: 0;
            pointer-events: none;
          }
          img {
            position:absolute;
            left:0px;
            top:0px;
            &.moustache {
              transition: transform 0.1s linear;
              &.animating {
                transition: transform 0.1s linear, top 0.5s linear;
              }
              cursor: pointer;
            }
            &.hitler {
              opacity: 1;
              &.invisible {
                opacity: 0;
                pointer-events: none;
              }
            }
          }
        }
      }
      .dialogBody {
        flex:1;
        display: flex;
        flex-direction: column;
        gap:0.5rem;
        .text {
          flex:1;
          display: flex;
          align-items: center;
        }
        .buttons {
          display: flex;
          gap:0.5rem;
          button {
            background:none;
            border:1px solid yellow;
            color: yellow;
            font-family: inherit;
            cursor: pointer;
            font-weight: bold;
            font-size: inherit;
            &:hover {
              background-color: yellow;
              color: black;
            }
          }
        }
      }
    }

    .videoScreen, .imageScreen {
      align-items: center;
      justify-content: center;
      .caption {
        display: flex;
        justify-content: center;
        align-items: center;
        .caption {
          border-radius: 4px;
          gap:1rem;
          font-style: italic;
          text-shadow: 0px 0px 5px black, 0px 0px 5px black, 0px 0px 5px black;

          button {
            height:2rem;
            width:2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              width:1rem;
            }
          }
        }
      }
      .img {
        img {
          width:100%;
          user-select: none;
          animation: growin1 0.2s linear;
        }
      }
      button {
        padding:0px;
        margin:0px;
        background:none;
        border:none;
        cursor: pointer;
        height:1.5rem;
        width:1.5rem;
        &:hover {
          svg {
            transform:scale(1.25);
          }
        }
        &:disabled {
          cursor: default;
          opacity: 0.5;
          &:hover {
            svg {
              transform:scale(1);
            }
          }
        }
        svg {
          fill: $portfolioBlue;
          width:1.5rem;
          transition: transform 0.1s ease;
          pointer-events: none;
        }
      }
      .X {
        position:fixed;
        right:1rem;
        top:1rem;
        z-index: 999999999999999;
      }
      .video {
        border:2px solid $portfolioBlue;
        border-radius: 4px;
        background-color: $portfolioBlack;
      }
    }
    .modal {
      &.contact {
        .modalBody {
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            margin-top: 0px;
            margin-bottom:0.5rem;
          }
        }
      }
      .modalHeading {
        display: flex;
        justify-content: space-between;
        background-color: #54d0ed;
        background-image: url("https://kaisboatfund.co.uk/api/pictures/heading.gif");
        background-repeat: no-repeat;
        color: black;
        align-items: center;
        height:2rem;
        padding-left:0.5rem;
        padding-right:5px;
        color: white;
        font-style: italic;
        .X {
          cursor: pointer;
          img {
            user-select: none;
          }
        }
      }

      .modalBody {
        padding:1rem;
        min-height: 100px;
        max-height: calc(100vh - 8rem);

        overflow-y: auto;

        background-color: $portfolioBlack;
        // background-image: url('./pics/stars.gif');
    
        // animation-name: offsetBG;
        // animation-duration: 30s;
        // animation-timing-function: linear;
        // animation-iteration-count: infinite;

        // text-shadow: 0px 0px 5px black, 0px 0px 5px black, 0px 0px 5px black;
        p {
          margin-top:1rem;
          margin-bottom:1rem;
        }
      }
      animation: growin 0.25s linear;
      transition: transform 0.5s linear;
      &.small{
        transform: scale(0);
      }
      

      box-sizing: border-box;
      // min-width: 300px;
      width: calc(100% - 1rem);
      background-color: skyblue;
      margin:auto;
      border:2px solid skyblue;
      border-radius: 4px;
    }
  }
  .unloaded {
    opacity: 0 !important;
  }
  .Heading {
    width:100%;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
    display:flex;
    transition: opacity 0.8s ease;
    &.invisible {
      opacity: 0;
      pointer-events: none;
    }
    &.pointer {
      cursor: pointer;
    }
    .AnimatedHeading {
      margin: auto;
      width:100%;
      position:relative;
      .abs {
        position:absolute;
        transition: opacity 0.8s linear;
        opacity: 1;
      }
      .link {
        text-shadow: 0px 0px 5px black, 0px 0px 5px black, 0px 0px 5px black;
        word-wrap:unset;
        a {
          color: yellow;
          &:visited {
            color: yellow;
          }
        }
      }
      .flip {
        transform: scaleX(-1);
      }
    }
  }
}

.Calligraphy {
  width:100%;
  height:100vh;
  display: flex;
  .Calligrabg {
    width:100%;
    height:100vh;
    opacity: 0;
    filter: blur(30px);
    position: absolute;
    transition: opacity 5s cubic-bezier( 0.2, 0.5, 0.5, 1 ), filter 5s cubic-bezier( 0.2, 0.5, 0.5, 1 );
    &.showBg {
      filter: blur(0px);
      opacity: 1;
      background-image: url("https://kaisboatfund.co.uk/api/pictures/calligrabg.jpg");
    }
  }
  .drop0 {
    border-radius: 5px;
    overflow: hidden;
    z-index: 98;
    margin:auto;
    filter: drop-shadow(0px 0px 50px black);
  }
  .drop1 {
    border-radius: 5px;
    overflow: hidden;
    z-index: 99;
    filter: drop-shadow(0px 0px 25px black);
  }
  .Video {
    border-radius: 5px;
    overflow: hidden;
    z-index: 100;
    background-color: black;

    @keyframes blurin {
      from {
        opacity: 0;
        filter: blur(20px) sepia(1);
        transform: scale(0.9);
      }
      to {                
        opacity: 1;
        filter: blur(0px) sepia(0);
        transform: scale(1);
      }
    }

    animation-name: blurin;
    animation-duration: 3s;
    animation-timing-function: cubic-bezier( 0.22, 0.48, 0.37, 1 );
  }
}

.HIDDEN {
  display: none;
}