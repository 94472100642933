@font-face {
  font-family: 'font';
  font-style: normal;
  src: url("../../font.ttf");
}

.train {
  font-family: serif;
  color: white;
  font-size: large;
  background-color: red;
  min-height: 100vh;
}

.train p {
  font-family: 'font';
}

.train p.p {
  font-family: inherit;
}

.train .thicc {
  height: 1rem;
}

.train a {
  cursor: pointer;
  font-style: normal;
  color: red;
}

.train a:hover {
  font-style: italic;
  background-color: yellow;
}

.train h1 {
  font-size: xx-large;
  color: yellow;
}

.train h2 {
  font-size: x-large;
  font-style: oblique;
  color: yellow;
}

.train input {
  color: black;
}
